import { Box, Grid, Stack, Typography } from '@mui/material';
import {
  CardTitle,
  FormikCard,
  AccountAutocomplete,
  SiteAutocomplete,
  DisplayGroup,
} from '../../../components';
import { useEffect, useState, FC, Fragment } from 'react';
import { IOneTimeServiceDetail, IBillingGroup, ISiteSimple, IAccountSimple } from '../../../models';

interface IOTSCustomerInfo {
  values: any;
  setFieldValue: (key: string, val: any) => void;
  isNewService: boolean;
  handleChange: (e: any) => void;
  touched: any;
  errors: any;
  handleBlur: (e: any) => void;
  service: IOneTimeServiceDetail | null;
  billingGroups: IBillingGroup[];
  accountIdParam?: string | null;
  siteIdParam?: string | null;
  shouldRefetchAccounts: boolean;
  showConfirm?: boolean;
  shouldUnload?: boolean;
  isAllExpanded?: boolean;
  setCurrentCustomer: (val: IAccountSimple | null) => void;
}

export const OTSCustomerInfo: FC<IOTSCustomerInfo> = ({
  values,
  setFieldValue,
  isNewService,
  touched,
  errors,
  handleBlur,
  service,
  billingGroups,
  accountIdParam,
  siteIdParam,
  shouldRefetchAccounts,
  showConfirm,
  shouldUnload,
  setCurrentCustomer,
  isAllExpanded,
}) => {
  const [selectedSite, setSelectedSite] = useState<ISiteSimple | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<IAccountSimple | null>(null);
  const [sites, setSites] = useState<ISiteSimple[] | null>(null);
  const [accounts, setAccounts] = useState<IAccountSimple[] | null>(null);

  const formatAddressBlock = (
    street?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null
  ) => {
    const formattedStreet = !!street ? (
      <>
        {street}
        <br />
      </>
    ) : (
      ''
    );
    const citySeparator = !!state || !!postalCode ? `, ` : '';
    const formattedCity = !!city ? `${city}${citySeparator}` : '';
    const formattedState = !!state ? `${state} ` : '';
    const formattedPostalCode = !!postalCode ? postalCode : '';
    return (
      <>
        {formattedStreet}
        {formattedCity}
        {formattedState}
        {formattedPostalCode}
      </>
    );
  };

  const handleTypeChange = (type: string, account: IAccountSimple | null) => {
    setFieldValue('siteId', '');
    setFieldValue('accountId', '');
    setSelectedAccount(null);
    setCurrentCustomer(null);
    setSelectedSite(null);
    setFieldValue('newCustomer', null);
    setFieldValue('customerType', type);
    if (type === 'NewCustomer') {
      setFieldValue('newCustomer', {
        firstName: account?.inputValue?.split(' ')[0],
        lastName: account?.inputValue?.split(' ')?.[1],
        accountName: account?.inputValue ?? '',
        phone: '',
        email: '',
        addressName: account?.inputValue ?? '',
        street: '',
        city: '',
        state: '',
        postalCode: '',
        latitude: '',
        longitude: '',
        billingGroupId: billingGroups?.length === 1 ? billingGroups?.[0].billingGroupId : '',
        chargeForChemicals: false,
        additionalSiteInformation: [],
      });
    }
  };
  useEffect(() => {
    if (service && !!service?.siteId && sites) {
      const site = sites?.filter(site => site.siteId === service?.siteId);
      setSelectedSite((site && site[0]) ?? null);
    }
  }, [service, sites]);
  useEffect(() => {
    if (service && !!service?.accountId) {
      const account = accounts?.filter(account => account.accountId === service?.accountId);
      setSelectedAccount((account && account[0]) ?? null);
      setCurrentCustomer((account && account[0]) ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  useEffect(() => {
    // if accountId url param, set the accounts dropdown
    if (accountIdParam && accounts) {
      const account = accounts?.filter(account => account.accountId === accountIdParam);
      setSelectedAccount((account && account[0]) ?? null);
      setCurrentCustomer((account && account[0]) ?? null);
      setFieldValue('accountId', account[0].accountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountIdParam, accounts]);

  useEffect(() => {
    // if siteId url param, set the sites dropdown
    if (siteIdParam && sites) {
      const site = sites?.filter(site => site.siteId === siteIdParam);
      setSelectedSite((site && site[0]) ?? null);
      setFieldValue('siteId', site[0].siteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteIdParam, sites]);

  return (
    <FormikCard showConfirm={showConfirm} shouldUnload={shouldUnload}>
      <CardTitle
        title={'Customer Information'}
        withExpand
        overrideExpand={isAllExpanded}
        mobileWrap
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <AccountAutocomplete
                isFreeSolo
                setSelectedAccount={val => {
                  if (val && !val?.accountId && val?.accountName) {
                    return handleTypeChange('NewCustomer', val);
                  }
                  setFieldValue('accountId', val?.accountId);
                  setFieldValue('siteId', '');
                  setSelectedSite(null);
                  setSelectedAccount(val);
                  setCurrentCustomer(val);
                }}
                labelText={selectedAccount?.accountName ? 'Customer' : 'Search for Customer'}
                handleBlur={handleBlur}
                selectedAccount={selectedAccount}
                isDisabled={!isNewService}
                hasError={!!touched.accountId && !!errors?.accountId}
                helperText={!!touched.accountId && errors?.accountId}
                handleOptions={data => setAccounts(data)}
                shouldRefetch={shouldRefetchAccounts}
              />
              {values.customerType === 'ExistingCustomer' && (
                <Stack gap={1}>
                  {selectedAccount?.accountAddress && (
                    <DisplayGroup label="Account Address" labelId="accountAddress" component="div">
                      {selectedAccount?.accountAddress && (
                        <>
                          <Typography component="address">
                            {formatAddressBlock(
                              selectedAccount?.accountAddress?.street,
                              selectedAccount?.accountAddress?.city,
                              selectedAccount?.accountAddress?.state,
                              selectedAccount?.accountAddress?.zipCode
                            )}
                          </Typography>
                        </>
                      )}
                    </DisplayGroup>
                  )}
                  <Box>
                    {selectedAccount?.accountPhoneNumbers &&
                      selectedAccount?.accountPhoneNumbers.map(phoneNumber => (
                        <Fragment key={phoneNumber.accountPhoneNumberId}>
                          <DisplayGroup
                            isInline
                            label={`${phoneNumber.phoneNumberType} Phone`}
                            labelId={phoneNumber.phoneNumberType.toLowerCase()}
                          >
                            {phoneNumber.phoneNumber}
                          </DisplayGroup>
                        </Fragment>
                      ))}
                    {selectedAccount?.accountEmail && (
                      <DisplayGroup
                        alignInlineItems="flex-start"
                        isInline
                        label="Email(s)"
                        labelId="email"
                      >
                        {selectedAccount?.accountEmail.split(';').join(', ')}
                      </DisplayGroup>
                    )}
                  </Box>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <SiteAutocomplete
                handleChange={val => {
                  setFieldValue('siteId', val);
                }}
                handleBlur={handleBlur}
                accountId={selectedAccount?.accountId}
                selectedSite={selectedSite}
                siteId={values.siteId}
                isDisabled={!isNewService}
                handleOptions={sites => setSites(sites)}
              />
              {selectedSite?.address && (
                <DisplayGroup label="Site Address" labelId="accountAddress" component="div">
                  <Typography component="address">
                    {formatAddressBlock(
                      selectedSite?.address?.street,
                      selectedSite?.address?.city,
                      selectedSite?.address?.state,
                      selectedSite?.address?.zipCode
                    )}
                  </Typography>
                </DisplayGroup>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <>
                {selectedSite?.additionalSiteInformation &&
                  selectedSite?.additionalSiteInformation.length > 0 &&
                  selectedSite?.additionalSiteInformation.map(additionalSiteInformation => {
                    if (!!additionalSiteInformation.value) {
                      return (
                        <Fragment key={additionalSiteInformation.userDefinedSiteDefId}>
                          <DisplayGroup
                            alignInlineItems="flex-start"
                            isInline
                            label={additionalSiteInformation.description}
                            labelId={additionalSiteInformation.userDefinedSiteDefId}
                          >
                            {additionalSiteInformation.value}
                          </DisplayGroup>
                        </Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}
                {selectedSite?.chargeForChemicals && (
                  <DisplayGroup
                    alignInlineItems="flex-start"
                    isInline
                    label="Normally pays for chemicals"
                    labelId="chargeForChemicals"
                  >
                    {selectedSite.chargeForChemicals}
                  </DisplayGroup>
                )}
              </>
            </Stack>
          </Grid>
        </Grid>
      </CardTitle>
    </FormikCard>
  );
};
