import { Page } from '../../components';
import { ScheduledServices } from './ScheduledServices';
import { subDays } from 'date-fns';

export const ServicesPage = () => {
  return (
    <Page title="Maintenance Services">
      <ScheduledServices
        dateRange={{
          selection: {
            startDate: subDays(new Date(), 30),
            endDate: null,
            key: 'selection',
          },
        }}
        currentStartDatePast30Days
      />
    </Page>
  );
};
