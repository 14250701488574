import {
  Avatar,
  Typography,
  useMediaQuery,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  alpha,
  styled,
} from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { ITechnician } from '../../../../models';
import { ExpandMore } from '@mui/icons-material';
import { theme } from '../../../../styles';
import { InvalidLocationIcon, Card } from '../../../../components';
import { RepairItem } from './repair-item';

export interface IRepairPod {
  techs: ITechnician[];
  condense?: boolean;
  showIndex?: boolean;
  colorizeIndex?: boolean;
  visitDate: Date;
}

export const RepairPod: FC<IRepairPod> = ({
  techs,
  condense = false,
  showIndex = false,
  colorizeIndex = false,
  visitDate,
}) => {
  const isPrint = useMediaQuery('print');
  const isMobile = useMediaQuery('(max-width: 1500px)');
  const isCondensed = isPrint ? true : condense;

  const renderRepairDate = () => {
    return (
      <Typography
        sx={{ fontWeight: 700, fontSize: 14, color: theme.palette.primary.main }}
        variant="body1"
      >
        {format(visitDate, 'EEE M/d/yyyy')}
      </Typography>
    );
  };

  return (
    <Wrapper isCondensed={isCondensed}>
      <Card className={classes.card}>
        <div className={classes.header}>
          <div className={classes.dateAndMap}>{renderRepairDate()}</div>
        </div>
        <div>
          {techs.map((tech: ITechnician, techIndex) => {
            const totalVisitCount = tech?.visits?.length ?? 0;

            const hasInvalidLocations = tech?.visits?.some(
              visit => !visit?.siteAddress?.latitude || !visit?.siteAddress?.longitude
            );

            const sortedVisits = tech?.visits?.sort(
              (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
            );
            if (tech?.visits?.length === 0) return null;
            return (
              <Accordion
                disableGutters
                elevation={0}
                key={`${techIndex}-${tech.userId}`}
                className={classes.accordionItem}
                slotProps={{ transition: { unmountOnExit: true } }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className={classes.accordionHeader}
                  sx={{ padding: 1 }}
                  id={`${techIndex}-${tech.userId}-header`}
                  aria-controls={`${techIndex}-${tech.userId}-content`}
                >
                  {!isMobile && (
                    <Avatar
                      variant="circular"
                      alt={tech.userName}
                      src={`url(https://media.istockphoto.com/photos/smiling-business-man-picture-id136159540)`}
                      sx={{
                        width: '30px',
                        height: '30px',
                        marginRight: '12px',
                        border: `2px solid ${tech?.color ? tech.color : '#aaaaaa'}`,
                        backgroundColor: `${tech?.color ? tech.color : '#aaaaaa'}`,
                        alignSelf: 'center',
                      }}
                    />
                  )}
                  <>
                    <Box>
                      <Typography sx={{ fontSize: '0.85rem', overflowWrap: 'anywhere' }}>
                        {tech.userName}
                      </Typography>
                      <Typography variant="caption">
                        {totalVisitCount} visit{totalVisitCount > 1 ? 's' : ''}
                      </Typography>
                    </Box>
                    <div className={classes.podIcons}>
                      {hasInvalidLocations && <InvalidLocationIcon />}
                    </div>
                  </>
                </AccordionSummary>
                <AccordionDetails
                  id={`${techIndex}-${tech.userId}-content`}
                  aria-labelledby={`${techIndex}-${tech.userId}-header`}
                  className={classes.accordionBody}
                >
                  <div>
                    {sortedVisits?.map((visit, index) => {
                      return (
                        <div key={`${index}`}>
                          <RepairItem
                            repair={visit}
                            index={index + 1}
                            condense={condense}
                            showIndex={showIndex}
                            colorizeIndex={colorizeIndex}
                            techColor={tech?.color}
                            shouldHandleInvalidLocation={false}
                          />
                        </div>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </Card>
    </Wrapper>
  );
};

const PREFIX = 'RepairPod';

const classes = {
  header: `${PREFIX}-header`,
  card: `${PREFIX}-card`,
  accordionItem: `${PREFIX}-accordionItem`,
  accordionHeader: `${PREFIX}-accordionHeader`,
  accordionBody: `${PREFIX}-accordionBody`,
  podIcons: `${PREFIX}-podIcons`,
  dateAndMap: `${PREFIX}-dateAndMap`,
};

const Wrapper = styled(Box)<{ isCondensed?: boolean }>(({ theme, isCondensed }) => ({
  [`& .${classes.header}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.secondary.light}`,
  },

  [`& .${classes.card}`]: {
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.dividers.grey}`,
    '@media print': {
      'page-break-inside': 'avoid',
      marginBottom: '3rem',
    },
  },

  [`& .${classes.accordionItem}`]: {
    position: 'relative',
    backgroundColor: 'white',
    '&.Mui-expanded': { backgroundColor: theme.palette.grey[100] },
    border: `1px solid ${theme.palette.dividers.grey}`,
    marginBottom: '-1px',
    '&:first-of-type': {
      borderRadius: `${theme.shape.borderRadius} 0`,
    },
    '&:last-of-type': {
      borderRadius: `0 ${theme.shape.borderRadius}`,
      marginBottom: 0,
    },
    '&&::before': {
      display: 'none', //hide border
    },
    // Bug Fix for height issue. Needs definitive height in order for dnd to work, so force display instead of height/overflow for MuiCollapse
    '&& .MuiCollapse-root': {
      display: 'none',
      height: 'auto',
      overflow: 'unset',
      minHeight: '100px',
    },
    '&.Mui-expanded .MuiCollapse-root': {
      display: 'block',
    },
    // end Bug Fix
  },

  [`& .${classes.accordionHeader}`]: {
    fontSize: isCondensed ? '.8rem' : undefined,
    alignItems: 'center',
    '.Mui-expanded &&': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      color: theme.palette.primary.main,
      '& .MuiAccordionSummary-expandIconWrapper': { color: theme.palette.primary.main },
    },
    '&& .MuiAccordionSummary-content': { margin: 0 },
    '&&:focus, &&:active': {
      outline: `3px solid ${alpha(theme.palette.primary.light, 0.5)}`,
      zIndex: 3,
    },
    '@media print': {
      'page-break-inside': 'avoid',
    },
  },

  [`& .${classes.accordionBody}`]: {
    padding: '5px',
    '@media print': {
      'page-break-inside': 'avoid',
    },
  },

  [`& .${classes.podIcons}`]: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 2px',
    flex: '1 1 auto',
    justifyContent: ' flex-end',
    gap: '2px',
    '& svg': {
      width: 20,
      height: 20,
    },
  },

  [`& .${classes.dateAndMap}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    '& .btn-text': {
      padding: '0',
    },
    '@media (min-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    '@media (min-width: 1500px)': {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
}));
