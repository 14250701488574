import { Dispatch, FC, SetStateAction } from 'react';
import { CardTitle, Card } from '../../components';
import { IServiceSuggestionChecklist } from '../../models';
import { ChecklistInfoDetails } from './checklist-details';

interface IChecklistInfo {
  serviceId: string | number;
  serviceDefId: string | number;
  setChecklistInfo: Dispatch<SetStateAction<IServiceSuggestionChecklist[]>>;
  checklistInfo: IServiceSuggestionChecklist[];
  dirty: boolean;
  isExpanded?: boolean;
  readonly?: boolean;
  noCardList?: boolean;
  showLoading?: boolean;
}

export const ChecklistInfo: FC<IChecklistInfo> = ({
  serviceId,
  serviceDefId,
  checklistInfo,
  setChecklistInfo,
  dirty,
  isExpanded,
  readonly,
  noCardList = false,
  showLoading = true,
}) => {
  return (
    <>
      {readonly && noCardList ? (
        <ChecklistInfoDetails
          checklistInfo={checklistInfo}
          readonly={readonly}
          setChecklistInfo={setChecklistInfo}
          dirty={dirty}
          serviceDefId={serviceDefId}
          serviceId={serviceId}
          showLoading={showLoading}
        />
      ) : (
        <Card>
          <CardTitle
            title="Checklist Information"
            withExpand
            initialExpand={false}
            overrideExpand={isExpanded}
            unmountOnExit
          >
            <ChecklistInfoDetails
              checklistInfo={checklistInfo}
              readonly={readonly}
              setChecklistInfo={setChecklistInfo}
              dirty={dirty}
              serviceDefId={serviceDefId}
              serviceId={serviceId}
              showLoading={showLoading}
            />
          </CardTitle>
        </Card>
      )}
    </>
  );
};
