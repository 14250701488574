import { FC, useCallback, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { GridDataFetcher, Link, ServerSideDataGrid, useDataGrid } from '../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { IRecurringService, IScheduledService } from '../../models';
import { ServicesActionButton } from './services-actions-button';
import { formatDate } from '../../helpers';
import { getRecurringServices } from '../../fetch';
import { useSnackbar } from 'notistack';

interface RecurringServicesDataGridProps {
  redirect?: string;
  isEditable?: boolean;
  siteId?: string;
  serviceDefId?: string;
}

export const RecurringServicesDataGrid: FC<RecurringServicesDataGridProps> = ({
  redirect,
  isEditable,
  siteId,
  serviceDefId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const recurringServicesDataFetcher: GridDataFetcher<IRecurringService, number> = useCallback(
    async ({ sortColumn, sortDirection }) => {
      try {
        const res = await getRecurringServices({
          siteId,
          sortBy: sortColumn,
          sortDirection: sortDirection ?? 'asc',
          serviceDefId,
        });
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading recurring services, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows: recurringServices,
    page: recurringPage,
    pageSize: recurringPerPage,
    rowCount: recurringServicesCount,
    sortModel: recurringSortModel,
    onSortModelChange: onRecurringSortModelChange,
    onPageChange: onRecurringPageChange,
    onPageSizeChange: onRecurringPageSizeChange,
    isLoading,
  } = useDataGrid<IRecurringService, number>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: `recurring-services-grid`,
      sortColumn: 'initialDate',
      sortDirection: 'asc',
    },
    dataFetcher: recurringServicesDataFetcher,
  });

  const columns = useMemo(() => {
    return [
      {
        field: 'serviceType',
        headerName: 'Type',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRecurringService>) => {
          const { row: original } = params;
          return (
            original.recurringServiceId && (
              <Link
                to={`/services/maintenance/recurring/${original.recurringServiceId}${
                  redirect ? `?redirect=${redirect}` : ''
                }`}
              >
                <Tooltip title={`Edit recurring service`} placement="bottom">
                  <span>{original.serviceType}</span>
                </Tooltip>
              </Link>
            )
          );
        },
      },
      {
        field: 'frequency',
        headerName: 'Recur Pattern',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
      },
      {
        field: 'userName',
        headerName: 'Tech',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
      },
      {
        field: 'initialDate',
        headerName: 'Initial Date',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        maxWidth: 150,
        renderCell: (params: GridRenderCellParams<IRecurringService>) => {
          const { row: original } = params;
          return original.initialDate ? formatDate(original.initialDate) : '';
        },
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        maxWidth: 150,
        renderCell: (params: GridRenderCellParams<IRecurringService>) => {
          const { row: original } = params;
          return original.endDate ? formatDate(original.endDate) : '';
        },
      },
      isEditable && {
        headerName: '',
        field: 'actions',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        width: 60,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          return <ServicesActionButton service={original} redirect={redirect} />;
        },
      },
    ].filter(Boolean);
  }, [redirect, isEditable]);

  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: IRecurringService) => row.recurringServiceId}
      columns={columns as any[]}
      rows={recurringServices}
      rowCount={recurringServicesCount}
      page={recurringPage}
      pageSize={recurringPerPage}
      onPageChange={onRecurringPageChange}
      onPageSizeChange={onRecurringPageSizeChange}
      sortModel={recurringSortModel}
      onSortModelChange={onRecurringSortModelChange}
      loading={isLoading}
      hasMobileLayout
    />
  );
};
