import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Box, IconButton, styled } from '@mui/material';
import { FC, useContext, useEffect } from 'react';
import sideBarImg from '../../styles/images/LeftNav-Bkg.jpg';
import { Link } from '../link';
import { TopLevelNav } from './top-level-nav';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Paths, ROLES } from '../../constants';
import { BrandingContext } from '../../context/branding-context';
import { Logo } from './Logo';
import { UserMenu } from './user-menu';
import { GlobalSearch } from './global-search';
import { UserContext } from '../../context';

interface IHeaderProps {
  setShowNav(showNav: boolean): void;
  showNav: boolean;
  width: number;
  height: number;
  isMobile: boolean;
  isSmMobile: boolean;
  // This is a temporary prop to allow us to test the header component
  isTesting?: boolean;
}

interface IHeaderStyles {
  isTesting?: boolean;
  sideBarImg: string;
}

export const Header: FC<IHeaderProps> = ({
  setShowNav,
  showNav,
  height,
  width,
  isMobile,
  isSmMobile,
  isTesting,
}) => {
  const { isSuperAdmin, user } = useContext(UserContext);
  const { appLongName, logoIcon, isPoolService } = useContext(BrandingContext);
  const { v2AncillaryFeatures, v2HelpHeaderLink, v2Customers } = useFlags();
  const renderNavToggle = () => (
    <IconButton
      onClick={() => {
        setShowNav(!showNav);
      }}
      color="inherit"
      className={classes.navButton}
      size="small"
    >
      <FontAwesomeIcon icon={showNav ? faArrowLeft : faBars} />
    </IconButton>
  );

  useEffect(() => {
    const body = document.querySelector('#header');

    body?.scrollIntoView?.({
      behavior: 'smooth',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <StyledAppBar
      id="header"
      position="fixed"
      className="print--none"
      data-testid="header"
      sx={{ width: '100%', height, zIndex: theme => theme.zIndex.drawer + 1 }}
      isTesting={isTesting}
      sideBarImg={sideBarImg}
    >
      <Box
        display="flex"
        alignItems="center"
        height={height}
        paddingLeft="1rem"
        paddingRight="1rem"
      >
        {!isMobile && (
          <Box className={classes.desktopLogoWrapper} sx={{ minWidth: width }}>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ minWidth: width - 16 - 22 }} // Sidebar width - link padding left - header gutter right
              paddingRight={3}
            >
              {showNav ? (
                <Link to={user?.userType === ROLES.SuperAdmin ? Paths.officeManagement.url : '/'}>
                  <Logo />
                </Link>
              ) : (
                <img
                  src={logoIcon}
                  alt={`${appLongName} Logo`}
                  className={classes.desktopCollapseLogo}
                />
              )}
              {renderNavToggle()}
            </Box>
          </Box>
        )}
        {isMobile && (
          <Box className={classes.wrapper}>
            <Box className={classes.logoWrapper}>
              <img src={logoIcon} alt={`${appLongName} Logo`} className={classes.mobileLogo} />
              {renderNavToggle()}
            </Box>
          </Box>
        )}
        <Box pl={1} display="flex" alignItems="center" gap={1} width="100%">
          <Box flex={1}>
            {(!isSuperAdmin || user?.userType === ROLES.Emulating) && (
              <GlobalSearch v2Customers={v2Customers} />
            )}
          </Box>
          <Box display="flex" alignItems="center">
            {!isMobile && !isSuperAdmin && (
              <>
                {/* Pool 360 Specific Links */}
                <TopLevelNav
                  to={Paths.news.url}
                  icon={Paths.news.icon}
                  text={Paths.news.label}
                  isExternal={false}
                  isVisible={isPoolService && v2AncillaryFeatures}
                />
                <TopLevelNav
                  to={Paths.feedback.url}
                  icon={Paths.feedback.icon}
                  text={Paths.feedback.label}
                  isExternal={false}
                  isVisible={isPoolService && v2AncillaryFeatures}
                />
                <TopLevelNav
                  to={Paths.help.url}
                  icon={Paths.help.icon}
                  text={Paths.help.label}
                  isExternal={false}
                  isVisible={isPoolService && v2HelpHeaderLink}
                />

                {/* Pinch Specific Links */}
                <TopLevelNav
                  to={v2AncillaryFeatures ? Paths.news.url : Paths.news.legacyUrl!}
                  icon={Paths.news.icon}
                  text={Paths.news.label}
                  isExternal={v2AncillaryFeatures ? false : true}
                  isVisible={!isPoolService}
                />
                <TopLevelNav
                  to={v2AncillaryFeatures ? Paths.feedback.url : Paths.feedback.legacyUrl!}
                  icon={Paths.feedback.icon}
                  text={Paths.feedback.label}
                  isExternal={v2AncillaryFeatures ? false : true}
                  isVisible={!isPoolService}
                />
                <TopLevelNav
                  to={v2HelpHeaderLink ? Paths.help.url : Paths.help.legacyUrl!}
                  icon={Paths.help.icon}
                  text={Paths.help.label}
                  isExternal={v2HelpHeaderLink ? false : true}
                  isVisible={!isPoolService}
                />
              </>
            )}
            <UserMenu
              isSmMobile={isSmMobile}
              isMobile={isMobile}
              v2AncillaryFeatures={v2AncillaryFeatures}
              isPoolService={isPoolService}
              v2HelpHeaderLink={v2HelpHeaderLink}
            />
          </Box>
        </Box>
      </Box>
    </StyledAppBar>
  );
};

const PREFIX = 'Header';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  logoWrapper: `${PREFIX}-logoWrapper`,
  desktopCollapseLogo: `${PREFIX}-desktopCollapseLogo`,
  mobileLogo: `${PREFIX}-mobileLogo`,
  navButton: `${PREFIX}-navButton`,
  desktopLogoWrapper: `${PREFIX}-desktopLogoWrapper`,
};

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'isTesting' && prop !== 'sideBarImg',
})<IHeaderStyles>(({ theme, isTesting, sideBarImg }) => {
  return {
    [`& .${classes.wrapper}`]: {
      backgroundImage: isTesting ? '' : `url(${sideBarImg})`,
    },

    [`& .${classes.logoWrapper}`]: {
      display: 'flex',
      flexDirection: 'row',
      width: 80,
      borderRight: '1px solid rgba(255, 255, 255, 0.2)',
      padding: `1rem`,
      marginLeft: '-16px',
      marginRight: '1rem',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    [`& .${classes.desktopCollapseLogo}`]: {
      width: 26,
      height: 26,
    },
    [`& .${classes.mobileLogo}`]: {
      width: 19,
      height: 19,
    },
    [`& .${classes.navButton}`]: {
      background: 'transparent',
      border: 0,
      boxShadow: 'none',
      padding: 0,
      marginLeft: 10,
      color: '#fff',
      '&:hover': {
        backgroundColor: `transparent !important`,
      },
      '&:focus': {
        backgroundColor: `transparent !important`,
        boxShadow: `none !important`,
      },
    },
    [`& .${classes.desktopLogoWrapper}`]: {
      display: 'flex',
      flexDirection: 'row',
      '@media (min-width: 930px)': {
        boxShadow: 'none',
      },
    },
  };
});
