import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Button, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { EAccountProvider, ITranCode } from '../../../models';
import { deleteTranCode, getAllTranCodes } from '../../../fetch';
import { faPlusCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AddEditTranCodeModal } from './add-edit-tran-code-modal';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { formatMoney, truncate } from '../../../helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const TranCodesTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, showModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [currentTranCode, setCurrentTranCode] = useState<ITranCode | null>(null);
  const confirm = useConfirm();
  const { user, accountingProvider } = useContext(UserContext);
  const featureFlags = useFlags();

  const dataFetcher: GridDataFetcher<ITranCode> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          officeId: user?.officeId as string,
        };

        const res = await getAllTranCodes(options);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading transaction codes, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    [enqueueSnackbar, user?.officeId]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ITranCode>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'tran-codes-grid',
      sortColumn: 'description',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const handelDelete = async (item: ITranCode) => {
    try {
      const result = await confirm('Are you sure you want to delete this transaction code?');
      if (result) {
        setDeleting(true);
        await deleteTranCode(item.tranCodeId);
        await refetch();
        enqueueSnackbar(`Transaction Code Deleted!`, {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(`Error deleting transaction code, please try again.`, {
        variant: 'error',
      });
    } finally {
      setDeleting(false);
    }
  };

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'code',
        headerName: 'Code',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'sortOrder',
        headerName: 'Sort Order',
        flex: 1,
      },
      accountingProvider === EAccountProvider.QuickBooks &&
        featureFlags.quickbooks && {
          field: 'quickBooksAccount',
          headerName: 'QB Account',
          sortable: false,
          flex: 1,
          renderCell: (params: GridRenderCellParams<ITranCode>) => {
            const row = params.row;
            const accountName = row.quickBooksAccount?.accountName;

            return (
              <>
                {
                  <Tooltip title={accountName}>
                    <span>{truncate(params.row.quickBooksAccount?.accountName ?? '', 12)}</span>
                  </Tooltip>
                }
              </>
            );
          },
        },
      {
        field: 'isCredit',
        headerName: 'Credit',
        sortable: false,
        flex: 1,
        type: 'boolean',
      },
      {
        field: 'isRevenue',
        headerName: 'Revenue',
        sortable: false,
        flex: 1,
        type: 'boolean',
      },
      {
        field: 'isTaxable',
        headerName: 'Taxable',
        sortable: false,
        flex: 1,
        type: 'boolean',
      },
      {
        field: 'isSummarized',
        headerName: 'Summarized on Statement',
        sortable: false,
        flex: 1,
        type: 'boolean',
      },
      {
        field: 'defaultAmount',
        headerName: 'Default Amount',
        sortable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams<ITranCode>) => {
          return <>{formatMoney(params.row.defaultAmount)}</>;
        },
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        renderCell: (params: GridRenderCellParams<ITranCode>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Transaction Code"
                disabled={isDeleting}
                onClick={() => {
                  showModal(true);
                  setCurrentTranCode(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              <IconButton
                color="error"
                title="Delete Transaction Code"
                disabled={isDeleting}
                onClick={() => {
                  handelDelete(params.row);
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ].filter(Boolean) as GridColDef[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleting, confirm, enqueueSnackbar, refetch, accountingProvider, featureFlags.quickbooks]);

  return (
    <>
      <Card>
        <CardTitle
          title="Transaction Codes"
          mobileWrap
          action={
            <>
              <Button
                onClick={() => showModal(true)}
                color="secondary"
                size="small"
                disabled={isLoading}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add Transaction Code
              </Button>
            </>
          }
        />
        <ServerSideDataGrid
          autoHeight
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: ITranCode) => row.tranCodeId}
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          hasMobileLayout
          mobileProps={{
            handleEdit: (item: ITranCode) => {
              showModal(true);
              setCurrentTranCode(item);
            },
            handleDelete: (item: ITranCode) => {
              handelDelete(item);
            },
          }}
        />
      </Card>
      <AddEditTranCodeModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentTranCode(null);
        }}
        currentTranCode={currentTranCode}
        fetchTranCodes={() => refetch()}
      />
    </>
  );
};
