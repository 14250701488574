import { styled } from '@mui/material';
import 'bingmaps';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ITechnician, IRepair } from '../../models';
import { isRouteStart, isRouteStartOrEnd } from '../../pages/routes/utils';
import { Loader } from '../loader';

const { BING_MAP_KEY } = require('../../buildSettings.json');

export const INLINE_MAP_MARKER_SVG = (color?: string) =>
  `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve"> <desc>Created with Fabric.js 1.7.22</desc> <defs> </defs> <g transform="translate(128 128) scale(0.72 0.72)" style=""> <g style="stroke: rgb(0,0,0); stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: ${color ? color : '#000000'
  }; fill-rule: nonzero; opacity: 1;" transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)" > <path d="M 41.588 88.03 L 27.926 64.366 c -1.516 -2.626 0.379 -5.909 3.412 -5.909 h 27.325 c 3.033 0 4.928 3.283 3.412 5.909 L 48.412 88.03 C 46.895 90.657 43.105 90.657 41.588 88.03 z" style="stroke: rgb(0,0,0); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: ${color ? color : '#000000'
  }; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" /> <circle cx="45.001999999999995" cy="37.032" r="37.032" style="stroke: rgb(0,0,0); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: ${color ? color : '#000000'
  }; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) "/> </g> </g> </svg>`;

export interface MapWindow extends Window {
  directionsManagers: any[];
  bingAPIReady: () => void;
  Microsoft: any;
}

declare let window: {
  bingAPIReady?: () => void;
  BingMap: Microsoft.Maps.Map;
};
const renderMap = (isRoutes: boolean, techs: ITechnician[], mapOptions?: MapOptions) => {
  // get all the locations so we can determine the center view from all locations
  let totalLocations = [];
  if (isRoutes) {
    totalLocations =
      techs.flatMap(t =>
        t.services.filter(route => !!route.latitude || !!route.longitude).flatMap(s => s)
      ) ?? [];
  } else {
    totalLocations =
      techs.flatMap(
        t =>
          t?.visits
            ?.filter(visit => !!visit?.siteAddress?.latitude || !!visit?.siteAddress?.longitude)
            .flatMap(visit => visit.siteAddress) // Needed because Microsoft.Maps.Location only consists of lat and long at the root level, not nested under siteAddress
      ) ?? [];
  }

  const bestView = Microsoft.Maps.LocationRect.fromLocations(
    totalLocations as unknown as Microsoft.Maps.Location[]
  );
  window.BingMap = new Microsoft.Maps.Map('#bing-map', {
    mapTypeId: Microsoft.Maps.MapTypeId.grayscale,
    zoom: mapOptions?.zoom ?? 12,
    // set the bounds initially so we see all of the routes, but then ignore it if the user chanages the view/center on the map
    bounds: !!mapOptions?.zoom ? undefined : bestView,
    padding: 80,
    center: mapOptions?.center ?? undefined,
  });
};

const loadBingApi = (
  isRoutes: boolean,
  techs: ITechnician[],
  callback: () => void,
  setPushPins?: Dispatch<SetStateAction<any>>,
  mapOptions?: MapOptions,
  setOptions?: Dispatch<SetStateAction<MapOptions>>
) => {
  let url = `https://www.bing.com/api/maps/mapcontrol?callback=bingAPIReady&key=${BING_MAP_KEY}`;
  if (!document.getElementById('BingMaps')) {
    const script = document.createElement('script');
    script.id = 'BingMaps';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = url;
    document.body.appendChild(script);
    // eslint-disable-next-line
    window.bingAPIReady = () => {
      renderMap(isRoutes, techs, mapOptions);
      renderPins(techs, callback, isRoutes, setPushPins, setOptions);
    };
    // call for every other time we have already loaded the script
  } else if (window?.bingAPIReady) {
    renderMap(isRoutes, techs, mapOptions);
    renderPins(techs, callback, isRoutes, setPushPins, setOptions);
  } else {
    // call once the script is loaded for the first time
    // eslint-disable-next-line
    window.bingAPIReady = () => {
      renderMap(isRoutes, techs, mapOptions);
      renderPins(techs, callback, isRoutes, setPushPins, setOptions);
    };
  }
};
const filterDuplicates = (tech: ITechnician, isRoutes: boolean) => {
  let unique: any[] = [];
  if (isRoutes) {
    return tech.services
      .filter(route => !isRouteStartOrEnd(route))
      .filter(o => {
        if (
          unique.find(
            i => Number(i.longitude) === Number(o.longitude) && Number(i.latitude === o.latitude)
          )
        ) {
          return true;
        }

        unique.push(o);
        return false;
      });
  }
  return tech?.visits?.filter(o => {
    if (
      unique.find(
        i =>
          Number(i?.siteAddress?.longitude) === Number(o?.siteAddress?.longitude) &&
          Number(i?.siteAddress?.latitude === o?.siteAddress?.latitude)
      )
    ) {
      return true;
    }

    unique.push(o);
    return false;
  }) as IRepair[];
};
const renderPins = (
  techs: ITechnician[],
  callback: () => void,
  isRoutes: boolean,
  setPushPins?: Dispatch<SetStateAction<any>>,
  setOptions?: Dispatch<SetStateAction<MapOptions>>
) => {
  const map = window.BingMap;
  Microsoft.Maps.loadModule('Microsoft.Maps.Directions', () => {
    let pushPins: any[] = [];

    // track whenever the view changed from zoom/center moved around within the map
    Microsoft.Maps.Events.addHandler(map, 'viewchangeend', () => {
      setOptions?.({
        zoom: map.getZoom(),
        center: map.getCenter(),
      });
    });

    // For each tech, we need to create a Directions Manager, which corrosponds to a particular tech's route
    techs.forEach(tech => {
      const dm = new Microsoft.Maps.Directions.DirectionsManager(map);
      // Make sure the route is set to driving
      dm.setRequestOptions({
        routeMode: Microsoft.Maps.Directions.RouteMode.driving,
        routeDraggable: false,
        maxRoutes: 1,
        routeOptimization: Microsoft.Maps.Directions.RouteOptimization.timeWithTraffic,
      });

      // Set render options for the route.
      dm.setRenderOptions({
        // This will automatically reset the center of the map when a route is added
        autoUpdateMapView: false,
        displayRouteSelector: false,
        displayManeuverIcons: false,
        drivingPolylineOptions: {
          // Create a random hex color: https://css-tricks.com/snippets/javascript/random-hex-color/
          strokeColor: tech?.color ? tech.color : '#000000',
          strokeThickness: 10,
        },
        waypointPushpinOptions: {
          visible: false,
        },
      });

      Microsoft.Maps.Events.addHandler(dm, 'directionsUpdated', (e: any) => {
        callback();
      });

      const center = map.getCenter();
      const infobox = new Microsoft.Maps.Infobox(center, {
        visible: false,
      });

      const pushpinClicked = (e: any) => {
        infobox.setOptions({
          location: e.target.getLocation(),
          visible: true,
          title: e.target.metadata.title,
          description: e.target.metadata.description,
        });
        infobox.setMap(map);
      };
      // This component supports mapping IRepair[] and IService[], depending on the isRoutes prop.
      // Due to the differences in the data structure, we need to handle them differently,
      // hence the various if statements checking the isRoutes prop in the following code.
      const duplicatePins: any[] = filterDuplicates(tech, isRoutes);
      const initialPins: any[] = isRoutes
        ? tech.services.filter(route => !!route.latitude || !!route.longitude) ?? []
        : tech?.visits
          ?.filter(visit => !!visit?.siteAddress)
          ?.filter(visit => !!visit?.siteAddress?.latitude || !!visit?.siteAddress?.longitude) ??
        [];
      initialPins?.forEach((item, index) => {
        const filteredDuplicates = isRoutes
          ? duplicatePins.filter(
            r => r.longitude === item.longitude && r.latitude === item.latitude
          )
          : duplicatePins.filter(
            v =>
              v?.siteAddress?.longitude === item?.siteAddress?.longitude &&
              v?.siteAddress?.latitude === item?.siteAddress?.latitude
          );
        const multipleStops: any[] = [...filteredDuplicates, item];
        const hasDuplicates = multipleStops?.length > 1;
        const isStart = isRoutes ? isRouteStart(item) : false;
        // need to set this to a Location from maps to pass to the Pushpin class
        let location = isRoutes
          ? new Microsoft.Maps.Location(item.latitude, item.longitude)
          : new Microsoft.Maps.Location(item?.siteAddress?.latitude, item?.siteAddress?.longitude);
        // some of the address strings have weird charcters in them, remove them
        const address = isRoutes
          ? `${item.address.replace(/\s+/g, ' ').trim()}, ${item.city
            .replace(/\s+/g, ' ')
            .trim()}, ${item.state.replace(/\s+/g, ' ').trim()}`
            .trim()
            .replace(/\r\n/g, '')
          : `${item?.siteAddress?.street?.replace(/\s+/g, ' ').trim()}, ${item?.siteAddress?.city
            ?.replace(/\s+/g, ' ')
            .trim()}, ${item?.siteAddress?.state?.replace(/\s+/g, ' ').trim()}`
            .trim()
            .replace(/\r\n/g, '');
        // pass both the address and location lat/lng so bing doesn't freak out
        const waypoint = new Microsoft.Maps.Directions.Waypoint({
          address,
          isViaPoint: false,
          location: {
            latitude: isRoutes ? item.latitude : item?.siteAddress?.latitude!,
            longitude: isRoutes ? item.longitude : item?.siteAddress?.longitude!,
            clone: () => location,
          },
        });
        dm.addWaypoint(waypoint);

        Microsoft.Maps.Events.addHandler(
          dm,
          'directionsError',
          (e: { responseCode: string; message: string }) => {
            // get any errors set loading to false
            callback();
          }
        );
        let text = isRoutes ? `${index}` : `${index + 1}`;
        text = hasDuplicates ? `${text}+` : text;
        if (isStart) {
          text = 'S';
        }
        const title = `${item.siteName.trim()}`;
        const startTitle = isRoutes ? `${tech.userName.trim()} - ${title}` : title;

        const addressText = isRoutes
          ? `${item.address.trim()}`
          : `${item?.siteAddress?.street?.trim()}`;

        let pin = new Microsoft.Maps.Pushpin(location, {
          icon: INLINE_MAP_MARKER_SVG(tech.color),
          color: tech.color,
          title: index === 0 ? startTitle : title,
          subTitle: addressText,
          text,
        });
        pin.metadata = {
          title: hasDuplicates
            ? `${multipleStops.map(s => s.siteName).join(', ')}`
            : index === 0
              ? startTitle
              : `${title}`,
          description: hasDuplicates
            ? index === 0 && isRoutes
              ? `${startTitle}, ${addressText}`
              : `${addressText}`
            : `${addressText}`,
        };
        map.entities.push(pin);
        Microsoft.Maps.Events.addHandler(pin, 'click', pushpinClicked);
        pushPins.push({
          id: isRoutes ? item.scheduledServiceId : item.repairVisitId,
          color: tech.color,
          pin: pin,
        });
      });
      if (dm.getAllWaypoints().length > 1) {
        setTimeout(() => {
          dm?.calculateDirections();
        }, 500);
      } else {
        callback();
      }
    });
    // send to the parent all of the pins for a route day
    if (setPushPins && pushPins.length > 0) {
      // call outside the loop so it doesn't effect performance
      setPushPins(pushPins);
    }
  });
};

interface IBingMap {
  techs: ITechnician[];
  setPushPins?: Dispatch<SetStateAction<any>>;
  keyId?: string;
  isRoutes?: boolean;
  reload?: boolean;
}

interface MapOptions {
  zoom: number | null;
  center: Microsoft.Maps.Location | null;
}

export const BingMap: FC<IBingMap> = ({ techs, setPushPins, keyId, isRoutes = true, reload }) => {
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [mapOptions, setOptions] = useState<MapOptions>({
    zoom: null,
    center: null,
  });


  useEffect(() => {
    setIsLoadingMap(true);
    loadBingApi(
      isRoutes,
      techs,
      () => {
        setIsLoadingMap(false);
      },
      setPushPins,
      mapOptions,
      setOptions
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [techs]);
  return (
    <StyledBingMap className={classes.map}>
      <div key={keyId} style={{ display: 'block' }} id="bing-map" />
      {isLoadingMap && <Loader position="centered" type="overlay" />}
    </StyledBingMap>
  );
};

const PREFIX = 'INLINE_MAP_MARKER_SVG';

const classes = {
  map: `${PREFIX}-map`
};

const StyledBingMap = styled('div')(({ theme }) => ({
  [`&.${classes.map}`]: {
    position: 'relative',
    '&&, && > div': {
      minHeight: '560px',
      '@media (min-height: 768px)': {
        minHeight: '63vh',
      },
      '@media print': {
        minHeight: '560px',
        width: '768px',
        'page-break-inside': 'avoid',
      },
    },
  }
}));