import { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Box,
  FormControlLabel,
  Grid,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from 'react-query';
import { Checkbox, SelectAsync, TextField, Card } from '../../../components';
import { IPoolEquipment, IPoolEquipmentFinish, IPoolEquipmentShape } from '../../../models/sites';
import {
  getPoolEquipmentAlgaeTypes,
  getPoolEquipmentFinishes,
  getPoolEquipmentSaltChlorinators,
  getPoolEquipmentSanitizers,
  getPoolEquipmentShapes,
  getPoolTypes,
} from '../../../fetch/sites';
import { IDropdownResponse, ILookupModel } from '../../../models';

interface IStructureDetailsFormProps {
  handleFormChange: (val?: any, isDirty?: boolean) => void;
  readOnly?: boolean;
  triggerFormValidation?: boolean;
}

export const StructureDetailsForm: FC<IStructureDetailsFormProps> = ({
  handleFormChange,
  readOnly,
  triggerFormValidation,
}) => {
  const {
    setFieldValue,
    values,
    errors,
    touched,
    handleBlur,
    validateField,
    setFieldTouched,
    validateForm,
  } = useFormikContext<IPoolEquipment>();

  const { isLoading: isLoadingSanitizers, data: sanitizers } = useQuery(
    ['getPoolEquipmentSanitizers'],
    async () => {
      return await getPoolEquipmentSanitizers();
    }
  );
  useEffect(() => {
    if (triggerFormValidation) {
      validateForm(); // Trigger form validation before compiling values for collection
      handleFormChange(undefined, true); // Trigger form change to compile values for collection
    }
  }, [triggerFormValidation, validateForm, handleFormChange]);
  // REMINDER: When adding fields, ensure that you update the StructureDetailsForm component as well
  return (
    <Box mt={2}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <SelectAsync
              hasClear={!readOnly}
              autoComplete="nope"
              label="Shape"
              name="appearance"
              apiRequest={getPoolEquipmentShapes}
              transformResponse={(response: IPoolEquipmentShape[]) => {
                return response.map(record => ({
                  label: record.shape ?? '',
                  value: record.shape ?? '',
                }));
              }}
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              InputProps={{
                readOnly,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <TextField
              autoComplete="nope"
              label="Gallons"
              name="poolVolume"
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              inputProps={{
                readOnly,
              }}
              error={!!touched?.poolVolume && !!errors?.poolVolume}
              helperText={touched?.poolVolume && errors?.poolVolume}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <SelectAsync
              hasClear={!readOnly}
              autoComplete="nope"
              label="Finish"
              name="surface"
              apiRequest={getPoolEquipmentFinishes}
              transformResponse={(response: IPoolEquipmentFinish[]) => {
                return response.map(record => ({
                  label: record.finish ?? '',
                  value: record.finish ?? '',
                }));
              }}
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              InputProps={{
                readOnly,
              }}
              error={!!touched?.surface && !!errors?.surface}
              helperText={touched?.surface && errors?.surface}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <SelectAsync
              name="poolType"
              label="Pool Type"
              apiRequest={getPoolTypes}
              transformResponse={(response: ILookupModel[]) =>
                response.map(item => ({ label: item.description, value: item.value }))
              }
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              InputProps={{
                readOnly,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} xl={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>{isLoadingSanitizers ? 'Loading...' : 'Sanitizer'}</InputLabel>
              <Select
                label={isLoadingSanitizers ? 'Loading...' : 'Sanitizer'}
                size="small"
                value={values.sanitizer}
                disabled={
                  isLoadingSanitizers ||
                  (values.saltChlorinator && values.saltChlorinator !== 'NONE' ? true : false)
                }
                error={!!touched.sanitizer && !!errors.sanitizer}
                onChange={e => {
                  setFieldValue('sanitizer', e.target.value);
                  validateField('sanitizer');
                }}
                fullWidth
                onBlur={e => {
                  handleBlur(e);
                  setFieldTouched('sanitizer', true, true);
                  handleFormChange();
                }}
                readOnly={readOnly}
              >
                {sanitizers?.map((sanit, index) => {
                  if (values.saltChlorinator && values.saltChlorinator !== 'NONE') {
                    return (
                      <MenuItem key="SALT" value="SALT">
                        SALT
                      </MenuItem>
                    );
                  }
                  return (
                    <MenuItem key={sanit.sanitizer!} value={sanit.sanitizer!}>
                      {sanit.sanitizer!}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {!!touched.sanitizer && !!errors?.sanitizer && (
              <FormHelperText error>{errors.sanitizer}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <SelectAsync
              autoComplete="nope"
              label="Salt Chlorinator"
              name="saltChlorinator"
              apiRequest={getPoolEquipmentSaltChlorinators}
              transformResponse={(response: string[]) => {
                return response.map(record => ({
                  label: record ?? '',
                  value: record ?? '',
                }));
              }}
              onChange={e => {
                if (e.target.value === 'NONE') {
                  setFieldValue('sanitizer', 'CHLORINE');
                } else {
                  setFieldValue('sanitizer', 'SALT');
                }
                setFieldValue('saltChlorinator', e.target.value);
              }}
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              InputProps={{
                readOnly,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} xl={3}>
            <SelectAsync
              hasClear={!readOnly}
              autoComplete="nope"
              label="Algae Types"
              name="algaeType"
              apiRequest={getPoolEquipmentAlgaeTypes}
              transformResponse={(response: IDropdownResponse[]) => {
                return response.map(record => ({
                  label: record.description ?? '',
                  value: record.value ?? '',
                }));
              }}
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
              InputProps={{
                readOnly,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <TextField
              autoComplete="nope"
              label="Stains"
              name="stains"
              inputProps={{ maxLength: 255, readOnly }}
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <TextField
              autoComplete="nope"
              label="Pool Finish Condition"
              name="poolFinish"
              inputProps={{ maxLength: 255, readOnly }}
              onBlur={e => {
                handleBlur(e);
                handleFormChange();
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <DatePicker
              views={['year']}
              maxDate={new Date()}
              label="When Last Resurfaced"
              format="yyyy"
              onChange={(date: any) => {
                setFieldValue('lastResurfaced', date);
                handleFormChange({ ...values, lastResurfaced: date });
              }}
              readOnly={readOnly}
              value={values.lastResurfaced ? new Date(values.lastResurfaced) : null}
              slotProps={{
                textField: {
                  error: !!errors?.lastResurfaced && !!touched?.lastResurfaced,
                  size: 'small',
                  fullWidth: true,
                  inputProps: { readOnly: readOnly },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <DatePicker
              views={['year']}
              maxDate={new Date()}
              label="Year Pool Built"
              format="yyyy"
              onChange={(date: any) => {
                setFieldValue('poolBuilt', date);
                handleFormChange({ ...values, poolBuilt: date });
              }}
              readOnly={readOnly}
              value={values.poolBuilt ? new Date(values.poolBuilt) : null}
              slotProps={{
                textField: {
                  error: !!errors?.poolBuilt && !!touched?.poolBuilt,
                  size: 'small',
                  fullWidth: true,
                  inputProps: { readOnly: readOnly },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <FormControlLabel
              control={
                <Checkbox
                  name="hasSpa"
                  onChange={e => {
                    setFieldValue('hasSpa', Boolean(e.target.checked));
                    handleFormChange({ ...values, hasSpa: Boolean(e.target.checked) }, true);
                  }}
                  disabled={readOnly}
                />
              }
              label={<Typography variant="body2">Spa</Typography>}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
