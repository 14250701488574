import { FC, useState, useEffect } from 'react';
import {
  TextField,
  StandardTextFieldProps,
  MenuItem,
  InputAdornment,
  Box,
  Chip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { ISelectOption } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

interface ISelectAsyncInputProps extends StandardTextFieldProps {
  name: string;
  apiRequest: () => Promise<any>;
  transformResponse: (response: any) => ISelectOption[];
  hasClear?: boolean;
  onClear?: () => void;
  handleOptions?: (val: ISelectOption[]) => void;
  handleResponseOptions?: (val: any) => void;
  handleChange: (val: any) => void;
  value: any;
  shouldRefetch?: boolean;
  multiple?: boolean;
}

export const SelectAsyncInput: FC<ISelectAsyncInputProps> = ({
  name,
  apiRequest,
  transformResponse,
  hasClear = false,
  onClear,
  handleOptions,
  handleResponseOptions,
  handleChange,
  value,
  shouldRefetch,
  multiple,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState<ISelectOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await apiRequest();
      const data = transformResponse(response);
      setOptions(data);
      handleOptions && handleOptions(data);
      handleResponseOptions && handleResponseOptions(response);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Error loading ${name} dropdown`, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      fetchData();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldRefetch) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  const config = {
    fullWidth: true,
    size: 'small' as 'small',
    value,
    select: true,
    label: isLoading ? 'Loading...' : props.label,
    onChange: (e: any) => handleChange(e.target.value),
    disabled: props.disabled ?? isLoading,
    required: props.required ?? false,
    InputProps: {
      ...props.InputProps,
      ...(hasClear
        ? {
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  position: 'absolute',
                  right: 35,
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  title="Clear"
                  onClick={() => {
                    handleChange('');
                    onClear && onClear();
                  }}
                />
              </InputAdornment>
            ),
          }
        : undefined),
    },
  };

  return (
    <TextField
      {...config}
      SelectProps={{
        MenuProps: multiple
          ? {
              sx: {
                maxHeight: '30rem',
              },
            }
          : undefined,
        multiple,
        renderValue: multiple
          ? (selected: any) => {
              const selectedOptions = selected.map((value: string) => {
                return options.find(option => option.value === value)?.label;
              });
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selectedOptions.map((value: string) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              );
            }
          : undefined,
      }}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
