import { FC, useMemo } from 'react';
import { Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { IAgreementData, IAgreementItem } from '../../../models';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Loader, SimpleDataGrid } from '../../../components';
import { formatDate, formatMoney, formatShortFriendlyDateWithTime } from '../../../helpers';
import { theme } from '../../../styles';

interface IEstimateAgreement {
  hasSubmitted: boolean;
  isLoadingAgreement: boolean;
  agreementData: IAgreementData;
}

export const EstimateAgreement: FC<IEstimateAgreement> = ({
  hasSubmitted,
  isLoadingAgreement,
  agreementData,
}: IEstimateAgreement) => {
  const isMobile = useMediaQuery(`(max-width: 899px)`);

  const getTotalPlusTax = () => {
    if (agreementData?.items) {
      const total = agreementData.items.map(item => item.amount || 0).reduce((a, b) => a + b, 0);
      return formatMoney(total);
    }
    return 0;
  };

  const taxLineItem = agreementData?.items.filter(item => item.reference === 'Tax');
  const taxTotal = taxLineItem?.map(item => item.amount || 0)?.[0];
  const taxRate = taxLineItem?.map(item => item.rate || 0);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'lookupCode',
        headerName: 'Lookup Code',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 150,
      },
      {
        field: 'reference',
        headerName: 'Description',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 175,
        flex: 1,
        renderCell: (params: GridRenderCellParams<IAgreementItem>) => {
          const { row: lineItem } = params;
          if (lineItem.reference === 'Tax') {
            return <>{lineItem.reference ? `${lineItem.reference} ${lineItem.rate}%` : ''}</>;
          }
          return <>{lineItem.reference ? lineItem.reference : ''}</>;
        },
      },

      {
        field: 'serialNumber',
        headerName: 'Serial Number',
        disableColumnMenu: true,
        sortable: false,
        minWidth: agreementData?.showLineItemPrices ? 125 : 175,
      },
      agreementData?.showLineItemPrices && {
        field: 'rate',
        headerName: 'Price',
        disableColumnMenu: true,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IAgreementItem>) => {
          const { row: lineItem } = params;
          if (lineItem.reference === 'Tax') {
            return <></>;
          }
          return <>{lineItem.rate ? formatMoney(lineItem.rate) : ''}</>;
        },
      },
      {
        field: 'quantity',
        headerName: 'Qty',
        disableColumnMenu: true,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IAgreementItem>) => {
          const { row: lineItem } = params;
          if (lineItem.reference === 'Tax') {
            return <></>;
          }
          return <>{lineItem.quantity ? lineItem.quantity : ''}</>;
        },
      },
      agreementData?.showLineItemPrices && {
        field: 'amount',
        headerName: 'Total',
        disableColumnMenu: true,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IAgreementItem>) => {
          const { row: lineItem } = params;
          return <>{lineItem.amount ? formatMoney(lineItem.amount) : ''}</>;
        },
      },
    ].filter(Boolean) as GridColDef[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementData?.items]);

  return (
    <>
      {isLoadingAgreement && <Loader type="overlay" position="centered" title="Loading" />}
      {agreementData && (
        <Grid container pl={{ md: 1.5 }} pr={{ md: 1.5 }}>
          {agreementData?.logoUrl && (
            <Grid display={'flex'} item xs={12} justifyContent={'center'} mb={2}>
              <img
                src={agreementData?.logoUrl}
                style={{ height: '100%' }}
                width={'205'}
                height={'60'}
                alt="Logo"
              />
            </Grid>
          )}
          <Grid container flexWrap={'wrap'} justifyContent={'space-between'}>
            <Grid item mb={{ xs: 2 }}>
              {agreementData?.name}
              <br />
              {agreementData?.accountAddress1}
              <br />
              {agreementData?.accountAddress2}
            </Grid>
            <Grid item>
              {agreementData?.storeName}
              <br />
              {agreementData?.storeAddress1}
              <br />
              {agreementData?.storeAddress2}
              <br />
              {agreementData?.storePhoneNumber}
            </Grid>
          </Grid>
          {agreementData?.problemDescription && (
            <Grid item xs={12} mb={2}>
              <Typography variant="body1" fontWeight={700} sx={{ textDecoration: 'underline' }}>
                Job Description
              </Typography>
              <Typography variant="body1">{agreementData?.problemDescription}</Typography>
            </Grid>
          )}
          <Grid item paddingTop={2} width={'100%'} mb={2}>
            <SimpleDataGrid
              getRowId={(row: IAgreementItem) => {
                return row.itemId!;
              }}
              rows={
                isMobile
                  ? [...agreementData.items.filter(item => item.reference !== 'Tax')]
                  : [
                      ...agreementData.items,
                      agreementData?.showLineItemPrices && {
                        itemId: 'total',
                        quantity: 'Total:',
                        amount: getTotalPlusTax(),
                      },
                      agreementData?.securityDeposit &&
                        agreementData?.showLineItemPrices && {
                          itemId: 'deposit',
                          quantity: 'Deposit:',
                          amount: formatMoney(agreementData?.securityDeposit),
                        },
                      !agreementData?.showLineItemPrices && {
                        itemId: 'totalNoPrices',
                        serialNumber: 'Total:',
                        quantity: getTotalPlusTax(),
                      },
                      agreementData?.securityDeposit &&
                        !agreementData?.showLineItemPrices && {
                          itemId: 'depositNoPrices',
                          serialNumber: 'Deposit:',
                          quantity: formatMoney(agreementData?.securityDeposit),
                        },
                    ].filter(Boolean)
              }
              columns={columns}
              disableRowSelectionOnClick
              columnHeaderHeight={36}
              hideFooter
              hasMobileLayout
              mobileBreakPoint={900}
              mobileProps={{
                mobileDefaultAccessor: 'reference',
              }}
            />
          </Grid>
          {isMobile && (
            <Grid container item mb={2}>
              <Grid item container justifyContent={'flex-end'} mr={2}>
                <Typography variant="body1" color={theme.palette.primary.main} fontWeight={700}>
                  {`Tax ${taxRate}%: ${formatMoney(taxTotal)}`}
                </Typography>
              </Grid>
              <Grid item container justifyContent={'flex-end'} mr={2}>
                <Typography variant="body1" color={theme.palette.primary.main} fontWeight={700}>
                  {`Total: ${getTotalPlusTax()}`}
                </Typography>
              </Grid>
              {agreementData?.securityDeposit !== 0 && (
                <Grid item container justifyContent={'flex-end'} mr={2}>
                  <Typography variant="body1" color={theme.palette.primary.main} fontWeight={700}>
                    {`Deposit: ${formatMoney(agreementData?.securityDeposit)}`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item pt={2} pb={2}>
            <Typography variant="body1" fontWeight={700}>
              {`Estimate created ${formatDate(
                agreementData?.createDate
              )} - Valid until ${formatDate(agreementData?.expirationDate)}`}
            </Typography>
          </Grid>
          <Divider orientation={'horizontal'} sx={{ width: '100%', marginBlock: 1 }} />
          {agreementData.termsAndConditions && (
            <Grid item pt={2} pb={2}>
              <div
                dangerouslySetInnerHTML={{
                  __html: agreementData?.termsAndConditions as string,
                }}
              />
            </Grid>
          )}
          {hasSubmitted && (
            <Grid container item justifyContent={'flex-end'}>
              <Grid>
                {agreementData?.signature && (
                  <>
                    <img
                      src={agreementData?.signature}
                      alt="Signature"
                      style={{ maxHeight: '80px' }}
                    />
                    <Divider
                      orientation={'horizontal'}
                      sx={{
                        width: '100%',
                        height: '2px',
                        borderColor: 'black',
                        borderBottomWidth: '4px',
                      }}
                    />
                  </>
                )}
                <Typography variant="body1" fontWeight={700} textAlign={'right'}>
                  Approved By {agreementData?.approvedBy}
                </Typography>
                <Typography variant="body1" fontWeight={700} textAlign={'right'}>
                  {formatShortFriendlyDateWithTime(agreementData?.approvalDate)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};
