import { Box, Alert, Button, Typography, IconButton, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoute } from '@fortawesome/free-solid-svg-icons';
import { Close } from '@mui/icons-material';
import { FC, useState } from 'react';

interface IUnsortStopsAlert {
  onOptimizeSwitch?: () => void;
}

export const UnsortedStopsAlert: FC<IUnsortStopsAlert> = ({ onOptimizeSwitch }) => {
  const [dismissedUnsortedItemsWarning, setDismissedUnsortedItemsWarning] = useState(false);

  if (dismissedUnsortedItemsWarning) {
    return null;
  }
  return (
    <Box mt={1} mb={1}>
      <StyledAlert severity="error">
        <Box display="flex">
          <Box display="flex" flex="1 1 auto" gap={1} alignItems="center" flexWrap="wrap">
            <Typography fontWeight={600}>
              Unsorted stops exist - Please optimize these routes!
            </Typography>
            <Button
              size="small"
              color="primary"
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faRoute} size="lg" />}
              onClick={onOptimizeSwitch}
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
              Optimize
            </Button>
          </Box>
          <div>
            <IconButton onClick={() => setDismissedUnsortedItemsWarning(true)} size="small">
              <Close />
            </IconButton>
          </div>
        </Box>
      </StyledAlert>
    </Box>
  );
};

const StyledAlert = styled(Alert)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  padding: '0 8px',

  '& .MuiAlert-message': {
    flex: '1 1 auto',
  },
}));
