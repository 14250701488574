import { Stack } from '@mui/material';
import { Loader } from '../../../components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { signEstimateAgreement } from '../../../fetch';
import { useState, FC } from 'react';
import { useSnackbar } from 'notistack';
import {
  IAgreementData,
  IAgreementStatus,
  IPaymentProcessorModel,
  PaymentProcessor,
} from '../../../models';
import { PaymentMethod } from './payment-method';
import { SignatureCard } from './signature-card';
import { generateUUID } from '../../../helpers';

const Schema = yup.object().shape({
  name: yup.mixed().when(['selectedTab', 'creditCardRequired', 'isAdyen'], {
    is: (selectedTab: string, creditCardRequired: boolean, isAdyen: boolean) =>
      selectedTab === 'new-card' && creditCardRequired && !isAdyen,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  street: yup.mixed().when(['selectedTab', 'creditCardRequired', 'isAdyen'], {
    is: (selectedTab: string, creditCardRequired: boolean, isAdyen: boolean) =>
      selectedTab === 'new-card' && creditCardRequired && !isAdyen,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  city: yup.mixed().when(['selectedTab', 'creditCardRequired', 'isAdyen'], {
    is: (selectedTab: string, creditCardRequired: boolean, isAdyen: boolean) =>
      selectedTab === 'new-card' && creditCardRequired && !isAdyen,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  state: yup.mixed().when(['selectedTab', 'creditCardRequired', 'isAdyen'], {
    is: (selectedTab: string, creditCardRequired: boolean, isAdyen: boolean) =>
      selectedTab === 'new-card' && creditCardRequired && !isAdyen,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  zipCode: yup.mixed().when(['selectedTab', 'creditCardRequired', 'isAdyen'], {
    is: (selectedTab: string, creditCardRequired: boolean, isAdyen: boolean) =>
      selectedTab === 'new-card' && creditCardRequired && !isAdyen,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  cardNumber: yup.mixed().when(['selectedTab', 'creditCardRequired', 'isAdyen'], {
    is: (selectedTab: string, creditCardRequired: boolean, isAdyen: boolean) =>
      selectedTab === 'new-card' && creditCardRequired && !isAdyen,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  expirationMonth: yup.mixed().when(['selectedTab', 'creditCardRequired', 'isAdyen'], {
    is: (selectedTab: string, creditCardRequired: boolean, isAdyen: boolean) =>
      selectedTab === 'new-card' && creditCardRequired && !isAdyen,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  expirationYear: yup.mixed().when(['selectedTab', 'creditCardRequired', 'isAdyen'], {
    is: (selectedTab: string, creditCardRequired: boolean, isAdyen: boolean) =>
      selectedTab === 'new-card' && creditCardRequired && !isAdyen,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  cvv: yup.mixed().when(['selectedTab', 'creditCardRequired', 'isAdyen'], {
    is: (selectedTab: string, creditCardRequired: boolean, isAdyen: boolean) =>
      selectedTab === 'new-card' && creditCardRequired && !isAdyen,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  printedName: yup.string().required('Required'),
  hasConfirmedAgreement: yup.boolean().required('Required'),
  hasConfirmedAuthorization: yup.boolean().required('Required'),
  recurringPaymentId: yup.mixed().when(['selectedTab', 'creditCardRequired'], {
    is: (selectedTab: number, creditCardRequired: boolean) => {
      return selectedTab === 1 && creditCardRequired;
    },
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  selectedTab: yup.string(),
  creditCardRequired: yup.boolean(),
  isAdyen: yup.boolean(),
});

const INITIAL_FORM_VALUES = {
  name: '',
  cardNumber: '',
  expirationMonth: '',
  expirationYear: '',
  cvv: '',
  printedName: '',
  hasConfirmedAgreement: false,
  hasConfirmedAuthorization: false,
  recurringPaymentId: '',
  selectedTab: 'new-card',
};

interface IContractPageDetails {
  estimateAgreementStatus?: IAgreementStatus;
  repairId?: string;
  estimateId?: string;
  fetchEstimateAgreementStatus: () => void;
  hasSubmitted: boolean;
  token: string;
  agreementData: IAgreementData;
  paymentProcessorData: IPaymentProcessorModel | undefined;
  isLoadingPaymentProcessor: boolean;
}

export const ContractPageDetails: FC<IContractPageDetails> = ({
  estimateAgreementStatus,
  repairId,
  estimateId,
  fetchEstimateAgreementStatus,
  hasSubmitted,
  token,
  agreementData,
  paymentProcessorData,
  isLoadingPaymentProcessor,
}) => {
  const [sigPadInstance, setSigPadInstance] = useState<SignaturePad | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [shouldCallAdyenSession, setShouldCallAdyenSession] = useState<boolean>(false);
  const [showAdyenPaymentSection, setShowAdyenPaymentSection] = useState<boolean>(false);
  const [isLoadingAdyenPaymentSection, setIsLoadingAdyenPaymentSection] = useState<boolean>(false);
  const [guid, setGuid] = useState<string>('');
  const isAdyen = paymentProcessorData?.paymentProcessor === PaymentProcessor.Adyen;

  const handleAdyenAgreeAndContinue = () => {
    setIsLoadingAdyenPaymentSection(true);
    setGuid(generateUUID());
    setShouldCallAdyenSession(true);

    setTimeout(() => {
      setShowAdyenPaymentSection(true);
      setIsLoadingAdyenPaymentSection(false);
      setShouldCallAdyenSession(false);
    }, 2000);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...INITIAL_FORM_VALUES,
        name: estimateAgreementStatus?.accountName ?? '',
        street: estimateAgreementStatus?.addressModel?.street ?? '',
        city: estimateAgreementStatus?.addressModel?.city ?? '',
        state: estimateAgreementStatus?.addressModel?.state ?? '',
        zipCode: estimateAgreementStatus?.addressModel?.postalCode ?? '',
        creditCardRequired: estimateAgreementStatus?.creditCardRequired ? true : false,
        isAdyen,
      }}
      validationSchema={Schema}
      onSubmit={async (values, actions) => {
        try {
          const formData = new FormData();
          const res = await fetch(sigPadInstance?.toDataURL() as string);
          const blob = await res.blob();
          formData.append('Body.Signature', blob);
          formData.append('Body.Name', values.printedName.trim());
          if (estimateAgreementStatus?.creditCardRequired) {
            if (values.selectedTab === 'new-card' && !isAdyen) {
              formData.append('Body.CreditCardNumber', values.cardNumber);
              formData.append('Body.ExpirationMonth', values.expirationMonth);
              formData.append('Body.ExpirationYear', values.expirationYear);
              formData.append('Body.Cvv', values.cvv);
              formData.append('Body.Street', values.street);
              formData.append('Body.City', values.city);
              formData.append('Body.State', values.state);
              formData.append('Body.PostalCode', values.zipCode);
            } else if (isAdyen) {
              formData.append('Body.Street', values.street);
              formData.append('Body.City', values.city);
              formData.append('Body.State', values.state);
              formData.append('Body.PostalCode', values.zipCode);
            } else {
              formData.append('Body.RecurringPaymentId', values.recurringPaymentId);
            }
          }
          formData.append('Body.EstimateId', estimateId as string);
          formData.append('Body.RepairId', repairId as string);
          isAdyen && formData.append('Body.Reference', guid);
          await signEstimateAgreement(token as string, formData);
          enqueueSnackbar('Service agreement signed!', {
            variant: 'success',
          });
          fetchEstimateAgreementStatus();
        } catch (error: any) {
          enqueueSnackbar(error?.Detail || `Error signing service agreement, please try again.`, {
            variant: 'error',
          });
        } finally {
          // disable the signature canvas
          sigPadInstance?.off();
        }
      }}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        handleSubmit,
        isValid,
        handleBlur,
        validateForm,
      }) => {
        const handleAdyenPaymentComplete = () => {
          handleSubmit();
        };
        return (
          <>
            {isSubmitting && <Loader type="fullscreen" position="centered" />}
            <Form onSubmit={isAdyen ? handleAdyenPaymentComplete : handleSubmit}>
              {!hasSubmitted && (
                <>
                  {isLoadingPaymentProcessor && <Loader type="inline" position="centered" />}
                  {paymentProcessorData && (
                    <Stack gap={2} flexDirection={isAdyen ? 'column-reverse' : 'column'}>
                      {estimateAgreementStatus?.creditCardRequired && (
                        <PaymentMethod
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          repairId={repairId}
                          estimateId={estimateId}
                          token={token}
                          validateForm={validateForm}
                          estimateAgreementStatus={estimateAgreementStatus}
                          agreementData={agreementData}
                          guid={guid}
                          shouldCallAdyenSession={shouldCallAdyenSession}
                          showAdyenPaymentSection={showAdyenPaymentSection}
                          afterAdyenPaymentComplete={handleAdyenPaymentComplete}
                          handleAdyenAgreeAndContinue={handleAdyenAgreeAndContinue}
                          paymentProcessorData={paymentProcessorData}
                          setShouldCallAdyenSession={setShouldCallAdyenSession}
                        />
                      )}
                      <SignatureCard
                        values={values}
                        setFieldValue={setFieldValue}
                        hasSubmitted={hasSubmitted}
                        isSubmitting={isSubmitting || isLoadingAdyenPaymentSection}
                        isValid={isValid}
                        sigPadInstance={sigPadInstance}
                        setSigPadInstance={setSigPadInstance}
                        officeName={estimateAgreementStatus?.officeName}
                        handleAdyenAgreeAndContinue={handleAdyenAgreeAndContinue}
                        display={isAdyen && showAdyenPaymentSection ? false : true}
                        isAdyen={isAdyen}
                      />
                    </Stack>
                  )}
                </>
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
