import {
  Box,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Typography,
  IconButton,
  FormGroup,
  Tooltip,
  Grid,
  FormHelperText,
  Divider,
  styled,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useFormikContext } from 'formik';
import { FC, useState, useEffect, useContext, useMemo, SetStateAction, Dispatch } from 'react';
import {
  CardTitle,
  Card,
  SiteAutocomplete,
  MultipleEmailInput,
  DateTimePicker,
  Link,
} from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { formatMoney, formatShortFriendlyDateWithTime, formatDate } from '../../helpers';
import {
  IListUser,
  ILookupModel,
  IScheduledServiceDetail,
  IServiceDefinition,
  IEmailTemplate,
  ISiteSimple,
  IAccountDetail,
  IServiceSuggestionChecklist,
} from '../../models';
import {
  getServiceDefinitions,
  getServiceStatuses,
  getEmailTemplates,
  getAccount,
} from '../../fetch';
import { ServiceSuggestions } from './ServiceSuggestions';
import { UserContext } from '../../context';
import { useDebounce, useUnload } from '../../hooks';
import { isPast, isToday, isBefore } from 'date-fns';
import { ServiceDetailsCardDisplay } from './service-details-card-display';
import { useQuery } from 'react-query';
import { ChecklistInfo } from './ChecklistInfo';

interface IServiceDetailsCard {
  isLoadingUsers: boolean;
  users: IListUser[];
  isRecurring?: boolean;
  isEdit?: boolean;
  schedule: IScheduledServiceDetail | null;
  serviceId: string;
  recurringId?: string;
  siteId?: string | null;
  isNew?: boolean;
  isExpanded?: boolean;
  cardTitle?: string;
  isInvalidInitialDate?: boolean;
  setIsInvalidInitialDate?: Dispatch<SetStateAction<boolean>>;
  setIsInvalidEndDate?: Dispatch<
    SetStateAction<{
      reason: string;
      check: boolean;
    }>
  >;
  readOnly?: boolean;
  accountId?: string;
  isCardLayout?: boolean;
  setServiceDefinitions: Dispatch<SetStateAction<IServiceDefinition[]>>;
  serviceDefinitions: IServiceDefinition[];
  setSelectedSite?: Dispatch<SetStateAction<ISiteSimple | null>>;
  selectedSite?: ISiteSimple | null;
  withChecklistInfo?: boolean;
}
export const ServiceDetailsCard: FC<IServiceDetailsCard> = ({
  isLoadingUsers,
  users,
  isRecurring,
  isEdit,
  schedule,
  serviceId,
  siteId,
  isNew,
  isExpanded,
  cardTitle,
  recurringId,
  isInvalidInitialDate,
  setIsInvalidInitialDate,
  setIsInvalidEndDate,
  readOnly,
  accountId,
  isCardLayout = true,
  setServiceDefinitions,
  serviceDefinitions,
  setSelectedSite,
  selectedSite,
  withChecklistInfo,
}) => {
  // context
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const { setFieldValue, handleBlur, errors, touched, values, dirty, setFieldTouched } =
    useFormikContext<any>();
  // styles

  // state
  const [isSuggestionTableToggled, setIsSuggestionTableToggled] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState(values?.description ?? '');
  const [completionValue, setCompletionValue] = useState(values?.completionNotes ?? '');
  const [earliestArrival, setEarliestArrival] = useState(values?.earliestArrival ?? '');
  const [latestArrival, setLatestArrival] = useState(values?.latestArrival ?? '');
  const [estimatedDuration, setEstimatedDuration] = useState(values?.estimatedDuration ?? '');
  const [startingTime, setStartingTime] = useState(values?.startingTime ?? '');
  const [siteOptions, setSiteOptions] = useState<ISiteSimple[]>([]);

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, dirty);

  useEffect(() => {
    if (
      siteOptions &&
      siteOptions?.length > 0 &&
      values.siteId &&
      setSelectedSite &&
      !selectedSite
    ) {
      const site = siteOptions.find(site => site.siteId === values.siteId);
      if (site) {
        setSelectedSite?.(site);
      }
    }
  }, [siteOptions, setSelectedSite, values.siteId, selectedSite]);

  const [isLoadingServiceDefinitions, setIsLoadingServiceDefinitions] = useState(false);
  const fetchServiceDefinitions = async () => {
    setIsLoadingServiceDefinitions(true);
    try {
      const res = await getServiceDefinitions({
        perPage: -1,
        officeId: user?.officeId,
        siteId: values.siteId,
      });
      setServiceDefinitions(res.records);
    } catch (error) {
      enqueueSnackbar(`Error loading Service Definitions, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingServiceDefinitions(false);
    }
  };

  const [emailTemplates, setEmailTemplates] = useState<IEmailTemplate[] | null>(null);
  const [isLoadingEmailTemplates, setLoadingEmailTemplates] = useState(false);
  const fetchEmailTemplates = async () => {
    setLoadingEmailTemplates(true);
    try {
      const res = await getEmailTemplates({ officeId: user?.officeId, perPage: -1, event: 'S' });
      setEmailTemplates(res.records);
    } catch (error) {
      enqueueSnackbar(`Error loading email templates, please try again.`, {
        variant: 'error',
      });
    } finally {
      setLoadingEmailTemplates(false);
    }
  };

  const [statuses, setStatuses] = useState<ILookupModel[]>([]);
  const [isLoadingStatuses, setIsLoadingStatuses] = useState(false);
  const fetchStatuses = async () => {
    setIsLoadingStatuses(true);
    try {
      const res = await getServiceStatuses();
      setStatuses(res);
    } catch (error) {
      enqueueSnackbar(`Error loading statuses, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingStatuses(false);
    }
  };

  const { isLoading: isLoadingAccountDetails, data: accountDetails } = useQuery<IAccountDetail>(
    ['getAccountDetails'],
    async () => {
      const res = await getAccount(accountId!!);
      return res;
    },
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!accountId,
    }
  );

  const customTitle = useMemo(() => {
    const defaultTitle = 'Service Details';
    if (!!cardTitle) {
      return cardTitle;
    }
    if (isRecurring) {
      return `Recurring ${defaultTitle}`;
    } else {
      return `Single ${defaultTitle}`;
    }
  }, [cardTitle, isRecurring]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      fetchServiceDefinitions();
      fetchStatuses();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.sendEmail) {
      fetchEmailTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.sendEmail]);

  useDebounce(
    () => {
      // Allows for delayed update to the form so it doesn't re-render on every change
      if (setFieldValue && descriptionValue !== values.description) {
        setFieldValue('description', descriptionValue);
      }
      // Allows for delayed update to the form so it doesn't re-render on every change
      if (setFieldValue && completionValue !== values.completionNotes) {
        setFieldValue('completionNotes', completionValue);
      }
      if (earliestArrival && setFieldValue && earliestArrival !== values.earliestArrival) {
        setFieldValue('earliestArrival', earliestArrival);
      }
      if (latestArrival && setFieldValue && latestArrival !== values.latestArrival) {
        setFieldValue('latestArrival', latestArrival);
      }
      if (startingTime && setFieldValue && startingTime !== values.startingTime) {
        setFieldValue('startingTime', startingTime);
      }
      if (setFieldValue && estimatedDuration !== values.estimatedDuration) {
        setFieldValue('estimatedDuration', estimatedDuration);
      }
    },
    750,
    [
      descriptionValue,
      setFieldValue,
      completionValue,
      earliestArrival,
      latestArrival,
      startingTime,
      estimatedDuration,
    ]
  );

  if (!isCardLayout) {
    return (
      <Box>
        <ServiceDetailsCardDisplay
          site={siteOptions?.find(site => site.siteId === values.siteId)}
          serviceDefinitions={serviceDefinitions}
          isLoading={isLoadingServiceDefinitions || isLoadingUsers}
          users={users}
          schedule={schedule}
        />
        {withChecklistInfo && (
          <>
            <Box mt={1}>
              <Divider />
            </Box>
            <Box mt={1}>
              <ChecklistInfo
                serviceId={serviceId}
                serviceDefId={values.serviceDefId}
                checklistInfo={values.serviceChecklistItems as IServiceSuggestionChecklist[]}
                setChecklistInfo={data => {
                  setFieldValue('serviceChecklistItems', data);
                }}
                dirty={false}
                isExpanded={true}
                readonly
                noCardList
                showLoading={false}
              />
            </Box>
          </>
        )}
      </Box>
    );
  }

  if (readOnly && isCardLayout) {
    return (
      <Card className={classes.card}>
        <CardTitle title={customTitle} withExpand overrideExpand={isExpanded}>
          <ServiceDetailsCardDisplay
            site={siteOptions?.find(site => site.siteId === values.siteId)}
            serviceDefinitions={serviceDefinitions}
            isLoading={isLoadingServiceDefinitions || isLoadingUsers}
            users={users}
            schedule={schedule}
          />
        </CardTitle>
      </Card>
    );
  }
  return (
    <StyledCard>
      <CardTitle title={customTitle} withExpand overrideExpand={isExpanded}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} className={clsx('col-print-12', classes.singleServiceFirstCol)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7} className="col-print-12">
                <Box>
                  <SiteAutocomplete
                    handleChange={val => {
                      setFieldValue('siteId', val);
                      fetchServiceDefinitions();
                    }}
                    handleBlur={handleBlur}
                    siteId={siteId ?? values.siteId}
                    isDisabled={!isNew}
                    handleOptions={sites => setSiteOptions(sites)}
                    accountId={accountId}
                    setSelectedSite={setSelectedSite}
                  />
                </Box>
              </Grid>
              {errors.siteId && touched.siteId && (
                <FormHelperText className={classes.error}>{errors.siteId}</FormHelperText>
              )}
              <Grid item xs={12} md={5} className="col-print-6">
                <DatePicker
                  label={isRecurring ? 'Initial Date' : 'Scheduled Service Date'}
                  format="MM/dd/yyyy"
                  onChange={(date: any) => {
                    if (isRecurring) {
                      setFieldValue('initialDate', date);
                      setIsInvalidInitialDate?.(isPast(new Date(date)) && !isToday(date));
                      if (values.endDate) {
                        setIsInvalidEndDate?.({
                          reason: isPast(new Date(values.endDate)) ? 'inPast' : 'invalid',
                          check:
                            formatDate(values.endDate) === formatDate(date)
                              ? false
                              : isBefore(new Date(values.endDate), new Date(date)) ||
                                isPast(new Date(values.endDate)),
                        });
                      }
                    } else {
                      setFieldValue('serviceDate', date);
                    }
                  }}
                  minDate={new Date()}
                  value={isRecurring ? new Date(values.initialDate) : new Date(values.serviceDate)}
                  slotProps={{
                    textField: {
                      error:
                        errors.initialDate === 'Invalid Date' ||
                        errors.serviceDate === 'Invalid Date' ||
                        isInvalidInitialDate,

                      size: 'small',
                      fullWidth: true,
                      required: true,
                      helperText: isInvalidInitialDate ? 'Invalid date past' : '',
                    },
                  }}
                />
                {(errors.initialDate === 'Invalid Date' ||
                  errors.serviceDate === 'Invalid Date') && (
                  <FormHelperText className={classes.error}>
                    {errors.initialDate || errors.serviceDate}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6} className="col-print-6">
                <TextField
                  fullWidth
                  select
                  name="serviceDefId"
                  label={isLoadingServiceDefinitions ? 'Loading...' : 'Service'}
                  size="small"
                  required
                  helperText={errors.serviceDefId && touched.serviceDefId && errors.serviceDefId}
                  onChange={(e: any) => {
                    setFieldValue('serviceDefId', e.target.value);
                    const selectedDef = serviceDefinitions.find(
                      ser => ser.serviceDefId === e.target.value
                    );
                    if (selectedDef) {
                      setFieldValue(
                        'serviceAmount',
                        formatMoney(selectedDef.defaultAmountToCharge, 2)
                      );
                      setFieldValue('transactionId', selectedDef.transactionCodeId);
                    }
                  }}
                  onBlur={e => {
                    handleBlur(e);
                    if (values.override) {
                      setFieldValue('serviceAmount', formatMoney(e.target.value, 2));
                    }
                  }}
                  value={values.serviceDefId}
                  disabled={isLoadingServiceDefinitions}
                  error={!!touched?.serviceDefId && !!errors?.serviceDefId}
                >
                  {serviceDefinitions.map(service => (
                    <MenuItem key={service.serviceDefId} value={service.serviceDefId}>
                      {service.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6} className="col-print-6">
                <FormGroup row sx={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    sx={{ flex: 1 }}
                    name="assignedTo"
                    label="Assign To"
                    select
                    onBlur={e => handleBlur(e)}
                    onChange={(e: any) => {
                      setFieldValue('assignedTo', e.target.value);
                      setFieldValue('previousSiteId', null);
                    }}
                    value={values.assignedTo}
                    size="small"
                    disabled={isLoadingUsers}
                    InputProps={{
                      classes: {
                        input: classes.assignedToSelect,
                      },
                      endAdornment: values.assignedTo && (
                        <InputAdornment className={classes.assignedToIconClear} position="end">
                          <FontAwesomeIcon
                            icon={faClose}
                            title="Clear"
                            onClick={() => setFieldValue('assignedTo', '')}
                          />
                        </InputAdornment>
                      ),
                    }}
                    required
                    error={!!touched?.assignedTo && !!errors?.assignedTo}
                  >
                    {users.map(user => (
                      <MenuItem
                        key={`assignTo-${user.userId}`}
                        value={user.userId}
                        disabled={user.isDisabled}
                      >
                        {user.userName} {user.isDisabled ? `(Disabled)` : ''}
                      </MenuItem>
                    ))}
                  </TextField>
                  {serviceId !== 'new' && serviceId !== 'recurring' && (
                    <Tooltip
                      placement="bottom"
                      title={
                        <div>
                          Find Suggestion
                          <br />
                          <small>Requires Site & Date</small>
                        </div>
                      }
                    >
                      <Box display="flex">
                        <IconButton
                          color="primary"
                          sx={{ marginTop: 0.5, backgroundColor: 'transparent !important' }}
                          disabled={
                            !!values.siteId &&
                            ((isRecurring && !!values.initialDate) ||
                              (!isRecurring && !!values.serviceDate))
                              ? false
                              : true
                          }
                          onClick={() => {
                            setIsSuggestionTableToggled(!isSuggestionTableToggled);
                          }}
                        >
                          <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  )}
                </FormGroup>
                {errors.assignedTo && touched.assignedTo && (
                  <FormHelperText className={classes.error}>{errors.assignedTo}</FormHelperText>
                )}
              </Grid>
              {!isRecurring && (
                <>
                  <Grid item xs={12} md={6} className="col-print-6">
                    <TextField
                      fullWidth
                      label="Status"
                      select
                      onChange={(e: any) => {
                        setFieldValue('status', e.target.value);
                        if (values.sendEmail) {
                          setFieldValue('sendEmail', false);
                          setFieldValue('emailTemplateId', '');
                          setFieldValue('emailAddress', []);
                          setFieldValue('emailAddressBcc', []);
                        }
                        // reset when status changes
                        if (e.target.value !== 'Closed' && values.completionNotes) {
                          setFieldValue('completionNotes', '');
                          setCompletionValue('');
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.status}
                      size="small"
                      disabled={isLoadingStatuses}
                    >
                      {statuses.map(status => (
                        <MenuItem key={`status-${status.value}`} value={status.value}>
                          {status.description}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.status && touched.status && (
                      <FormHelperText className={classes.error}>{errors.status}</FormHelperText>
                    )}
                  </Grid>
                  {values.status === 'Closed' && isEdit && schedule?.whenCompleted && (
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      className="col-print-6"
                      style={{ alignSelf: 'center' }}
                    >
                      <Typography>
                        Completion Date:{' '}
                        <Typography color="secondary" component="span">
                          {schedule?.whenCompleted
                            ? formatShortFriendlyDateWithTime(schedule?.whenCompleted)
                            : ''}
                        </Typography>
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {(!isRecurring || (isEdit && schedule)) && (
            <Grid item xs={12} lg={6} className="col-print-12">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} className="col-print-12">
                  <DateTimePicker
                    label="Estimated Earliest Arrival"
                    value={earliestArrival}
                    onChange={(dateTime: any) => {
                      setEarliestArrival(dateTime);
                    }}
                    error={!!errors.earliestArrival}
                  />
                  {errors.earliestArrival && touched.earliestArrival && (
                    <FormHelperText className={classes.error}>
                      {errors.earliestArrival}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={6} className="col-print-6">
                  <DateTimePicker
                    label="Estimated Latest Arrival"
                    value={latestArrival}
                    disabled={!values.earliestArrival}
                    minDate={new Date(values.earliestArrival)}
                    onChange={(dateTime: any) => {
                      setLatestArrival(dateTime);
                    }}
                    error={!!errors.latestArrival}
                    textFieldHelperText={
                      !!errors.latestArrival
                        ? `Please make sure you've typed the date and time correctly. MM:DD:YYYY HH:MM and "am" or "pm"`
                        : undefined
                    }
                  />
                  {errors.latestArrival && touched.latestArrival && (
                    <FormHelperText className={classes.error}>
                      {errors.latestArrival}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={6} className="col-print-6">
                  <TextField
                    label="Estimated Duration (minutes)"
                    value={estimatedDuration}
                    size="small"
                    fullWidth
                    onChange={(e: any) => {
                      const newVal = e.target.value;
                      if (newVal === '') {
                        return setEstimatedDuration('');
                      }
                      const onlyNums = newVal.replace(/[^\d]/g, '');
                      setEstimatedDuration(Number(Math.round(onlyNums)));
                    }}
                    onBlur={e => {
                      handleBlur(e);
                      setFieldTouched('estimatedDuration', true);
                    }}
                    error={touched?.estimatedDuration && !!errors?.estimatedDuration}
                    helperText={touched?.estimatedDuration && errors?.estimatedDuration}
                    inputProps={{ maxLength: 4 }}
                  />
                </Grid>
                <Grid item xs={12} md={6} className="col-print-6">
                  <DateTimePicker
                    label="Starting Time"
                    value={startingTime || null}
                    onChange={(dateTime: any) => {
                      setStartingTime(dateTime);
                    }}
                    error={!!errors.startingTime}
                  />
                  {errors.startingTime && touched.startingTime && (
                    <FormHelperText className={classes.error}>{errors.startingTime}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            {((!isNew && isSuggestionTableToggled) || isNew) && (
              <ServiceSuggestions
                isRecurring={isRecurring}
                recurringId={recurringId}
                scheduledServiceId={schedule?.scheduledServiceId}
                setIsSuggestionTableToggled={setIsSuggestionTableToggled}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              placeholder={'Description'}
              label={'Description'}
              rows={4}
              name={'description'}
              onChange={e => {
                setDescriptionValue(e.target.value);
              }}
              onBlur={handleBlur}
              value={descriptionValue}
              className={clsx('print--none')}
              size="small"
              error={touched?.description && errors?.description ? true : false}
              helperText={touched?.description && errors?.description}
            />
            {/* Used for the print screen only */}
            {values.description && (
              <div className={clsx('print--only')}>
                <label className="print--shrink-label MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled css-ahtaft-MuiFormLabel-root-MuiInputLabel-root">
                  Description
                </label>
                {values.description}
              </div>
            )}
          </Grid>
          {values.status === 'Closed' && isEdit && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  placeholder={'Completion Notes'}
                  label={'Completion Notes'}
                  rows={4}
                  name={'completionNotes'}
                  onChange={e => setCompletionValue(e.target.value)}
                  onBlur={handleBlur}
                  value={completionValue}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  color="primary"
                  control={
                    <Checkbox
                      checked={values.sendEmail}
                      id="send-email"
                      color="primary"
                      name="sendEmail"
                      onChange={(_, checked: boolean) => {
                        setFieldValue('sendEmail', checked);
                        setFieldValue('emailTemplateId', '');
                        setFieldValue('emailAddress', []);
                        setFieldValue('emailAddressBcc', []);

                        if (checked) {
                          setFieldValue('emailTemplateId', emailTemplates?.[0]?.emailTemplateId);
                          accountDetails?.emails &&
                            setFieldValue('emailAddress', accountDetails?.emails);
                        }
                      }}
                    />
                  }
                  label="Send Email"
                />
              </Grid>
              {values.sendEmail && (
                <>
                  <Grid item xs={12} sm={6} md={4} className="col-print-12">
                    <TextField
                      fullWidth
                      label="Email Template"
                      select
                      onChange={(e: any) => {
                        setFieldValue('emailTemplateId', e.target.value);
                      }}
                      value={values.emailTemplateId}
                      size="small"
                      disabled={isLoadingEmailTemplates}
                    >
                      {emailTemplates?.map((template, index) => (
                        <MenuItem key={`${index}`} value={template.emailTemplateId}>
                          {template.emailTemplateName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className="col-print-12">
                    <MultipleEmailInput
                      fullWidth
                      size="small"
                      label="Email Address"
                      value={values.emailAddress?.length ? values.emailAddress : []}
                      onChange={(_e, newEmailsValue) => {
                        setFieldValue('emailAddress', newEmailsValue);
                      }}
                      disabled={isLoadingAccountDetails}
                      required
                      name="emailAddress"
                      onBlur={handleBlur}
                      hasError={
                        !values.emailAddress || values.emailAddress?.length === 0 ? true : false
                      }
                      helperText={
                        !values.emailAddress || values.emailAddress?.length === 0 ? 'Required' : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className="col-print-12">
                    <MultipleEmailInput
                      fullWidth
                      size="small"
                      label="Email Bcc"
                      value={values?.emailAddressBcc}
                      onChange={(_e, newEmailsValue) => {
                        setFieldValue('emailAddressBcc', newEmailsValue);
                      }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          {!isRecurring && schedule?.recurringServiceId && (
            <Grid item>
              <Typography variant="body1" component={'span'} fontWeight={'700'} mr={1}>
                Repeat Pattern:
              </Typography>
              <Link to={`/services/maintenance/recurring/${schedule?.recurringServiceId}`}>
                {schedule?.frequency}
              </Link>
            </Grid>
          )}
        </Grid>
      </CardTitle>
    </StyledCard>
  );
};

const PREFIX = 'ServiceDetailsCard';

const classes = {
  card: `${PREFIX}-card`,
  error: `${PREFIX}-error`,
  singleServiceFirstCol: `${PREFIX}-singleServiceFirstCol`,
  assignedToSelect: `${PREFIX}-assignedToSelect`,
  assignedToIconClear: `${PREFIX}-assignedToIconClear`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '@media print': { marginBottom: '3rem' },

  [`& .${classes.error}`]: { color: theme.palette.error.main, display: 'block' },

  [`& .${classes.singleServiceFirstCol}`]: {
    [theme.breakpoints.up(1200)]: {
      // @ts-ignore
      borderRight: ({ isRecurring }) =>
        isRecurring ? 'none' : `2px solid ${theme.palette.secondary.light}`,
      paddingRight: theme.spacing(2),
    },
  },

  [`& .${classes.assignedToSelect}`]: {
    paddingRight: `0px !important`,
  },

  [`& .${classes.assignedToIconClear}`]: {
    position: 'absolute',
    right: 30,
    cursor: 'pointer',
  },
}));
