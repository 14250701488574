import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FC } from 'react';
import {
  ICalendarDateRange,
  ICalendarView,
  IColorSetMap,
  IListUser,
  IOneTimeServiceDetail,
  IRepair,
} from '../../../../models';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { OTSVisitCalendar } from '../ots-visit-calendar';
import { DateRangeNavigator } from '../../../../components';

interface IOTSSchedulerCalendarTabProps {
  service?: IOneTimeServiceDetail | null;
  allowCreate?: boolean;
  selectedTechId: string;
  setSelectedTechId: (val: string) => void;
  isLoadingTechs: boolean;
  techs: IListUser[];
  setSelectedTech: (val: IListUser | null) => void;
  isShowingSelectedTech: boolean;
  setIsShowingSelectedTech: (val: boolean) => void;
  selectedDateRange: ICalendarDateRange | undefined;
  setSelectedDateRange: (val: ICalendarDateRange | undefined) => void;
  selectedTech: IListUser | null;
  visitsColorMap: IColorSetMap;
  scheduledVisits: IRepair[];
  setScheduledVisits: (val: IRepair[]) => void;
  setDeletedVisits: (val: string[]) => void;
  deletedVisits: string[];
  setIsSingleViewMode: (val: boolean) => void;
  isSingleViewMode: boolean;
  isSmMobile: boolean;
}

export const OTSSchedulerCalendarTab: FC<IOTSSchedulerCalendarTabProps> = ({
  service,
  allowCreate,
  selectedTechId,
  setSelectedTechId,
  isLoadingTechs,
  techs,
  setSelectedTech,
  isShowingSelectedTech,
  setIsShowingSelectedTech,
  selectedDateRange,
  setSelectedDateRange,
  selectedTech,
  visitsColorMap,
  scheduledVisits,
  setScheduledVisits,
  setDeletedVisits,
  deletedVisits,
  setIsSingleViewMode,
  isSingleViewMode,
  isSmMobile,
}) => {
  return (
    <>
      <Grid spacing={isSmMobile ? 1 : 2} mb={1} mt={2} container>
        <Grid item xs={12} md={2}>
          <FormControl size="small" fullWidth>
            <InputLabel>Technician</InputLabel>
            <Select
              label="Technician"
              size="small"
              required
              value={selectedTechId}
              disabled={isLoadingTechs}
              onChange={e => {
                setSelectedTechId(e.target.value);
                const selected = techs.find(tech => tech?.userId === e.target.value);
                if (selected) {
                  setSelectedTech(selected);
                }
              }}
            >
              {techs.map((tech, index) => {
                return (
                  <MenuItem key={`${index}`} value={tech.userId}>
                    {tech.userName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6.5} lg={7} xl={8}>
          <FormControlLabel
            control={<Checkbox checked={isShowingSelectedTech} />}
            label="Show Only Selected Technician"
            onChange={(_, checked) => setIsShowingSelectedTech(checked)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3.5} lg={3} xl={2}>
          <DateRangeNavigator
            view={isSingleViewMode ? ICalendarView.Day : ICalendarView.Week}
            dateRange={selectedDateRange!}
            onChange={(range: ICalendarDateRange) => {
              if (isSingleViewMode) {
                setSelectedDateRange(range);
              } else {
                setSelectedDateRange({
                  startDate: startOfDay(range?.startDate),
                  endDate: endOfDay(addDays(range?.startDate, 6)),
                });
              }
            }}
            willHandleDateChange
            hasBorder
          />
        </Grid>
      </Grid>
      <OTSVisitCalendar
        allowCreate={allowCreate}
        selectedTech={selectedTech}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        visitsColorMap={visitsColorMap}
        service={service}
        allVisits={scheduledVisits}
        setDeletedVisits={setDeletedVisits}
        deletedVisits={deletedVisits}
        techs={techs}
        isMobile={isSmMobile}
        setIsSingleViewMode={setIsSingleViewMode}
        isSingleViewMode={isSingleViewMode}
        visits={
          isShowingSelectedTech
            ? scheduledVisits
              ?.filter(v => v.assignedTo === selectedTech?.userName)
              ?.map(visit => {
                return {
                  start: visit?.startTime ? new Date(visit?.startTime) : undefined,
                  end: visit?.endTime ? new Date(visit?.endTime) : undefined,
                  isNew: visit?.isNew || false,
                  isMoved: visit?.isMoved || false,
                  visit,
                };
              })
            : scheduledVisits.map(visit => {
              return {
                start: visit?.startTime ? new Date(visit?.startTime) : undefined,
                end: visit?.endTime ? new Date(visit?.endTime) : undefined,
                isNew: visit?.isNew || false,
                isMoved: visit?.isMoved || false,
                visit,
              };
            })
        }
        handleUpdateVisits={data => {
          setScheduledVisits(data);
        }}
      />
    </>
  );
};
