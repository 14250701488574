import { FC, useState, useEffect, useContext, Dispatch, SetStateAction } from 'react';
import { Box, Grid, TextField, Autocomplete } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { SelectAsyncInput, FilterButtons } from '../../../components';
import {
  IAccountSimple,
  IDateRange,
  IListUser,
  ILookupModel,
  IResponse,
  ITaskDefinition,
} from '../../../models';
import {
  getAccountsSimple,
  getTaskDefinitions,
  getTaskPriorityTypes,
  getUsers,
} from '../../../fetch';
import { UserContext } from '../../../context';
import { checkForEnterKey } from '../../../helpers';

interface ITasksFilters {
  selectedAccountId: string;
  setSelectedAccountId: (val: string) => void;
  showPriorityFilter?: boolean;
  selectedDateRange: IDateRange | null;
  setSelectedDateRange: Dispatch<SetStateAction<IDateRange>>;
  hasAppliedFilters: boolean;
  setHasAppliedFilters: (val: boolean) => void;
  applyFilters: (clearFilters?: boolean) => void;
  isLoading: boolean;
  setSelectedTaskDefId: (val: string) => void;
  selectedTaskDefId: string;
  selectedUserId: string;
  setSelectedUserId: (val: string) => void;
  selectedPriority: string;
  setSelectedPriority: (val: string) => void;
  excludeFilters?: string[];
}

export const TasksFilters: FC<ITasksFilters> = ({
  setSelectedAccountId,
  showPriorityFilter = false,
  selectedDateRange,
  setSelectedDateRange,
  hasAppliedFilters,
  setHasAppliedFilters,
  applyFilters,
  isLoading,
  setSelectedTaskDefId,
  selectedTaskDefId,
  selectedUserId,
  setSelectedUserId,
  selectedPriority,
  setSelectedPriority,
  excludeFilters,
  selectedAccountId,
}) => {
  const { user } = useContext(UserContext);
  const [autocompleteValue, setAutocompleteValue] = useState<IAccountSimple | null>(null);
  const [accounts, setAccounts] = useState<IAccountSimple[]>([]);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);

  const fetchAccounts = async () => {
    setIsLoadingAccounts(true);
    try {
      const res = await getAccountsSimple({ perPage: -1 });
      setAccounts(res.records);
      if (selectedAccountId) {
        const selectedAccount = res.records.find(acc => acc.accountId === selectedAccountId);
        if (selectedAccount) {
          setAutocompleteValue(selectedAccount);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  useEffect(() => {
    if (!excludeFilters?.includes('Customer')) {
      fetchAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeFilters]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      onKeyDown={e =>
        checkForEnterKey(e, () => {
          setHasAppliedFilters(true);
          applyFilters();
        })
      }
    >
      {!excludeFilters?.includes('Customer') && (
        <>
          <Grid item xs={12} sm={4}>
            <Box>
              <Autocomplete
                value={autocompleteValue}
                onChange={(event, newValue: any) => {
                  setAutocompleteValue(newValue);
                  setSelectedAccountId(newValue?.accountId);
                }}
                disabled={isLoadingAccounts}
                selectOnFocus
                handleHomeEndKeys
                loading={isLoadingAccounts}
                id="selected-site"
                options={accounts || []}
                getOptionLabel={(option: IAccountSimple) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.accountName;
                }}
                renderOption={(props, option: IAccountSimple) => {
                  return (
                    <li {...props} key={option.accountId}>
                      {option.accountName}
                    </li>
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    placeholder="Select Customer"
                    key={params.id}
                    size="small"
                    autoComplete="on"
                    label="Customer"
                    variant="outlined"
                  />
                )}
              />
            </Box>
          </Grid>
        </>
      )}
      {showPriorityFilter && !excludeFilters?.includes('Priority') && (
        <Grid item xs={12} sm={4}>
          <SelectAsyncInput
            label="Priority"
            name="priority"
            value={selectedPriority}
            handleChange={val => setSelectedPriority?.(val)}
            apiRequest={() => getTaskPriorityTypes()}
            transformResponse={(res: ILookupModel[]) =>
              res.map(r => ({
                label: r.description,
                value: r.value,
              }))
            }
            hasClear={!!selectedPriority}
            onClear={() => {
              setSelectedPriority?.('');
            }}
          />
        </Grid>
      )}
      {!excludeFilters?.includes('Start Date') && (
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            label="Start Date"
            value={selectedDateRange?.startDate}
            onChange={newValue => {
              setSelectedDateRange({
                ...selectedDateRange,
                startDate: newValue as any,
              } as IDateRange);
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                size: 'small',
              },
            }}
          />
        </Grid>
      )}
      {!excludeFilters?.includes('End Date') && (
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            label="End Date"
            value={selectedDateRange?.endDate}
            onChange={newValue => {
              setSelectedDateRange({
                ...selectedDateRange,
                endDate: newValue as any,
              } as IDateRange);
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                size: 'small',
              },
            }}
          />
        </Grid>
      )}
      {!excludeFilters?.includes('Task Type') && (
        <Grid item xs={12} sm={4}>
          <SelectAsyncInput
            label="Task Type"
            name="taskDefId"
            value={selectedTaskDefId}
            handleChange={val => setSelectedTaskDefId?.(val)}
            apiRequest={() => getTaskDefinitions({ perPage: -1 })}
            transformResponse={(res: IResponse<ITaskDefinition[]>) =>
              res.records.map(r => ({
                label: r.description,
                value: r.taskDefinitionId,
              }))
            }
            hasClear={!!selectedTaskDefId}
            onClear={() => {
              setSelectedTaskDefId?.('');
            }}
          />
        </Grid>
      )}
      {!excludeFilters?.includes('User') && (
        <Grid item xs={12} sm={4}>
          <SelectAsyncInput
            name="userId"
            label="User"
            value={selectedUserId}
            handleChange={val => setSelectedUserId?.(val)}
            apiRequest={() =>
              getUsers({ officeId: user?.officeId, perPage: -1, isDisabled: false })
            }
            transformResponse={(response: IResponse<IListUser[]>) => {
              return response.records.map(record => ({
                label: record.userName,
                value: record.userId,
              }));
            }}
            hasClear={!!selectedUserId}
            onClear={() => {
              setSelectedUserId?.('');
            }}
          />
        </Grid>
      )}

      <FilterButtons
        hasAppliedFilters={hasAppliedFilters}
        isDisabled={isLoading}
        handleApplyFilters={() => {
          setHasAppliedFilters(true);
          applyFilters();
        }}
        handleResetFilters={() => {
          setHasAppliedFilters(false);
          setAutocompleteValue(null);
          setSelectedDateRange({
            startDate: null,
            endDate: null,
            key: 'selection',
            inputValue: '',
          });
          setSelectedTaskDefId?.('');
          setSelectedAccountId('');
          setSelectedUserId('');
          setSelectedPriority('');
          applyFilters(true);
        }}
      />
    </Grid>
  );
};
