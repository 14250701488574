import { Box, Divider, Button, useMediaQuery, Theme } from '@mui/material';
import { FC, useState } from 'react';
import { GenerateReport, updateParameters } from '../../../components/report';
import { IReportParameter, IReportParameterUpdate } from '../../../models';
import { SendEmailModal } from '../../../components';
import { sendStatementEmail } from '../../../fetch';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const REPORT_ID = '8d928bb6-f0ac-42b8-8aa6-ac8b6a53abf6';

export interface CustomerStatementTabProps {
  accountCode: string;
  accountId: string;
  accountEmails?: string[];
}

export const CustomerStatementTab: FC<CustomerStatementTabProps> = ({
  accountCode,
  accountId,
  accountEmails,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const onParametersLoad = (parameters: IReportParameter[]) => {
    const parameterUpdates: IReportParameterUpdate = {
      StartAccountCode: {
        defaultValue: accountCode,
        hidden: true,
      },
      OneOnly: {
        defaultValue: 'true',
        hidden: true,
      },
      OrderBy: {
        hidden: true,
      },
      BillingGroupId: {
        hidden: true,
      },
      IncludeZeroBalances: {
        defaultValue: 'true',
        savedValue: 'true',
        hidden: true,
      },
      IncludeCreditBalances: {
        defaultValue: 'true',
        savedValue: 'true',
        hidden: true,
      },
      EmailOnly: {
        hidden: true,
      },
      EmailSubject: {
        hidden: true,
      },
      EmailBody: {
        hidden: true,
      },
      MonthlyMessage: {
        hidden: true,
      },
      OverdueMessage30: {
        hidden: true,
      },
      OverdueMessage60: {
        hidden: true,
      },
      OverdueMessage90: {
        hidden: true,
      },
    };
    return updateParameters(parameters, parameterUpdates);
  };
  const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);

  return (
    <Box marginTop="1rem">
      <GenerateReport
        reportId={REPORT_ID}
        generateImmediately
        generateOnBlur
        onParametersLoad={onParametersLoad}
        afterFormContent={
          <Box mt={2}>
            <Divider sx={{ marginBottom: 2 }} />
            <Button
              type="button"
              color="primary"
              variant="outlined"
              size="small"
              onClick={async () => {
                setEmailModalIsOpen(true);
              }}
              startIcon={<FontAwesomeIcon icon={faEnvelope} />}
              fullWidth={isMobile}
            >
              Send As Email
            </Button>
          </Box>
        }
      />
      <SendEmailModal
        open={emailModalIsOpen}
        onClose={() => setEmailModalIsOpen(false)}
        modalTitle="Send Statement Email"
        onSubmit={async formValues => {
          await sendStatementEmail(accountId, formValues);
        }}
        defaultToAddress={accountEmails}
        showSendAsAgreementToggle={false}
      />
    </Box>
  );
};
