import { Autocomplete, TextField, Box, IconButton } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context';
import { IAccountSimple } from '../../models';
import { getAccountsSimple } from '../../fetch';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { getCustomerDetailRoute } from '../../helpers';

interface IAccountAutocomplete {
  setSelectedAccount: (val: IAccountSimple | null) => void;
  isDisabled?: boolean;
  handleOptions?: (val: IAccountSimple[]) => void;
  accountId?: string;
  selectedAccount: IAccountSimple | null;
  hasError?: boolean;
  helperText?: string;
  handleBlur?: (e: any) => void;
  isRequired?: boolean;
  isFreeSolo?: boolean;
  labelText?: string;
  shouldRefetch?: boolean;
}

const filter = createFilterOptions<IAccountSimple>();

export const AccountAutocomplete: FC<IAccountAutocomplete> = ({
  isDisabled,
  handleOptions,
  hasError,
  helperText,
  selectedAccount,
  setSelectedAccount,
  handleBlur,
  isRequired,
  isFreeSolo,
  labelText,
  shouldRefetch,
}) => {
  const { user } = useContext(UserContext);
  const [isOpen, setOpen] = useState(false);

  const {
    isLoading: isLoadingAccounts,
    isFetching,
    data: accounts,
    refetch,
  } = useQuery(
    ['getAccountsSimple', user],
    async () => {
      const accountRes = await getAccountsSimple({ perPage: -1 });
      return accountRes.records;
    },
    {
      notifyOnChangeProps: 'tracked',
      onSuccess: accounts => {
        handleOptions?.(accounts);
      },
    }
  );

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
  }, [shouldRefetch, refetch]);

  return (
    <Box display="flex" gap={1} alignItems="flex-start">
      <Autocomplete
        fullWidth
        value={selectedAccount}
        disabled={isLoadingAccounts || isDisabled || isFetching}
        onChange={(e, newValue: any) => {
          if (newValue && newValue?.accountName?.length) {
            setSelectedAccount(newValue);
          } else {
            setSelectedAccount(null);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(option => inputValue === option.accountName);
          if (inputValue !== '' && !isExisting && isFreeSolo) {
            filtered.push({
              inputValue,
              accountName: `Create ${inputValue} as a new Customer`,
              accountId: '',
              accountLastName: '',
              accountFirstName: '',
              accountStatus: '',
            });
          }

          return filtered;
        }}
        onBlur={handleBlur}
        options={accounts || []}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          }
          return option.accountName;
        }}
        renderOption={(props: any, option: IAccountSimple) => {
          return (
            <li {...props} key={option.accountId}>
              {option.accountName}
            </li>
          );
        }}
        freeSolo={isFreeSolo}
        open={isOpen}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        selectOnFocus
        clearOnBlur
        renderInput={params => {
          return (
            <TextField
              {...params}
              size="small"
              label={isLoadingAccounts ? 'Loading...' : labelText ?? 'Customer'}
              required={isRequired ?? true}
              fullWidth
              autoComplete="none"
              variant="outlined"
              placeholder={labelText ?? 'Select a customer'}
              error={hasError}
              helperText={helperText}
              name="accountId"
            />
          );
        }}
      />
      {selectedAccount && selectedAccount?.accountId && (
        <IconButton
          color="secondary"
          onClick={() => {
            window.open(`${getCustomerDetailRoute(selectedAccount?.accountId!)}`, '_blank');
          }}
        >
          <FontAwesomeIcon fontSize="16px" icon={faExternalLink} title="Open Customer" />
        </IconButton>
      )}
    </Box>
  );
};
