import { FC, useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { IParameterInputProps, IReportParameter } from '../../models';
import {
  CheckboxGroupParameter,
  CheckboxParameter,
  DateParameter,
  DropdownParameter,
  IntegerParameter,
  MoneyParameter,
  RadioButtonsParameter,
  TextareaParameter,
  TextboxParameter,
} from './parameter-controls';
import { Box, Grid, Button, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faFile, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { CancelIcon } from '../icon';

export interface IReportParameterFormProps {
  parameters: IReportParameter[];
  isSubmitting?: boolean;
  isExporting?: boolean;
  onSubmit: (values: Record<string, string>) => unknown;
  onExport: (values: Record<string, string>) => unknown;
  setStartDate?: Dispatch<SetStateAction<string>>;
  setEndDate?: Dispatch<SetStateAction<string>>;
  submitImmediately?: boolean;
  generateOnBlur?: boolean;
  isForm?: boolean;
  handleClose?: () => void;
  isModal?: boolean;
}

export const ReportParameterForm: FC<IReportParameterFormProps> = ({
  parameters,
  isSubmitting,
  isExporting,
  onSubmit,
  onExport,
  setEndDate,
  setStartDate,
  submitImmediately = false,
  generateOnBlur = false,
  isForm,
  handleClose,
  isModal,
}) => {
  const [values, setValues] = useState<Record<string, string>>({});
  const isMobile = useMediaQuery('(max-width: 1300px)');

  useEffect(() => {
    const initialValues = {} as Record<string, string>;
    parameters.forEach(param => {
      let defaultValue = param.savedValue ?? param.defaultValue;

      if (param.selectListItems.length) {
        switch (param.parameterType) {
          case 'checkboxgroup':
            const selectedItems = param.selectListItems.filter(item => item.selected);
            if (selectedItems.length) {
              defaultValue = selectedItems.map(item => item.value).toString();
            }
            break;
          default:
            const selectedItem = param.selectListItems.find(item => item.selected);
            if (selectedItem && !defaultValue) {
              defaultValue = selectedItem.value;
            }
            break;
        }
      }

      if (!defaultValue && param.selectListItems.length) {
        defaultValue = param.selectListItems[0].value;
      }

      initialValues[param.reportParameterId] = defaultValue;
    });

    setValues(initialValues);

    if (submitImmediately && !isSubmitting) {
      onSubmit?.(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  const handleChange = (value: string, param: IReportParameter) => {
    if (
      setStartDate &&
      param.parameterName === 'DetailDate' &&
      `${new Date(value)?.getFullYear()}`?.length === 4
    ) {
      setStartDate(value);
    }
    if (
      setEndDate &&
      param.parameterName === 'EndDate' &&
      `${new Date(value)?.getFullYear()}`?.length === 4
    ) {
      setEndDate(value);
    }
    setValues({
      ...values,
      [param.reportParameterId]: value,
    });
  };

  const handleSubmit = useCallback(() => {
    onSubmit(values);
  }, [onSubmit, values]);

  const handleExportSubmit = useCallback(() => {
    onExport(values);
  }, [onExport, values]);

  const renderParam = (param: IReportParameter) => {
    const value = values[param.reportParameterId] || '';
    let ParamInput: FC<IParameterInputProps> | undefined;
    switch (param.parameterType) {
      case 'checkbox':
        ParamInput = CheckboxParameter;
        break;
      case 'checkboxgroup':
        ParamInput = CheckboxGroupParameter;
        break;
      case 'radiobuttons':
        ParamInput = RadioButtonsParameter;
        break;
      case 'dropdown':
        ParamInput = DropdownParameter;
        break;
      case 'textarea':
        ParamInput = TextareaParameter;
        break;
      case 'datetime':
      case 'date':
        ParamInput = DateParameter;
        break;
      case 'money':
        ParamInput = MoneyParameter;
        break;
      case 'integer':
        ParamInput = IntegerParameter;
        break;
      case 'textbox':
      case 'string':
        ParamInput = TextboxParameter;
        break;
    }
    if (!ParamInput) {
      return null;
    }
    return (
      <ParamInput
        parameter={param}
        value={value}
        onChange={handleChange}
        onBlur={() => {
          generateOnBlur && !isSubmitting && handleSubmit();
        }}
      />
    );
  };

  const renderChildren = () => {
    return (
      <>
        {parameters?.length > 0 && (
          <Grid container spacing={2}>
            {parameters
              .filter(p => !p.hidden)
              .map(param => (
                <Grid item xs={12} key={param.reportParameterId}>
                  {renderParam(param)}
                </Grid>
              ))}
          </Grid>
        )}
        {parameters?.length > 0 && !generateOnBlur && (
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent={isModal ? 'flex-end' : 'center'}
            gap={1}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            {handleClose && (
              <Button
                type="button"
                color="inherit"
                disabled={isSubmitting}
                onClick={handleClose}
                startIcon={<CancelIcon />}
                fullWidth={isMobile}
              >
                Cancel
              </Button>
            )}
            <Button
              type="button"
              color="secondary"
              disabled={isSubmitting || isExporting}
              onClick={handleSubmit}
              fullWidth={isMobile}
              startIcon={
                isSubmitting ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  <FontAwesomeIcon icon={faFile} />
                )
              }
            >
              {isSubmitting ? 'Generating' : 'Generate'}
            </Button>
            <Button
              type="button"
              color="primary"
              disabled={isExporting || isSubmitting}
              onClick={handleExportSubmit}
              fullWidth={isMobile}
              startIcon={
                isExporting ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  <FontAwesomeIcon icon={faFileExport} />
                )
              }
            >
              {isExporting ? 'Exporting' : 'Export'}
            </Button>
          </Box>
        )}
      </>
    );
  };
  return <>{isForm ? <form>{renderChildren()}</form> : renderChildren()}</>;
};
