import { Stack, Box, Checkbox, FormControlLabel } from '@mui/material';
import { Modal, ModalSaveSection } from '../modal';
import { AmountInput } from '../inputs';
import { FC, useContext, useMemo, useState } from 'react';
import { convertToNumber, formatMoney } from '../../helpers';
import { DisplayGroup } from '../label';
import { useConfirm } from '../../hooks';
import { Loader } from '../loader';
import { refundSecurityDeposit } from '../../fetch';
import { UserContext } from '../../context';
import { useSnackbar } from 'notistack';

interface IRefundModal {
  totalAmount: number;
  lineItemId: string;
  isOpen: boolean;
  onClose: (shouldUpdate?: boolean) => void;
}

export const RefundModal: FC<IRefundModal> = ({ lineItemId, totalAmount, isOpen, onClose }) => {
  const [isRefundFullAmount, setIsRefundFullAmount] = useState<boolean>(true);
  const [partialRefund, setPartialRefund] = useState('');
  const [isFullRefundProcessing, setIsFullRefundProcessing] = useState(false);
  const [isPartialRefundProcessing, setIsPartialRefundProcessing] = useState(false);
  const confirm = useConfirm();
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleFullRefund = async () => {
    const result = await confirm(
      `Are you sure you want to full refund this amount, ${formatMoney(
        Math.abs(convertToNumber(totalAmount))
      )}?`
    );
    if (result) {
      try {
        setIsFullRefundProcessing(true);
        await refundSecurityDeposit(lineItemId!, {
          transactionRefundType: 'Full',
          officeId: user?.officeId!,
        });
        enqueueSnackbar('Refund success!', {
          variant: 'success',
        });
        setPartialRefund('');
        onClose(true);
      } catch (e: any) {
        enqueueSnackbar(e?.Detail ?? `Error on full refund, please try again`, {
          variant: 'error',
        });
      } finally {
        setIsFullRefundProcessing(false);
      }
    }
  };

  const handlePartialAmount = async () => {
    const result = await confirm(
      `Are you sure you want to partial refund this amount, ${formatMoney(
        convertToNumber(partialRefund)
      )}?`
    );

    if (result) {
      try {
        setIsPartialRefundProcessing(true);
        await refundSecurityDeposit(lineItemId!, {
          transactionRefundType: 'Partial',
          officeId: user?.officeId!,
          partialAmountToRefund: convertToNumber(partialRefund),
        });
        enqueueSnackbar('Refund success!', {
          variant: 'success',
        });
        setPartialRefund('');
        onClose(true);
      } catch (e: any) {
        enqueueSnackbar(e?.Detail ?? `Error on partial refund, please try again`, {
          variant: 'error',
        });
      } finally {
        setIsPartialRefundProcessing(false);
      }
    }
  };

  const amountGreaterThanTotal = useMemo(() => {
    return (
      (partialRefund.length && convertToNumber(partialRefund)) >
      Math.abs(convertToNumber(totalAmount))
    );
  }, [partialRefund, totalAmount]);

  const amountLessThanZero = useMemo(() => {
    return partialRefund.length > 0 && convertToNumber(partialRefund) < 1;
  }, [partialRefund]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        setPartialRefund('');
        setIsRefundFullAmount(true);
      }}
      title="Process Refund"
      maxWidth="xs"
    >
      {(isFullRefundProcessing || isPartialRefundProcessing) && (
        <Loader type="overlay" position="centered" />
      )}
      <Stack gap={1} mt={1}>
        <DisplayGroup isInline inlineLabelWidth="3rem" label="Amount">
          {formatMoney(totalAmount)}
        </DisplayGroup>
        <FormControlLabel
          color="primary"
          control={
            <Checkbox
              checked={isRefundFullAmount}
              id="refundFullAmountCheckbox"
              color="primary"
              onChange={(_, checked) => {
                setIsRefundFullAmount(checked);
                setPartialRefund('');
              }}
            />
          }
          label="Refund Full Amount"
        />
        {!isRefundFullAmount && (
          <Box mt={1}>
            <AmountInput
              name="partial-refund"
              value={partialRefund}
              label="Partial Amount to Refund"
              error={amountGreaterThanTotal || amountLessThanZero ? true : false}
              helperText={
                amountGreaterThanTotal
                  ? 'Invalid amount to refund'
                  : amountLessThanZero
                  ? 'Amount needs to be more than 0.'
                  : ''
              }
              onChange={value => {
                setPartialRefund(value);
              }}
            />
          </Box>
        )}
      </Stack>
      <ModalSaveSection
        handleSave={async () => {
          isRefundFullAmount ? await handleFullRefund() : await handlePartialAmount();
        }}
        isSaveDisabled={
          isFullRefundProcessing ||
          isPartialRefundProcessing ||
          (!isRefundFullAmount
            ? !partialRefund ||
              amountGreaterThanTotal ||
              isPartialRefundProcessing ||
              amountLessThanZero
            : false)
        }
        handleCancel={() => {
          onClose();
          setPartialRefund('');
          setIsRefundFullAmount(true);
        }}
      />
    </Modal>
  );
};
