import { deepEqual } from 'fast-equals';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context';
import { postRoutesToOptimize } from '../../fetch';
import { cloneViaSerialization } from '../../helpers';
import {
  IServiceChange,
  IServiceRoute,
  IServiceChangeItem,
  IServiceState,
  IRouteUpdateMode,
  ITechOptimizationEvent,
  ITechnician,
} from '../../models';
import { buildChangeState, buildServicesState } from './utils';

interface UseRouteChangesOptions {
  serviceRoutes: IServiceRoute[];
  updatedRoutes?: IServiceRoute[];
  changes?: Record<string, IServiceChange>;
  updateMode: IRouteUpdateMode;
}

export const useRouteChanges = ({
  serviceRoutes,
  updatedRoutes: initialUpdatedRoutes,
  changes: initialChanges,
  updateMode: initialUpdateMode,
}: UseRouteChangesOptions) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const [updateMode, setUpdateMode] = useState<IRouteUpdateMode>(initialUpdateMode);
  const [updatedRoutes, setUpdatedRoutes] = useState<IServiceRoute[]>(initialUpdatedRoutes || []);
  const [changes, setChanges] = useState<Record<string, IServiceChange>>(initialChanges || {});
  const [initialState, setInitialState] = useState<Record<string, IServiceState>>({});
  const [isOptimizing, setIsOptimizing] = useState(false);
  const [optimizedTech, setOptimizedTech] = useState<ITechnician | undefined>(undefined);
  const hasChanges = !isEmpty(changes);

  const setInitialRoutes = (
    routes: IServiceRoute[],
    updatedRoutes?: IServiceRoute[],
    changes?: Record<string, IServiceChange>
  ) => {
    const clonedRoutes = cloneViaSerialization(routes);
    if (updatedRoutes) {
      setUpdatedRoutes(cloneViaSerialization(updatedRoutes));
    } else {
      setUpdatedRoutes(cloneViaSerialization(routes));
    }

    if (changes) {
      setChanges(changes);
    } else {
      setChanges({});
    }

    setInitialState(buildServicesState(clonedRoutes));
  };

  useEffect(() => {
    setInitialRoutes(serviceRoutes, initialUpdatedRoutes, initialChanges);
  }, [serviceRoutes, initialUpdatedRoutes, initialChanges]);

  const onServicesChange = useCallback(
    (changeSet: IServiceChangeItem[]) => {
      const preChangeRoutes = cloneViaSerialization(updatedRoutes);
      const newChanges = { ...changes };

      changeSet.forEach(change => {
        delete newChanges[change.scheduledServiceId];
        const initialServiceState = initialState[change.scheduledServiceId];
        const changePayload: IServiceChange = {
          service: initialServiceState.service,
          scheduledServiceId: change.scheduledServiceId,
          version: !!change.version ? change.version : null,
          from: {
            userId: change.from.userId,
            serviceDate: change.from.serviceDate,
            index: change.from.serviceIndex,
          },
          to: {
            userId: change.to.userId,
            serviceDate: change.to.serviceDate,
            index: change.to.serviceIndex,
          },
        };

        newChanges[change.scheduledServiceId] = changePayload;

        const preChangeRoute = preChangeRoutes[change.from.routeIndex];
        const preChangeTech = preChangeRoute.technicians[change.from.techIndex];

        const sourceRoute = updatedRoutes[change.from.routeIndex];
        const sourceTech = sourceRoute.technicians[change.from.techIndex];

        const service = preChangeTech.services[change.from.serviceIndex];

        // remove the item from the source location
        sourceTech.services = sourceTech.services.filter(
          s => s.scheduledServiceId !== service?.scheduledServiceId
        );

        // update in an immutable way to trigger react watchers
        sourceTech.services = [...sourceTech.services];
        sourceRoute.technicians = [...sourceRoute.technicians];
      });

      changeSet.forEach(change => {
        const preChangeRoute = preChangeRoutes[change.from.routeIndex];
        const preChangeTech = preChangeRoute.technicians[change.from.techIndex];

        const targetRoute = updatedRoutes[change.to.routeIndex];
        const targetTech = targetRoute.technicians[change.to.techIndex];

        const service = preChangeTech.services[change.from.serviceIndex];

        // add the copied item to the destination location
        targetTech.services.splice(change.to.serviceIndex, 0, service);

        // update in an immutable way to trigger react watchers
        targetTech.services = [...targetTech.services];
        targetRoute.technicians = [...targetRoute.technicians];
      });
      // if the service pod gets put back in its original position than reset changes
      // and the updated routes and initial service routes are the same data
      if (deepEqual(serviceRoutes, updatedRoutes)) {
        setChanges({});
      } else {
        setChanges(newChanges);
      }
      setUpdatedRoutes([...updatedRoutes]);
    },
    [initialState, changes, updatedRoutes, serviceRoutes]
  );

  const reset = () => {
    setInitialRoutes(serviceRoutes, initialUpdatedRoutes, initialChanges);
  };

  const onUpdateModeChange = (newMode: IRouteUpdateMode) => {
    setUpdateMode(newMode);
  };

  const onOptimizeTechRoute = useCallback(
    async (event: ITechOptimizationEvent) => {
      try {
        const { tech, route } = event;

        const targetRoute = updatedRoutes.find(r => r.serviceDate === route.serviceDate);
        if (!targetRoute) {
          return;
        }

        const targetTech = targetRoute.technicians.find(t => t.userId === tech.userId);
        if (!targetTech) {
          return;
        }

        setIsOptimizing(true);
        const optimizationResult = await postRoutesToOptimize({
          userIds: [tech.userId],
          startDate: route.serviceDate,
          endDate: route.serviceDate,
          optimizeMethod: 'Distance',
          officeId: user?.officeId,
        });

        if (!optimizationResult.changes.length) {
          enqueueSnackbar('No optimizations were needed', {
            variant: 'warning',
          });
          return;
        }

        const changes = buildChangeState(optimizationResult.original, optimizationResult.changes);
        setChanges(changes);

        const modifiedServices = optimizationResult.modified[0].technicians[0].services;

        // mark services as optimized
        targetTech.services = modifiedServices;

        setUpdatedRoutes(cloneViaSerialization(updatedRoutes));
        setOptimizedTech({
          ...tech,
          serviceDate: route.serviceDate,
        });
      } finally {
        setIsOptimizing(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.officeId, updatedRoutes]
  );

  return {
    updateMode,
    onUpdateModeChange,
    updatedRoutes,
    onServicesChange,
    changes,
    hasChanges,
    reset,
    setInitialRoutes,
    setUpdateMode,
    isOptimizing,
    onOptimizeTechRoute,
    setChanges,
    optimizedTech,
    setOptimizedTech,
    setUpdatedRoutes,
  };
};
