import { axios } from '../helpers';
import qs from 'querystring';
import {
  IEmailTemplate,
  IDropdownResponse,
  IResponse,
  IEmailTemplateDetailCreate,
  IEmailTemplateDetail,
  IEmailTemplateFollowUpNote,
  IEmailTemplateFollowUpNotePayload,
} from '../models';
import { userSignal } from '../signals';

export const getEmailTemplates = async ({
  ...otherParams
}: {
  officeId?: string | null;
  event?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<IResponse<IEmailTemplate[]>> => {
  try {
    const { data } = await axios.get(`/api/email-templates`, {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getEmailTemplateEventTypes = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/email-templates/event-types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getEmailTemplate = async (
  emailTempalteId: string,
  officeId?: string | null
): Promise<IEmailTemplateDetail> => {
  try {
    const { data } = await axios.get(`/api/email-templates/${emailTempalteId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const createEmailTemplate = async (
  payload: IEmailTemplateDetailCreate,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/email-templates`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const updateEmailTemplate = async (
  emailTemplateId: string,
  payload: IEmailTemplateDetailCreate,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/email-templates/${emailTemplateId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const deleteEmailTemplate = async (emailTemplateId: string): Promise<string> => {
  try {
    const { data } = await axios.delete(`/api/email-templates/${emailTemplateId}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getEmailEventTemplateParams = async ({
  ...otherParams
}: {
  emailEvent?: string;
  officeId?: string | null;
}): Promise<any[]> => {
  try {
    const { data } = await axios.get(`/api/email-templates/email-event-template-params`, {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getEmailTemplateCampaigns = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/email-templates/campaigns`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getEmailTemplateFollowUpNotes = async (
  emailTemplateId: string,
  filters?: {
    officeId?: string | null;
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number | string;
  }
): Promise<IResponse<IEmailTemplateFollowUpNote[]>> => {
  try {
    const { data } = await axios.get(`/api/email-templates/${emailTemplateId}/inserts`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const createEmailTemplateFollowUpNote = async (
  emailTemplateId: string,
  payload: IEmailTemplateFollowUpNotePayload,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/email-templates/${emailTemplateId}/inserts`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const updateEmailTemplateFollowUpNote = async (
  emailTemplateId: string,
  emailTemplateInsertId: string,
  payload: IEmailTemplateFollowUpNotePayload,
  officeId?: string | null
): Promise<void> => {
  try {
    const { data } = await axios.put(
      `/api/email-templates/${emailTemplateId}/inserts/${emailTemplateInsertId}`,
      {
        ...payload,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};
export const deleteEmailTemplateFollowUpNote = async (
  emailTemplateId: string,
  emailTemplateInsertId: string
): Promise<any> => {
  try {
    const { data } = await axios.delete(
      `/api/email-templates/${emailTemplateId}/inserts/${emailTemplateInsertId}`
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
