import { FeatureFlag, Permissions } from '../../constants';
import { ReactElement } from 'react';
import { TaskTypesTable } from './task-types';
import { PaymentSetupsPage } from './payment-setups';
import { SalesTaxesTable } from './sales-taxes';
import { SalesTaxGroupsTable } from './sales-tax-groups';
import { BillingGroupsTable } from './billing-groups';
import { NewsManagementTable } from '../admin/news-management/news-management-table';
import { ReferralTypesTable } from './referral-types';
import { UserDefinedAccountTable } from './user-defined-account';
import { TerminationTypesTable } from './termination-types';
import { AddEditRatePage, RatesTable } from './rates';
import { TranCodesTable } from './tran-codes';
import { TranCodeGroupsTable } from './tran-code-groups';
import { UserDefinedSiteDefsTable } from './user-defined-site-defs';
import { ServiceLocationsList } from './service-locations/service-locations-list';
import { ServiceCodesTable } from './service-codes';
import { TreatmentTypesTable } from './treatment-types';
import { CommissionTypesTable } from './commission-types';
import { AccountClassDefsTable } from './account-class-defs';
import { AccountAlertDefsTable } from './account-alert-defs';
import { TechAvailability } from '../scheduling';
import { TechManagementDetails } from './tech-management/tech-management-details';
import { EmailTemplatesListPageDetails, EmailTemplatesDetailsPage } from './email-templates';
import { getLegacyUrl } from '../../helpers';
import { UserGroupsTable } from './user-mangement/user-groups.table';
import { Paths } from '../../constants';
import { QuickBooksPage } from './quickbooks';
import { CommissionsRulesListDetails } from './commission-rules/commission-rules-list-details';
import { CommissionRulesDetail } from './commission-rules';

const PCP_LEGACY_URL = getLegacyUrl?.();
const { REACT_APP_POOL_360_URL } = require('../../buildSettings.json');

export interface ISetupPageRoute {
  title: string;
  path: string;
  element: ReactElement;
  parent?: string;
  legacyUrl?: string;
  featureFlag?: string[] | string;
  permission?: string;
  permissions?: string[];
  isExternal?: boolean;
}

export const setupPageRoutes: ISetupPageRoute[] = [
  {
    title: 'News Management',
    path: '/setup/news-management',
    element: <NewsManagementTable />,
    permission: Permissions.NewsManagement,
    parent: 'System Setup',
  },
  {
    title: 'Role Permissions',
    path: '/setup/role-permissions',
    element: <UserGroupsTable />,
    legacyUrl: Paths.userManagement.legacyUrl,
    featureFlag: FeatureFlag.v2UserManagement,
    permission: Permissions.Settings,
    parent: 'User Setup',
  },
  {
    title: 'User Management',
    path: `${REACT_APP_POOL_360_URL}/poolservice/users`,
    element: <></>,
    legacyUrl: Paths.userManagement.legacyUrl,
    featureFlag: FeatureFlag.v2UserManagement,
    permission: Permissions.Settings,
    parent: 'User Setup',
    isExternal: true,
  },
  {
    title: 'Task Types',
    path: '/setup/task-types',
    element: <TaskTypesTable />,
    legacyUrl: Paths.taskTypes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounts',
  },
  {
    title: 'Payment Setup',
    path: Paths.paymentSetup.url,
    element: <PaymentSetupsPage />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: 'Sales Taxes',
    path: '/setup/sales-taxes',
    element: <SalesTaxesTable />,
    legacyUrl: Paths.salesTaxes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: 'Sales Tax Groups',
    path: '/setup/sales-tax-groups',
    element: <SalesTaxGroupsTable />,
    legacyUrl: Paths.salesTaxGroups.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: 'Billing Groups',
    path: '/setup/billing-groups',
    element: <BillingGroupsTable />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: 'Referral Types',
    path: '/setup/referral-types',
    element: <ReferralTypesTable />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Sites',
  },
  {
    title: 'Account Attribute Types',
    path: '/setup/user-defined-account',
    element: <UserDefinedAccountTable />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounts',
  },
  {
    title: 'Termination Types',
    path: '/setup/termination-types',
    element: <TerminationTypesTable />,
    legacyUrl: Paths.terminationTypes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounts',
  },
  {
    title: 'Transaction Codes',
    path: '/setup/tran-codes',
    element: <TranCodesTable />,
    legacyUrl: Paths.tranCodes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: 'Rates',
    path: Paths.rates.url,
    element: <RatesTable />,
    legacyUrl: Paths.rates.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: 'Rates',
    path: `${Paths.rates.url}/:rateId`,
    element: <AddEditRatePage />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
  },
  {
    title: 'Transaction Code Groups',
    path: '/setup/tran-code-groups',
    element: <TranCodeGroupsTable />,
    legacyUrl: Paths.tranCodeGroups.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: Paths.quickbooks.label,
    path: Paths.quickbooks.url,
    element: <QuickBooksPage />,
    featureFlag: FeatureFlag.quickbooks,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: 'Site Attribute Types',
    path: '/setup/user-defined-site-defs',
    element: <UserDefinedSiteDefsTable />,
    legacyUrl: Paths.userDefinedSiteDefs.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Sites',
  },
  {
    title: 'Service Locations',
    path: '/setup/service-locations',
    element: <ServiceLocationsList />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Sites',
  },
  {
    title: 'Service Codes',
    path: '/setup/service-codes',
    element: <ServiceCodesTable />,
    legacyUrl: Paths.serviceCodes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Sites',
  },
  {
    title: 'Treatment Types',
    path: '/setup/treatment-types',
    element: <TreatmentTypesTable />,
    legacyUrl: Paths.treatmentTypes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Sites',
  },
  {
    title: 'Commission Types',
    path: '/setup/commission-types',
    element: <CommissionTypesTable />,
    legacyUrl: Paths.commissionTypes.legacyUrl,
    featureFlag: [FeatureFlag.v2Setup, FeatureFlag.v2Commissions],
    permission: Permissions.Settings,
    parent: 'User Setup',
  },
  {
    title: 'Commission Rules',
    path: Paths.commissionRules.url,
    element: <CommissionsRulesListDetails />,
    legacyUrl: Paths.commissionRules.legacyUrl,
    featureFlag: [FeatureFlag.v2Setup, FeatureFlag.v2Commissions],
    permission: Permissions.Settings,
    parent: 'User Setup',
  },
  {
    title: 'Commission Rules',
    path: `${Paths.commissionRules.url}/:commissionRuleId`,
    element: <CommissionRulesDetail />,
    legacyUrl: Paths.commissionRules.legacyUrl,
    featureFlag: [FeatureFlag.v2Setup, FeatureFlag.v2Commissions],
    permission: Permissions.Settings,
  },
  {
    title: 'Account Class Definitions',
    path: '/setup/account-class-defs',
    element: <AccountClassDefsTable />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounts',
  },
  {
    title: 'Account Alert Definitions',
    path: '/setup/account-alert-defs',
    element: <AccountAlertDefsTable />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounts',
  },
  {
    title: Paths.techAvailability.label,
    path: '/setup/tech-availability',
    element: <TechAvailability />,
    featureFlag: [FeatureFlag.technicianManagement],
    permission: Permissions.Settings,
    parent: 'Tech Management',
  },
  {
    title: Paths.techManagement.label,
    path: '/setup/tech-management',
    element: <TechManagementDetails />,
    featureFlag: [FeatureFlag.technicianManagement],
    permission: Permissions.Settings,
    parent: 'Tech Management',
  },
  {
    title: 'Email Templates',
    path: '/setup/email-templates',
    element: <EmailTemplatesListPageDetails />,
    legacyUrl: `${PCP_LEGACY_URL}/Office/Email/Template/Index`,
    permission: Permissions.Settings,
    parent: 'System Setup',
  },
  {
    title: 'Email Templates',
    path: '/setup/email-templates/:emailTemplateId',
    element: <EmailTemplatesDetailsPage />,
    legacyUrl: `${PCP_LEGACY_URL}/Office/Email/Template/Index`,
    permission: Permissions.Settings,
  },
];
