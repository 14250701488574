import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { TextField, Box, Autocomplete } from '@mui/material';
import { FC, useState } from 'react';
import { getNumberRange } from '../../helpers';
import { useFormikContext } from 'formik';

interface ICreditCardInputs {
  numberValue: string;
  cvvValue: string;
  handleCreditCardNumberChange: (val: string) => void;
  handleCvvChange: (val: string) => void;
  resetOnCreditCardNumberChange?: boolean;
  handleBlur: any;
  isInline?: boolean;
  required?: boolean;
}

export const CreditCardInputs: FC<ICreditCardInputs> = ({
  numberValue,
  handleCreditCardNumberChange,
  handleCvvChange,
  handleBlur,
  cvvValue,
  isInline,
  required,
  resetOnCreditCardNumberChange = false,
}) => {
  const { meta, getCardImageProps, getCardNumberProps, getCVCProps } = usePaymentInputs();
  const { setFieldValue, values } = useFormikContext<any>();
  const [expirationMonth, setExpirationMonth] = useState<string>(values.expirationMonth ?? '');
  const [expirationYear, setExpirationYear] = useState<string>(values.expirationYear ?? '');
  const fieldMinWidth = '8rem';
  const renderContent = (isInline?: boolean) => (
    <>
      <Box display="flex" flex={isInline ? { xs: '0 0 100%', lg: 1 } : 1} width={'100%'}>
        <TextField
          label="Credit Card"
          name="creditCardNumber"
          size="small"
          required={required}
          sx={{ width: '100%' }}
          value={numberValue}
          defaultValue={numberValue}
          inputProps={{
            ...getCardNumberProps({
              onChange: (e: any) => {
                handleCreditCardNumberChange(e.target.value);
                // Reset autocomplete values if credit card number changes
                if (resetOnCreditCardNumberChange) {
                  setExpirationMonth('');
                  setExpirationYear('');
                }
              },
              // @ts-ignore
              maxLength: 19, //limit field char length, account for space separators
            }),
          }}
          InputProps={{
            startAdornment: (
              <Box
                component="svg"
                {...getCardImageProps({ images } as any)}
                sx={{ marginRight: '8px', fontSize: '1.5rem' }}
              />
            ),
          }}
          error={meta?.touchedInputs.cardNumber && !!meta?.erroredInputs?.cardNumber}
          helperText={
            meta?.touchedInputs.cardNumber && !!meta?.erroredInputs?.cardNumber
              ? meta?.erroredInputs?.cardNumber
              : ''
          }
        />
      </Box>
      <Autocomplete
        fullWidth={!isInline}
        value={expirationMonth}
        autoSelect
        autoHighlight
        onChange={(_event: any, newValue: string | null) => {
          setExpirationMonth(newValue ?? '');
          setFieldValue('expirationMonth', newValue ?? '');
        }}
        options={
          getNumberRange(1, 12, 1)?.map(month => {
            return month.toString(); // Autocomplete requires string array
          }) || []
        }
        getOptionLabel={(option: string | null) => {
          return option ?? '';
        }}
        renderOption={(props: any, option: string | null) => {
          return (
            <li {...props} key={option}>
              {option}
            </li>
          );
        }}
        sx={
          isInline
            ? { width: { xs: '100%', sm: fieldMinWidth }, flex: { xs: 1, lg: '0 0 auto' } }
            : undefined
        }
        renderInput={params => {
          return (
            <TextField
              {...params}
              size="small"
              label={'Month'}
              required={required}
              fullWidth
              autoComplete="none"
              variant="outlined"
            />
          );
        }}
      />

      <Autocomplete
        fullWidth={!isInline}
        value={expirationYear}
        autoSelect
        autoHighlight
        onChange={(_event: any, newValue: string | null) => {
          setExpirationYear(newValue ?? '');
          setFieldValue('expirationYear', newValue ?? '');
        }}
        options={
          getNumberRange(new Date().getFullYear(), new Date().getFullYear() + 10, 1)?.map(year => {
            return year.toString(); // Autocomplete requires string array
          }) || []
        }
        getOptionLabel={(option: string | null) => {
          return option ?? '';
        }}
        renderOption={(props: any, option: string | null) => {
          return (
            <li {...props} key={option}>
              {option}
            </li>
          );
        }}
        sx={
          isInline
            ? { width: { xs: '100%', sm: fieldMinWidth }, flex: { xs: 1, lg: '0 0 auto' } }
            : undefined
        }
        renderInput={params => {
          return (
            <TextField
              {...params}
              size="small"
              label={'Year'}
              required={required}
              fullWidth
              autoComplete="none"
              variant="outlined"
            />
          );
        }}
      />
      <TextField
        label="CVV"
        name="cvv"
        required={required}
        size="small"
        value={cvvValue}
        fullWidth={!isInline}
        defaultValue={cvvValue}
        sx={
          isInline
            ? { width: { xs: '100%', sm: fieldMinWidth }, flex: { xs: 1, lg: '0 0 auto' } }
            : undefined
        }
        inputProps={{
          ...getCVCProps({
            onBlur: handleBlur,
            onChange: (e: any) => {
              handleCvvChange(e.target.value);
            },
          }),
        }}
        error={!!meta?.touchedInputs?.cvc && !!meta?.erroredInputs?.cvc}
        helperText={
          meta?.touchedInputs?.cvc && meta?.erroredInputs?.cvc ? meta?.erroredInputs?.cvc : ''
        }
      />
    </>
  );
  return isInline ? (
    <Box
      display="flex"
      sx={{
        flexWrap: 'wrap',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
      gap={2}
      alignItems="flex-start"
      data-testid="inline-credit-card-inputs"
    >
      {renderContent(true)}
    </Box>
  ) : (
    renderContent()
  );
};
