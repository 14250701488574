import { TablePagination, TablePaginationProps, styled } from '@mui/material';
import React, { FC } from 'react';

import { TablePaginationActions } from './TablePaginationActions';

interface IPagination
  extends Pick<
    TablePaginationProps,
    'labelRowsPerPage' | 'count' | 'rowsPerPage' | 'page' | 'rowsPerPageOptions'
  > {
  setPage: (newPage: number) => void;
  setRowsPerPage: (val: number) => void;
  component?: React.ElementType<any>;
}

export const Pagination: FC<IPagination> = ({
  labelRowsPerPage,
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  setPage,
  setRowsPerPage,
  component,
}) => {
  return (
    <StyledTablePagination
      labelRowsPerPage="Showing:"
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      slotProps={{
        select: {
          classes: {
            select: classes.select,
          },
        },
      }}
      // @ts-ignore
      component={component}
      rowsPerPageOptions={rowsPerPageOptions ?? [10, 25, 50]}
      classes={{
        root: classes.paginationRoot,
      }}
      onPageChange={(e: unknown, newPage: number) => setPage(newPage)}
      onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setRowsPerPage(parseInt(e.target.value, 10));
      }}
      ActionsComponent={TablePaginationActions}
      data-testid="pagination-wrapper"
    />
  );
};

const PREFIX = 'Pagination';

const classes = {
  select: `${PREFIX}-select`,
  paginationRoot: `${PREFIX}-paginationRoot`,
};

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  [`& .${classes.select}`]: {},

  [`&.${classes.paginationRoot}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    '& p': {
      margin: 0,
    },
    '& .MuiTablePagination-selectRoot': {
      marginLeft: 0,
      marginRight: '5px',
    },
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
    },
    '& .MuiTablePagination-actions': {
      marginLeft: 0,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up('md')]: {
      '& .MuiTablePagination-selectRoot': {
        marginLeft: '8px',
        marginRight: '32px',
      },
      '& .MuiToolbar-gutters': {
        paddingLeft: 0,
      },
      '& .MuiTablePagination-actions': {
        marginLeft: '20px',
      },
    },
  },
}));
