import { Loader, AccountAutocomplete, CreditLimitAlert, AmountInput } from '../../components';
import * as Yup from 'yup';
import { TextField, Grid, Button, Box } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IAccountSimple } from '../../models';
import { createBatchTransaction } from '../../fetch';

interface IBatchTransactionFormData {
  checkNumber: string;
  transactionAmount: string;
}

const PaymentFormSchema = Yup.object().shape({
  checkNumber: Yup.string().required(),
  transactionAmount: Yup.string().required(),
});

interface IPostPaymentsPageDetailsFields {
  transactionBatchId: string;
  loadBatchTransactionTotal: () => void;
  refetchTransactions: () => void;
  isReadOnly?: boolean;
}

export const PostPaymentsPageDetailsFields: FC<IPostPaymentsPageDetailsFields> = ({
  transactionBatchId,
  loadBatchTransactionTotal,
  refetchTransactions,
  isReadOnly,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isPosting, setIsPosting] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<IAccountSimple | null>(null);
  const { handleSubmit, values, isValid, handleChange, resetForm, setFieldValue } = useFormik({
    validationSchema: PaymentFormSchema,
    initialValues: {
      checkNumber: '',
      transactionAmount: '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values: IBatchTransactionFormData) => {
      try {
        setIsPosting(true);
        await createBatchTransaction({
          transactionBatchId: transactionBatchId,
          accountId: selectedAccount!.accountId,
          checkNumber: values.checkNumber,
          amount: +values.transactionAmount,
        });
        loadBatchTransactionTotal();
        refetchTransactions();
        resetForm();
        setSelectedAccount(null);
        enqueueSnackbar('Transaction was successfully posted', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar('An error occurred while creating this transaction', {
          variant: 'error',
        });
      } finally {
        setIsPosting(false);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      {isPosting && <Loader type="overlay" position="centered" title="Loading..." />}
      <Box width="100%" mt={2} mb={selectedAccount?.accountId ? 1 : 0}>
        {selectedAccount?.accountId && (
          <CreditLimitAlert
            accountId={selectedAccount?.accountId}
            shouldRefetch={!!selectedAccount?.accountId}
            isAlwaysShown
            showLoader
          />
        )}
      </Box>
      <Grid container spacing={2} mb={2} mt={selectedAccount?.accountId ? 1 : -2}>
        <Grid item xs={12} sm={4}>
          <AccountAutocomplete
            setSelectedAccount={val => {
              setSelectedAccount(val);
            }}
            isRequired={false}
            selectedAccount={selectedAccount}
            isDisabled={isReadOnly}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            name="checkNumber"
            value={values.checkNumber}
            label="Check #"
            size="small"
            autoComplete="on"
            variant="outlined"
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box display="flex" alignItems="center" gap={1}>
            <AmountInput
              value={values.transactionAmount}
              onChange={(val: string) => {
                setFieldValue('transactionAmount', val);
              }}
              disabled={isReadOnly}
              label="Amount"
              name="transactionAmount"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!selectedAccount || !isValid}
            >
              Post
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
