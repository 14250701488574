import { useDataGrid, GridDataFetcher, Card, CardTitle, Loader } from '../../../components';
import { Box, Button } from '@mui/material';
import { useState, FC, useCallback, useEffect } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { IRepair, IRepairVisit, IOneTimeServiceDetail } from '../../../models';
import { getRepairVisits, getRepairVisit, deleteRepairVisit } from '../../../fetch';
import { useSnackbar } from 'notistack';
import { OTSVisitModal } from './ots-visit-modal';
import clsx from 'clsx';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirm } from '../../../hooks';
import { OTSVisitsDataGrid } from './ots-visits-data-grid';
import { OTSSchedulerModal } from './scheduler';

interface IOTSVisits {
  repairId: string;
  isExpanded?: boolean;
  service?: IOneTimeServiceDetail | null;
  refetchRepairStatus?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<string[], unknown>>;
  hidePagination?: boolean;
  isModal?: boolean;
  isSchedulerOpen?: boolean;
  shouldSchedulerBeDisabled?: boolean;
  saveAndContinue: () => void;
}

export const OTSVisits: FC<IOTSVisits> = ({
  repairId,
  isExpanded,
  service,
  refetchRepairStatus,
  hidePagination = false,
  isModal,
  isSchedulerOpen = false,
  shouldSchedulerBeDisabled = false,
  saveAndContinue,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [currentRepairVisit, setCurrentRepairVisit] = useState<IRepairVisit | null>(null);
  const [isOtsVisitModalOpen, setOtsVisitModal] = useState<boolean>(false);
  const [isOtsSchedulerModalOpen, setOtsSchedulerModal] = useState<boolean>(isSchedulerOpen);
  const [isLoadingVisit, setIsLoadingVisit] = useState<boolean>(false);
  const [isDeletingVisit, setIsDeletingVisit] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Prevent datafetcher from running right away and creating 2 API calls.
    let mounted = true;
    if (repairId && mounted) {
      setIsInitialized(true);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repairId]);

  const dataFetcher: GridDataFetcher<IRepair> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        if (!isInitialized) {
          return {
            continueLoading: true,
          };
        }
        let res: { records: IRepair[]; totalRecordCount: number } = {
          records: [],
          totalRecordCount: 0,
        };
        if (repairId !== 'new') {
          res = await getRepairVisits({
            sortBy: sortColumn || 'ServiceDate',
            sortDirection: sortDirection || 'asc',
            page: page + 1,
            perPage: hidePagination ? -1 : perPage,
            repairId,
          });
        }

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading visits, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isInitialized]
  );

  const {
    rows,
    isLoading: isLoadingVisits,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: refetchOtsVisits,
  } = useDataGrid<IRepair>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'ots-visits',
      sortColumn: 'serviceDate',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const handleDelete = async (repairVisitId: string) => {
    try {
      const result = await confirm('Are you sure you want to delete this visit?');
      if (result) {
        setIsDeletingVisit(true);
        await deleteRepairVisit(repairVisitId!);
        enqueueSnackbar(`Visit Deleted!`, {
          variant: 'success',
        });
        refetchOtsVisits();
        refetchRepairStatus!!();
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting visit, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeletingVisit(false);
    }
  };

  const handleEdit = async (repairVisitId: string) => {
    try {
      setIsLoadingVisit(true);
      const res = await getRepairVisit(repairVisitId!);
      setCurrentRepairVisit(res);
      setOtsVisitModal(true);
    } catch (error) {
    } finally {
      setIsLoadingVisit(false);
    }
  };

  const isLoadingTable = isLoadingVisits || isDeletingVisit;
  return (
    <>
      <Card>
        <CardTitle
          title="Visits"
          mobileWrap
          withExpand
          overrideExpand={isExpanded}
          action={
            !isModal ? (
              <Button
                onClick={() => {
                  if (repairId === 'new') {
                    saveAndContinue();
                  } else {
                    setOtsSchedulerModal(true);
                  }
                }}
                className={clsx('print--none')}
                color="secondary"
                size="small"
                disabled={isLoadingTable || shouldSchedulerBeDisabled}
                startIcon={<FontAwesomeIcon icon={faEye} />}
              >
                Scheduler
              </Button>
            ) : undefined
          }
        >
          {isLoadingVisit && <Loader position="centered" type="overlay" />}
          <Box>
            <OTSVisitsDataGrid
              loading={isLoadingVisits || isDeletingVisit}
              rows={rows}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              refetch={refetchOtsVisits}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              hideFooter={hidePagination}
              isModal={isModal}
            />
          </Box>
        </CardTitle>
      </Card>

      <OTSSchedulerModal
        open={isOtsSchedulerModalOpen}
        service={service}
        onClose={(shouldReload?: boolean) => {
          if (shouldReload) {
            refetchOtsVisits();
            refetchRepairStatus!!();
          }
          setOtsSchedulerModal(false);
        }}
      />
      <OTSVisitModal
        repairId={repairId}
        currentRepairVisit={currentRepairVisit}
        open={isOtsVisitModalOpen}
        onClose={(shouldReload?: boolean) => {
          if (shouldReload) {
            refetchOtsVisits();
            refetchRepairStatus!!();
          }
          setCurrentRepairVisit(null);
          setOtsVisitModal(false);
        }}
      />
    </>
  );
};
