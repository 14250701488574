import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { CommissionsRulesDataGrid } from './commission-rules-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useContext, useState } from 'react';
import { GridDataFetcher, useDataGrid, CardTitle, Card } from '../../../components';
import { getCommissionRules } from '../../../fetch';
import { ICommissionRule } from '../../../models';
import { UserContext } from '../../../context';
import { Paths } from '../../../constants';

export const CommissionsRulesListDetails: FC = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setDeleting] = useState(false);

  const dataFetcher: GridDataFetcher<ICommissionRule> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        const res = await getCommissionRules({
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          page: page + 1,
          perPage,
          officeId: user?.officeId,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading commission rules, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ICommissionRule>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'commission-rules-grid',
      sortColumn: 'serviceType',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  return (
    <Card>
      <CardTitle
        title="Commission Rules"
        marginBottom={0}
        action={
          <Button
            onClick={() =>
              history.push(`${Paths.commissionRules.url}/new?redirect=${Paths.commissionRules.url}`)
            }
            color="secondary"
            size="small"
            disabled={isLoading}
            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
          >
            Add Commission Rule
          </Button>
        }
      />
      <CommissionsRulesDataGrid
        loading={isLoading || isDeleting}
        rows={rows}
        rowCount={recordCount}
        page={page}
        pageSize={perPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        refetch={refetch}
        setDeleting={setDeleting}
      />
    </Card>
  );
};
