import { omit, uniqBy } from 'lodash';
import { makeColor } from '../../helpers';
import {
  IService,
  IServiceChange,
  IServiceChangePayload,
  IServiceRoute,
  IServiceState,
  ITechnician,
  ITechnicianRouteInfo,
} from '../../models';

export const toChangeListPayload = (changes: Record<string, IServiceChange>) => {
  return Object.values(changes).map(change => {
    return {
      ...omit(change, ['service']),
    };
  });
};

export const colorizeTechs = (techs: ITechnician[]): ITechnician[] => {
  const techsWithColor: ITechnician[] = techs.map((t, index) => ({
    ...t,
    color: makeColor(index, techs.length),
  }));
  return techsWithColor;
};

export const colorizeRoute = (route: IServiceRoute): IServiceRoute => {
  return {
    ...route,
    technicians: colorizeTechs(route.technicians),
  };
};

export const colorizeRoutes = (routes: IServiceRoute[]) => {
  return routes.map(colorizeRoute);
};

export const isRouteStart = (service: IService) => service.siteName === 'Route Start';

export const isRouteEnd = (service: IService) => service.siteName === 'Route End';

export const isRouteStartOrEnd = (service: IService) =>
  isRouteStart(service) || isRouteEnd(service);

export const buildFlatTechnicianList = (serviceRoutes: IServiceRoute[]) => {
  return uniqBy(
    serviceRoutes.reduce<any>((prev, curr) => [...prev, ...curr.technicians], []),
    (t: ITechnician) => t.userId
  );
};

export const buildTechnicianInfoList = (serviceRoutes: IServiceRoute[]): ITechnicianRouteInfo[] => {
  const list: ITechnicianRouteInfo[] = [];
  serviceRoutes.forEach((route, routeIndex) => {
    route.technicians.forEach((tech, techIndex) => {
      list.push({
        route,
        routeIndex,
        tech,
        techIndex,
      });
    });
  });
  return list;
};

export const buildServicesState = (serviceRoutes: IServiceRoute[]) => {
  const hashtable: Record<string, IServiceState> = {};
  serviceRoutes.forEach(route => {
    route.technicians.forEach(tech => {
      tech.services.forEach((service, index) => {
        hashtable[service.scheduledServiceId] = {
          scheduledServiceId: service.scheduledServiceId,
          service,
          state: {
            serviceDate: route.serviceDate,
            userId: tech.userId,
            index,
          },
        };
      });
    });
  });
  return hashtable;
};

export const buildChangeState = (
  serviceRoutes: IServiceRoute[],
  changeList: IServiceChangePayload[]
) => {
  const servicesHashtable = buildServicesState(serviceRoutes);
  const changes: Record<string, IServiceChange> = {};
  changeList.forEach(c => {
    const service = servicesHashtable[c.scheduledServiceId].service;
    changes[c.scheduledServiceId] = {
      ...c,
      service,
      version: service.version,
    };
  });
  return changes;
};
