import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Box,
  Grid,
  Stack,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dispatch, FC, SetStateAction } from 'react';
import { Frequency, IScheduledServiceDetail } from '../../models';
import { Daily } from './Daily';
import { isBefore, isPast } from 'date-fns';
import { Monthly, Weekly, Yearly } from './index';
import { formatDate } from '../../helpers';

interface IRepeatPattern {
  setSchedule: React.Dispatch<{
    dailyInterval1: number;
    weeklyInterval1: number;
    weeklyInterval2: number;
    monthlyInterval1: number;
    monthlyInterval2: number;
    monthlyInterval3: number;
    yearlyInterval1: number;
    yearlyInterval2: number;
    yearlyInterval3: number;
    recurOption?: boolean;
    recurPeriod: string;
  }>;
  serviceDetails: IScheduledServiceDetail | null;
  setFieldValue: (key: string, val: any) => void;
  endDate: string | Date;
  recurOption: boolean;
  recurPeriod: string;
  dailyInterval1: number;
  weeklyInterval1: number;
  weeklyInterval2: number;
  monthlyInterval1: number;
  monthlyInterval2: number;
  monthlyInterval3: number;
  yearlyInterval1: number;
  yearlyInterval2: number;
  yearlyInterval3: number;
  initialDate: Date | string;
  setIsInvalidEndDate: Dispatch<
    SetStateAction<{
      reason: string;
      check: boolean;
    }>
  >;
  isInvalidEndDate: {
    reason: string;
    check: boolean;
  };
}

export const RepeatPattern: FC<IRepeatPattern> = ({
  setSchedule,
  setFieldValue,
  endDate,
  recurOption,
  recurPeriod,
  dailyInterval1,
  weeklyInterval1,
  weeklyInterval2,
  monthlyInterval1,
  monthlyInterval2,
  monthlyInterval3,
  yearlyInterval1,
  yearlyInterval2,
  yearlyInterval3,
  initialDate,
  setIsInvalidEndDate,
  isInvalidEndDate,
}) => {
  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <FormControl>
          <FormLabel id="frequency" color="primary">
            Frequency
          </FormLabel>
          <RadioGroup
            aria-labelledby="frequency"
            defaultValue={Frequency.Daily}
            name="radio-buttons-group"
            value={recurPeriod}
            onChange={e => {
              setSchedule({
                recurPeriod: e.target.value as string,
                recurOption,
                dailyInterval1,
                weeklyInterval1,
                weeklyInterval2,
                monthlyInterval1,
                monthlyInterval2,
                monthlyInterval3,
                yearlyInterval1,
                yearlyInterval2,
                yearlyInterval3,
              });
            }}
          >
            <FormControlLabel value={Frequency.Daily} control={<Radio />} label="Daily" />
            <FormControlLabel value={Frequency.Weekly} control={<Radio />} label="Weekly" />
            <FormControlLabel value={Frequency.Monthly} control={<Radio />} label="Monthly" />
            <FormControlLabel value={Frequency.Yearly} control={<Radio />} label="Yearly" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={9}>
        <FormControl sx={{ width: '100%' }}>
          <FormLabel id="options">Options</FormLabel>
          <Stack gap={1}>
            {recurPeriod === Frequency.Daily && (
              <Daily
                setSchedule={data =>
                  setSchedule({
                    recurPeriod: data.recurPeriod,
                    recurOption,
                    dailyInterval1: data.interval1,
                    weeklyInterval1,
                    weeklyInterval2,
                    monthlyInterval1,
                    monthlyInterval2,
                    monthlyInterval3,
                    yearlyInterval1,
                    yearlyInterval2,
                    yearlyInterval3,
                  })
                }
                interval1={dailyInterval1}
              />
            )}
            {recurPeriod === Frequency.Weekly && (
              <Weekly
                setSchedule={data =>
                  setSchedule({
                    recurPeriod: data.recurPeriod,
                    recurOption,
                    dailyInterval1,
                    weeklyInterval1: data.interval1,
                    weeklyInterval2: data.interval2,
                    monthlyInterval1,
                    monthlyInterval2,
                    monthlyInterval3,
                    yearlyInterval1,
                    yearlyInterval2,
                    yearlyInterval3,
                  })
                }
                interval1={weeklyInterval1}
                interval2={weeklyInterval2}
              />
            )}
            {recurPeriod === Frequency.Monthly && (
              <Monthly
                setSchedule={data =>
                  setSchedule({
                    recurPeriod: data.recurPeriod,
                    recurOption: data.recurOption,
                    dailyInterval1,
                    weeklyInterval1,
                    weeklyInterval2,
                    monthlyInterval1: data.interval1,
                    monthlyInterval2: data.interval2,
                    monthlyInterval3: data.interval3,
                    yearlyInterval1,
                    yearlyInterval2,
                    yearlyInterval3,
                  })
                }
                interval1={monthlyInterval1}
                interval2={monthlyInterval2}
                interval3={monthlyInterval3}
                recurOption={recurOption}
              />
            )}
            {recurPeriod === Frequency.Yearly && (
              <Yearly
                setSchedule={data =>
                  setSchedule({
                    recurPeriod: data.recurPeriod,
                    recurOption: data.recurOption,
                    dailyInterval1,
                    weeklyInterval1,
                    weeklyInterval2,
                    monthlyInterval1,
                    monthlyInterval2,
                    monthlyInterval3,
                    yearlyInterval1: data.interval1,
                    yearlyInterval2: data.interval2,
                    yearlyInterval3: data.interval3,
                  })
                }
                interval1={yearlyInterval1}
                interval2={yearlyInterval2}
                interval3={yearlyInterval3}
                recurOption={recurOption}
              />
            )}
            <Box>
              <DatePicker
                label="Repeats Until End Date"
                format="MM/dd/yyyy"
                onChange={(date: any) => {
                  if (date) {
                    setFieldValue('endDate', date);
                    setIsInvalidEndDate({
                      reason: isPast(new Date(date)) ? 'inPast' : 'invalid',
                      check:
                        formatDate(date) === formatDate(initialDate)
                          ? false
                          : isBefore(new Date(date), new Date(initialDate)) ||
                            isPast(new Date(date)),
                    });
                  } else {
                    setFieldValue('endDate', null);
                    setIsInvalidEndDate({
                      reason: '',
                      check: false,
                    });
                  }
                }}
                value={endDate ? new Date(endDate) : null}
                minDate={new Date(initialDate)}
                closeOnSelect
                slotProps={{
                  field: { clearable: true },
                  textField: {
                    size: 'small',
                    required: false,
                    error: isInvalidEndDate.check,
                    helperText:
                      isInvalidEndDate.reason === 'inPast' && isInvalidEndDate.check
                        ? "Date can't be in the past"
                        : isInvalidEndDate.check
                        ? "Date can't be before the initial date"
                        : 'Leave end date blank to auto-extend perpetually',
                  },
                }}
              />
            </Box>
          </Stack>
        </FormControl>
      </Grid>
    </Grid>
  );
};
