import { Autocomplete, Avatar, Chip, TextField, styled } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { ITechnician } from '../../models';

interface ITechnicianFilters {
  technicians: ITechnician[];
  selectedTechs: ITechnician[];
  setSelectedTechs: Dispatch<SetStateAction<ITechnician[]>>;
  isLoading: boolean;
  inputSize?: 'small' | 'medium';
  disabled?: boolean;
}

export const TechnicianFilters: FC<ITechnicianFilters> = ({
  technicians,
  selectedTechs,
  setSelectedTechs,
  isLoading,
  inputSize = 'medium',
  disabled,
}) => {
  const handleDelete = (tech: ITechnician) => {
    setSelectedTechs(prev => prev.filter(t => t.userId !== tech.userId));
  };
  return (
    <Autocomplete
      value={selectedTechs}
      multiple
      disableClearable
      onChange={(event, newValue: ITechnician[]) => {
        if (newValue && newValue.length > 0) {
          setSelectedTechs(newValue);
        } else {
          setSelectedTechs([]);
        }
      }}
      sx={{
        borderRadius: theme => theme.spacing(0.5),
        '&& .MuiOutlinedInput-root': {
          padding: '6px 9px',
        },
      }}
      id="technicians"
      options={technicians.filter(t => !selectedTechs.map(t => t.userId).includes(t.userId))}
      disabled={isLoading || disabled}
      getOptionLabel={(option: ITechnician) => {
        return option.userName;
      }}
      renderOption={(props, option: ITechnician) => {
        return (
          <li {...props} key={option.userId}>
            {option.userName}
          </li>
        );
      }}
      renderTags={tags =>
        tags.map(tag => (
          <StyledChip
            key={tag.userId}
            avatar={<Avatar alt={tag.userName}>{tag.userName.slice(0, 1)}</Avatar>}
            label={tag.userName}
            variant="outlined"
            onDelete={() => {
              handleDelete(tag);
            }}
            disabled={isLoading}
          />
        ))
      }
      renderInput={params => {
        return (
          <TextField
            {...params}
            size={inputSize}
            name="technicians"
            autoComplete="none"
            label={selectedTechs?.length || !!params.inputProps.value ? '' : 'Select Technician'}
            InputLabelProps={{ shrink: false }}
            variant="outlined"
          />
        );
      }}
    />
  );
};

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette.primary.main,
  borderRight: 'none',
  textOverflow: 'ellipsis',
  maxWidth: '175px',
  '&& .MuiChip-deleteIcon': {
    fontSize: '24px',
    marginRight: '-14px',
    color: theme.palette.primary.main,
  },
}));
