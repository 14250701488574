import { FC } from 'react';
import { Box, Divider, Typography, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, IconDefinition, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from '../link';
import { IBreadcrumb } from '../../models';

interface IIconPageHeadingProps {
  icon?: IconDefinition;
  heading: string;
  breadcrumb?: IBreadcrumb;
  isCustomer?: boolean;
  showDivider?: boolean;
  showHome?: boolean;
}

export const IconPageHeading: FC<IIconPageHeadingProps> = ({
  icon,
  heading,
  breadcrumb,
  isCustomer = false,
  showHome = true,
  showDivider = true,
}) => {
  const headingIcon: IconDefinition | undefined =
    isCustomer && !icon ? faUser : icon ? icon : undefined;

  return (
    <IconPageHeadingWrapper>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {heading !== 'Home' && showHome && (
          <Box className={classes.breadcrumb}>
            <Typography variant="h2" title="Home">
              <Link to="/" type="secondary">
                Home
              </Link>
            </Typography>
            <FontAwesomeIcon
              color="grey"
              icon={faChevronRight}
              size="sm"
              className={classes.separator}
            />
          </Box>
        )}
        {breadcrumb ? (
          <>
            <Box className={classes.breadcrumb}>
              <Typography
                variant="h2"
                sx={{ fontWeight: !breadcrumb.link ? 'normal' : undefined }}
                title={breadcrumb.title ? breadcrumb.title : ''}
              >
                {breadcrumb.link ? (
                  <>
                    <Link to={breadcrumb.link} type="secondary">
                      {breadcrumb.text}
                    </Link>
                  </>
                ) : (
                  breadcrumb.text
                )}
              </Typography>
            </Box>
            <FontAwesomeIcon
              color="grey"
              icon={faChevronRight}
              size="sm"
              className={classes.separator}
            />
            <Box className={classes.breadcrumb}>
              {icon && (
                <FontAwesomeIcon color="grey" icon={icon} size="lg" className={classes.icon} />
              )}
              <Typography variant="h2" sx={{ fontWeight: 'normal' }}>
                {heading}
              </Typography>
            </Box>
          </>
        ) : (
          <Box display="flex" alignItems="flex-start" flexWrap="wrap">
            {headingIcon && (
              <FontAwesomeIcon color="grey" icon={headingIcon} size="lg" className={classes.icon} />
            )}
            <Typography variant="h2">{heading}</Typography>
          </Box>
        )}
      </Box>
      {showDivider && <Divider className={classes.divider} data-testid="Divider" />}
    </IconPageHeadingWrapper>
  );
};

const PREFIX = 'IconPageHeading';

const classes = {
  icon: `${PREFIX}-icon`,
  separator: `${PREFIX}-separator`,
  breadcrumb: `${PREFIX}-breadcrumb`,
  divider: `${PREFIX}-divider`
};

const IconPageHeadingWrapper = styled('div')(({ theme }) => ({
  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.separator}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  [`& .${classes.breadcrumb}`]: {
    display: 'flex',
    padding: theme.spacing(0.5, 0, 0.5, 0),
    alignItems: 'center',
  },

  [`& .${classes.divider}`]: {
    borderColor: theme.palette.dividers.grey,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  }
}));