import { Loader, Modal, ModalSaveSection, SimpleDataGrid } from '../../components';
import { GridRenderCellParams, GridCellParams } from '@mui/x-data-grid';
import { FC, useState, useMemo } from 'react';
import { Box, Typography, Divider, styled } from '@mui/material';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
import {
  IEstimateLineItemDetail,
  IRepairVisitLaborRes,
  IRepairVisitLabor,
  IInvoiceLaborCharges,
} from '../../models';
import { getRepariVisitsLabor, getEstimateVariableLabor } from '../../fetch';
import { formatShortFriendlyDateWithTime } from '../../helpers';

interface ILaborHoursModal {
  isOpen: boolean;
  handleClose: () => void;
  isSubmitting: boolean;
  handleSubmit: (laborHours: IInvoiceLaborCharges[]) => any;
  repairId?: string | null;
  estimateId?: string | null;
}

export const LaborHoursModal: FC<ILaborHoursModal> = ({
  isOpen,
  handleClose,
  isSubmitting,
  handleSubmit,
  repairId,
  estimateId,
}) => {
  const [rows, setRows] = useState<IEstimateLineItemDetail[]>([]);
  const [repairVisitLaborHours, setRepairVisitLaborHours] = useState<IRepairVisitLabor[]>([]);

  const totalHours: number = useMemo(() => {
    return rows.reduce((curr, acc) => {
      return curr + (acc?.estimatedLaborDurationActual ?? 0);
    }, 0);
  }, [rows]);

  const repairTechColumns = useMemo(
    () => [
      {
        field: 'userName',
        headerName: 'User',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'serviceStart',
        headerName: 'Start Date',
        filterable: false,
        sortable: false,
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRepairVisitLabor>) => {
          const { row: repair } = params;
          return <>{formatShortFriendlyDateWithTime(repair.serviceStart)}</>;
        },
      },
      {
        field: 'serviceEnd',
        headerName: 'End Date',
        filterable: false,
        sortable: false,
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRepairVisitLabor>) => {
          const { row: repair } = params;
          return <>{formatShortFriendlyDateWithTime(repair.serviceEnd)}</>;
        },
      },
      {
        field: 'hours',
        headerName: 'Hours',
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        type: 'number',
      },
    ],
    []
  );
  const lineItemColumns = useMemo(
    () => [
      {
        field: 'sortOrder',
        headerName: 'Line',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'details',
        headerName: 'Description',
        filterable: false,
        sortable: false,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'estimatedLaborDuration',
        headerName: 'Estimate',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        type: 'number',
      },
      {
        field: 'estimatedLaborDurationActual',
        headerName: 'Actual',
        editable: true,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        type: 'number',
      },
    ],
    []
  );
  const processRowUpdate = (newRow: any) => {
    const updatedRow = { ...newRow };
    setRows(rows.map(row => (row.estimateLineItemId === newRow.estimateLineItemId ? newRow : row)));
    return updatedRow;
  };

  const { isLoading } = useQuery<IRepairVisitLaborRes, Error>(
    ['getRepariVisitsLabor', repairId, isOpen],
    () => getRepariVisitsLabor(repairId!),
    {
      onSuccess: d => {
        setRows(
          d.estimateLineItems.map(estimate => ({
            ...estimate,
            estimatedLaborDurationActual: estimate.estimatedLaborDuration,
          }))
        );
        setRepairVisitLaborHours(d.repairVisitLaborHours);
      },
      enabled: isOpen && !!repairId,
    }
  );
  const { isLoading: isLoadingEstimateLaborItems } = useQuery<IEstimateLineItemDetail[], Error>(
    ['getEstimateVariableLabor', estimateId, isOpen],
    () => getEstimateVariableLabor(estimateId!),
    {
      onSuccess: d => {
        setRows(
          d.map(d => ({
            ...d,
            estimatedLaborDurationActual: d.estimatedLaborDuration,
          }))
        );
      },
      enabled: isOpen && !!estimateId,
    }
  );
  return (
    <StyledModal
      open={isOpen}
      onClose={() => {
        handleClose();
        setRows([]);
        setRepairVisitLaborHours([]);
      }}
      title="Please enter actual labor hours"
      maxWidth="sm"
    >
      {isSubmitting && <Loader position="centered" type="overlay" />}
      <form
        onSubmit={async e => {
          try {
            e.preventDefault();
            e.stopPropagation();
            const hasError = await handleSubmit(
              rows.map(row => ({
                transactionLineItemId: row.estimateLineItemId,
                actualLaborCharges: row.estimatedLaborDurationActual ?? 0,
              }))
            );
            if (!hasError) {
              setRows([]);
              setRepairVisitLaborHours([]);
              handleClose();
            }
          } catch (error) {}
        }}
      >
        {repairVisitLaborHours?.length > 0 && (
          <>
            <Box mt={1} mb={1}>
              <SimpleDataGrid
                loading={isLoading}
                getRowId={row => {
                  return row.repairVisitId;
                }}
                rows={repairVisitLaborHours}
                columns={repairTechColumns}
              />
            </Box>
            <Divider />
          </>
        )}
        <Box
          mt={2}
          sx={{
            '& .editable': {
              backgroundColor: theme => theme.palette.secondary.light,
            },
          }}
        >
          <Typography variant="caption">
            Type in the actual labor hour for each line item in the "Actual" column cell below by
            typing, double clicking or pressing enter
          </Typography>
          <SimpleDataGrid
            loading={isLoading || isLoadingEstimateLaborItems}
            getRowId={row => row.estimateLineItemId}
            rows={[
              ...rows,
              {
                estimateLineItemId: '',
                sortOrder: '',
                details: '',
                estimatedLaborDuration: 'Total:',
                estimatedLaborDurationActual: totalHours,
              },
            ]}
            processRowUpdate={processRowUpdate}
            columns={lineItemColumns}
            isCellEditable={props => props.row.estimatedLaborDuration !== 'Total:'}
            getRowClassName={props =>
              props.row.details === 'Total:' ? classes.totalRow : classes.row
            }
            getCellClassName={(params: GridCellParams<any, any, number>) => {
              if (params.field === 'estimatedLaborDurationActual' && params.isEditable) {
                return 'editable';
              }
              return '';
            }}
          />
        </Box>
        <ModalSaveSection
          submitLabel="Finalize Invoice"
          submitIcon={faFileInvoice}
          isSaveDisabled={isSubmitting}
          handleCancel={() => {
            handleClose();
            setRows([]);
            setRepairVisitLaborHours([]);
          }}
        />
      </form>
    </StyledModal>
  );
};

const PREFIX = 'LaborHoursModal';

const classes = {
  row: `${PREFIX}-row`,
  totalRow: `${PREFIX}-totalRow`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.row}`]: {
    backgroundColor: theme.palette.common.white,

    '&&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },

  [`& .${classes.totalRow}`]: {
    backgroundColor: theme.palette.grey[200],
  },
}));
