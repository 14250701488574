import { FC, useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { useDebounce } from '../../hooks';
import { getAddressAutocomplete } from '../../fetch';
import { IAddressAutocomplete } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { createFilterOptions } from '@mui/material/Autocomplete';

interface IAddressAutocompleteProps {
  handleChange: (val: IAddressAutocomplete | null) => void;
  option: IAddressAutocomplete | null;
  disabled?: boolean;
  required?: boolean;
  handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string | React.ReactNode;
  showInvalidLocationError?: boolean;
  addressType?: 'Account' | 'Site';
  label?: string;
  handleClear?: () => void;
  isFreeSolo?: boolean;
  name?: string;
}

const filter = createFilterOptions<IAddressAutocomplete>();

export const AddressAutocomplete: FC<IAddressAutocompleteProps> = ({
  handleChange,
  option,
  disabled,
  required,
  handleBlur,
  error,
  helperText,
  showInvalidLocationError = false,
  addressType,
  label = 'Address',
  handleClear,
  isFreeSolo,
  name,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [searchValueDebounced, setSearchValueDebounced] = useState('');
  const [value, setValue] = useState<IAddressAutocomplete | null>(option ?? null);
  const [options, setOptions] = useState<IAddressAutocomplete[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');

  const fetchAddresses = async () => {
    setIsLoading(true);
    try {
      const options = await getAddressAutocomplete(searchValueDebounced, token);
      setOptions(options);
      if (options?.length && !isOpen) {
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  // effects
  useEffect(() => {
    if (searchValueDebounced) {
      fetchAddresses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueDebounced]);
  // hooks
  useDebounce(
    () => {
      setSearchValueDebounced(inputValue);
    },
    500,
    [inputValue]
  );
  useEffect(() => {
    setValue(option);
  }, [option]);
  return (
    <Autocomplete
      value={value}
      onInputChange={(e, value, reason) => {
        if (reason === 'clear') {
          setOptions([]);
        }
        if (reason === 'reset') {
          return;
        }
        setInputValue(value);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(option => inputValue === option.street);
        if (inputValue !== '' && !isExisting && isFreeSolo) {
          filtered.push({
            inputValue,
            street: `Use "${inputValue}"`,
            secondary: '',
            city: '',
            state: '',
            zipCode: '',
          });
        }

        return filtered;
      }}
      onChange={(e, newValue: any) => {
        if (typeof newValue === 'string' && isFreeSolo) {
          const newAddress = {
            inputValue,
            street: inputValue,
            secondary: '',
            city: '',
            state: '',
            zipCode: '',
          };
          setValue(newAddress);
          handleChange(newAddress);
        } else if (newValue && newValue?.street?.length) {
          setValue(newValue);
          handleChange(newValue);
        } else {
          setValue(null);
          handleClear && handleClear();
        }
      }}
      id="select-address"
      includeInputInList
      options={options}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option?.inputValue && isFreeSolo) {
          return option.inputValue;
        }
        const street = option.street ? `${option.street.trim()}` : '';
        const secondary = option?.secondary ? ` ${option.secondary} ` : '';
        const city = option?.city ? `, ${option.city.trim()}` : '';
        return `${street}${secondary}${city} ${option?.state} ${option?.zipCode}`.trim() ?? '';
      }}
      renderOption={(props, option: IAddressAutocomplete) => {
        const street = option.street ? `${option.street.trim()}` : '';
        const secondary = option?.secondary ? ` ${option.secondary} ` : '';
        const city = option?.city ? `, ${option.city.trim()}` : '';
        const state = option?.state ? `, ${option.state.trim()}` : '';
        const zipCode = option?.zipCode ? ` ${option.zipCode.trim()}` : '';
        return (
          <li
            {...props}
            key={`${option.street}-${option.secondary}-${option.city}-${option.state}-${option.zipCode}}`}
          >
            {street}
            {secondary}
            {city}
            {state}
            {zipCode}
          </li>
        );
      }}
      open={isOpen}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      freeSolo={isFreeSolo}
      disabled={disabled}
      selectOnFocus
      clearOnBlur
      onBlur={handleBlur}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          required={required}
          size="small"
          name={name ?? 'street'}
          autoComplete="none"
          label={label}
          placeholder="Search for address"
          id="select-address-input"
          error={error || showInvalidLocationError}
          helperText={
            required && error ? (
              'Required'
            ) : disabled ? undefined : showInvalidLocationError ? (
              <>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{ marginRight: '.25rem' }} />
                {addressType} Address Invalid
              </>
            ) : (
              helperText
            )
          }
        />
      )}
    />
  );
};
