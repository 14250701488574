import { FormControl, InputLabel, MenuItem, Select, InputAdornment, styled } from '@mui/material';
import { FC } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFilterInputProps } from '../../../../models';

export const SelectListFilter: FC<IFilterInputProps> = ({
  filter,
  values,
  onChange,
  showClear = true,
  defaultValue,
}) => {

  const { name, label } = filter;
  const [value = ''] = values;

  return (
    <StyledFormControl size="small" fullWidth>
      <InputLabel className={classes.selectLabel}>{label}</InputLabel>
      <Select
        name={name}
        value={value || ''}
        displayEmpty
        disabled={filter?.disabled}
        onChange={e => {
          onChange([e.target.value as string], filter);
        }}
        endAdornment={
          value &&
          showClear && (
            <InputAdornment
              position="end"
              sx={{
                position: 'absolute',
                right: 35,
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                title="Clear"
                onClick={() => onChange([defaultValue ?? ''], filter)}
              />
            </InputAdornment>
          )
        }
      >
        {filter.selectListItems?.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

const PREFIX = 'SelectListFilter';

const classes = {
  marginBottom: `${PREFIX}-marginBottom`,
  content: `${PREFIX}-content`,
  selectLabel: `${PREFIX}-selectLabel`
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.marginBottom}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.content}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.selectLabel}`]: {
    background: 'white',
  }
}));