import { FC } from 'react';
import { TextField, InputAdornment, styled } from '@mui/material';
import { Close, Search } from '@mui/icons-material';

interface ISearchField {
  searchValue: string;
  handleSearch?: (val: string) => void;
  isDisabled?: boolean;
  setSearchValue: (val: string) => void;
  handleSearchIcon?: (val?: boolean) => void;
  handleClearSearch?: () => void;
  placeholder?: string;
  className?: string;
  label?: string;
  shouldAutoFocus?: boolean;
}

export const SearchField: FC<ISearchField> = ({
  placeholder,
  searchValue,
  handleSearch,
  isDisabled,
  setSearchValue,
  handleSearchIcon,
  handleClearSearch,
  className,
  label,
  shouldAutoFocus,
}) => {

  return (
    <TextField
      size="small"
      fullWidth
      label={label}
      variant="outlined"
      placeholder={placeholder ?? 'Search...'}
      name="search"
      value={searchValue}
      disabled={isDisabled}
      className={className}
      autoFocus={shouldAutoFocus}
      inputProps={{
        sx: {
          borderRadius: '6px',
        },
      }}
      InputProps={{
        startAdornment: (
          <StyledInputAdornment
            position="start"
            className={classes.searchIcon}
            onClick={() => {
              if (searchValue.length > 0) {
                if (handleSearchIcon) {
                  handleSearchIcon(true);
                }
                handleSearch && handleSearch(searchValue);
              }
            }}
          >
            <Search />
          </StyledInputAdornment>
        ),
        endAdornment: searchValue ? (
          <InputAdornment
            position="end"
            className={classes.searchIcon}
            data-testid="clear-search-button"
            onClick={() => {
              if (handleClearSearch) {
                handleClearSearch();
              } else {
                setSearchValue('');
                handleSearch && handleSearch('');
              }
            }}
          >
            <Close />
          </InputAdornment>
        ) : null,
      }}
      onKeyDown={e => {
        if (e.key === 'Enter' && searchValue.length > 0) {
          handleSearch && handleSearch(searchValue);
        }
      }}
      onChange={e => {
        setSearchValue(e.target.value);
      }}
    />
  );
};

const PREFIX = 'SearchField';

const classes = {
  searchIcon: `${PREFIX}-searchIcon`
};

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  [`&.${classes.searchIcon}`]: {
    cursor: 'pointer',
    color: theme.palette.grey[500],
  }
}));