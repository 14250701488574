import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
} from '../../../components';
import { GridColDef, getGridDateOperators, GridRenderCellParams } from '@mui/x-data-grid';
import { ISalesTax } from '../../../models';
import { deleteSalesTax, getAllSalesTaxes } from '../../../fetch';
import { faPlusCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AddEditSalesTaxModal } from './add-edit-sales-tax-modal';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { formatDate, formatMoney } from '../../../helpers';
const ALLOWED_DATE_OPERATORS = ['onOrAfter', 'onOrBefore'];

export const dateOperators = getGridDateOperators().filter(op =>
  ALLOWED_DATE_OPERATORS.includes(op.value)
);

export const SalesTaxesTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, showModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [currentSalesTax, setCurrentSalesTax] = useState<ISalesTax | null>(null);
  const confirm = useConfirm();
  const { user } = useContext(UserContext);

  const dataFetcher: GridDataFetcher<ISalesTax> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          officeId: user?.officeId as string,
        };

        const res = await getAllSalesTaxes(options);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading sales taxes, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ISalesTax>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'sales-taxes-grid',
      sortColumn: 'description',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const handleDelete = async (item: ISalesTax) => {
    try {
      const result = await confirm('Are you sure you want to delete this sales tax?');
      if (result) {
        setDeleting(true);
        await deleteSalesTax(item.salesTaxId);
        await refetch();
        enqueueSnackbar(`Sales Tax Deleted!`, {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(`Error deleting sales tax, please try again.`, {
        variant: 'error',
      });
    } finally {
      setDeleting(false);
    }
  };

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'code',
        headerName: 'Code',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'taxRateText',
        headerName: 'Tax Rate',
        sortable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams<ISalesTax>) => {
          if (!!params.row.taxRateText) {
            return <>{formatMoney(params.row.taxRateText)}</>;
          }
          return <></>;
        },
      },
      {
        field: 'effectiveDate',
        headerName: 'Effective Date',
        filterOperators: dateOperators,
        width: 160,
        renderCell: (params: GridRenderCellParams<ISalesTax>) => {
          if (!!params.row.effectiveDate) {
            return <>{formatDate(params.row.effectiveDate)}</>;
          }
          return <></>;
        },
      },
      {
        field: 'expireDate',
        headerName: 'Expire Date',
        filterOperators: dateOperators,
        width: 160,
        renderCell: (params: GridRenderCellParams<ISalesTax>) => {
          if (!!params.row.expireDate) {
            return <>{formatDate(params.row.expireDate)}</>;
          }
          return <></>;
        },
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        renderCell: (params: GridRenderCellParams<ISalesTax>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Sales Tax"
                onClick={() => {
                  showModal(true);
                  setCurrentSalesTax(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              <IconButton
                color="error"
                title="Delete Sales Tax"
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isDeleting]);

  return (
    <>
      <Card>
        <CardTitle
          title="Sales Taxes"
          action={
            <Button
              onClick={() => showModal(true)}
              color="secondary"
              size="small"
              disabled={isLoading}
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
              Add Sales Tax
            </Button>
          }
        />
        <ServerSideDataGrid
          autoHeight
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: ISalesTax) => row.salesTaxId}
          rows={rows}
          columns={columns}
          loading={isLoading || isDeleting}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          hasMobileLayout
          mobileProps={{
            handleEdit: (item: ISalesTax) => {
              showModal(true);
              setCurrentSalesTax(item);
            },
            handleDelete: (item: ISalesTax) => {
              handleDelete(item);
            },
          }}
        />
      </Card>
      <AddEditSalesTaxModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentSalesTax(null);
        }}
        currentSalesTax={currentSalesTax}
        fetchSalesTaxes={() => refetch()}
      />
    </>
  );
};
