import { Box, Button, ButtonGroup, styled } from '@mui/material';
import { Loader } from '../loader';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faRemove } from '@fortawesome/free-solid-svg-icons';

export const UploadedImage = ({
  source,
  isLoading,
  showZoom = true,
}: {
  source: string;
  isLoading?: boolean;
  showZoom?: boolean;
}) => {

  const [zoomScale, setZoomScale] = useState<number>(1);

  return (
    (<ImageWrap>
      {showZoom && (
        <ButtonGroup>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            onClick={() => {
              setZoomScale(zoomScale / 0.8);
            }}
          >
            Zoom In
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<FontAwesomeIcon icon={faRemove} />}
            onClick={() => {
              setZoomScale(zoomScale * 0.8);
            }}
          >
            Zoom Out
          </Button>
        </ButtonGroup>
      )}
      <Box display="flex" mt={2} justifyContent="center" className={classes.imgBox}>
        {isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Loader type="overlay" />
          </Box>
        )}
        {source && (
          <div
            style={
              showZoom
                ? {
                  overflow: 'scroll',
                  boxSizing: 'border-box',
                }
                : undefined
            }
          >
            <img
              src={source}
              alt={'snap'}
              className={classes.img}
              style={{
                transformOrigin: 'left top',
                transform: `scale(${zoomScale}, ${zoomScale})`,
              }}
            />
          </div>
        )}
      </Box>
    </ImageWrap>)
  );
};

const PREFIX = 'UploadedImage';

const classes = {
  imgBox: `${PREFIX}-imgBox`,
  img: `${PREFIX}-img`
};

const ImageWrap = styled('div')(({ theme }) => ({
  [`& .${classes.imgBox}`]: {
    maxWidth: '100%',
    flexDirection: 'column',
  },

  [`& .${classes.img}`]: {
    height: 'inherit',
    maxWidth: 'inherit',
    padding: theme.spacing(1),
  }
}));