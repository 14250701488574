import { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, MenuItem, Grid } from '@mui/material';
import { PhoneTextField, Select, TextField, EditAddressInformation } from '../../components';
import { generateDefaultName } from './customers-detail/CustomersDetailCRUD';
import { IBillingGroup } from '../../models';

interface INewCustomerForm {
  billingGroups: IBillingGroup[];
  isLoadingBillingGroups: boolean;
  isEmailRequired?: boolean;
}

export const NewCustomerForm: FC<INewCustomerForm> = ({
  billingGroups,
  isLoadingBillingGroups,
  isEmailRequired,
}) => {
  const { setFieldValue, values, handleBlur } = useFormikContext<any>();
  const [pushPinLocation, setPushPinLocation] = useState<Microsoft.Maps.Location | null>(null);
  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="firstName"
                  fullWidth
                  size="small"
                  autoComplete="nope"
                  label="First Name"
                  name="newCustomer.firstName"
                  required
                  onBlur={e => {
                    handleBlur(e);
                    if (!values.newCustomer.accountName && values.newCustomer.lastName) {
                      setFieldValue(
                        'newCustomer.accountName',
                        generateDefaultName(values.newCustomer)
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  fullWidth
                  size="small"
                  autoComplete="nope"
                  label="Last Name"
                  name="newCustomer.lastName"
                  required
                  onBlur={e => {
                    handleBlur(e);
                    if (!values.newCustomer.accountName && values.newCustomer.firstName) {
                      setFieldValue(
                        'newCustomer.accountName',
                        generateDefaultName(values.newCustomer)
                      );
                    }
                    if (!values.newCustomer.addressName && values.newCustomer.firstName) {
                      setFieldValue(
                        'newCustomer.addressName',
                        generateDefaultName(values.newCustomer)
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="accountName"
                  fullWidth
                  required
                  size="small"
                  autoComplete="nope"
                  label="Customer"
                  name="newCustomer.accountName"
                  onBlur={e => {
                    handleBlur(e);
                    if (
                      !values.newCustomer.accountName &&
                      (values.newCustomer.firstName || values.newCustomer.lastName)
                    ) {
                      setFieldValue(
                        'newCustomer.accountName',
                        generateDefaultName(values.newCustomer)
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneTextField
                  id="homePhone"
                  autoComplete="nope"
                  required
                  label="Home Phone"
                  name="newCustomer.phone"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="email"
                  fullWidth
                  required={isEmailRequired === undefined ? true : isEmailRequired}
                  size="small"
                  autoComplete="nope"
                  label="Email"
                  name="newCustomer.email"
                />
              </Grid>
              {Array.isArray(billingGroups) && billingGroups.length > 0 && (
                <Grid item xs={12} sm={6}>
                  <Select
                    id="billingGroupId"
                    fullWidth
                    required
                    size="small"
                    autoComplete="nope"
                    label="Billing Group"
                    name="newCustomer.billingGroupId"
                    disabled={isLoadingBillingGroups}
                  >
                    {billingGroups?.map(group => (
                      <MenuItem
                        key={`billing-group-${group.billingGroupId}`}
                        value={group.billingGroupId}
                      >
                        {group.description}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditAddressInformation
              pushPinLocation={pushPinLocation}
              setPushPinLocation={setPushPinLocation}
              isNewCustomer
              handleFormChange={() => {
                if (pushPinLocation) {
                  setFieldValue('newCustomer.longitude', pushPinLocation.longitude);
                  setFieldValue('newCustomer.latitude', pushPinLocation.latitude);
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
