import { Alert, Box, Divider, Grid, Typography, Button, Stack } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from './code-block';
import { handleCopyClick, styleGuideClientName } from './constants';
import { StyleGuidePage } from './style-guide-page';
import { StyleGuidePaths } from '../../constants';

export const StyleGuideUIUXPatterns = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="UI/UX Patterns">
      <Grid container mb={2}>
        <Grid item xs={12} lg={8}>
          <Box mb={1}>
            <Typography>
              During the course of development, there are certain UI/UX patterns that have been
              implemented with both MUI components and custom components that are worth taking note.
            </Typography>
          </Box>
          <Box mb={2}>
            <Alert severity="info">
              NOTE: This is a living style guide and is subject to grow and change. The patterns
              listed on this page will eventually turn into new sections of the Kitchen Sink
              Components documentation. Feel free to add other temporary documentation to this page
              in lieu of future expansion of the Kitchen Sink. Please provide Example buttons, when
              possible.
            </Alert>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Branding
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography>
              Some branding things of note:
              <ul className="my-2">
                <li>
                  <strong>Disabled Buttons</strong>: Buttons should all have the same gray disabled
                  state, no matter what color is selected. This is implemented automatically when
                  using the Button component.
                </li>
                <li className="mt-1">
                  <strong>Button Color</strong>: It is important to keep button coloring in mind. In
                  the world of UI, one color is used for primary actions (i.e. submit buttons, add
                  buttons, etc.) and one for secondary actions (i.e. general links, pagination,
                  etc.). Typically with brand colors, the primary actions are color coded with an
                  accent or secondary color, a color not widely used that easily calls out these
                  primary actions. The secondary actions are typically color coded with the primary
                  brand color.
                </li>
                <li className="mt-1">
                  <strong>Button Style (variant)</strong>: {styleGuideClientName} utilizes 2 button
                  variants:
                  <em>contained</em> and <em>outlined</em>. <em>Contained</em> is the primary button
                  style and will be more commonly used. <em>Outlined</em> buttons will be used in
                  toolbars, such as for tab action toolbars, sidebar buttons, etc. On a detail page,
                  these actions could include Print, Email, Post, Generate Report, etc.
                </li>
                <li className="mt-1">
                  <strong>Button Size</strong>: In most cases, buttons should be default (medium)
                  sized. For Card action buttons, both in the card body and in the card title, they
                  should be small sized.
                </li>
                <li className="mt-1">
                  <strong>Button Icons</strong>: Technically speaking, a button is not required to
                  have an icon, though it is common for buttons to have icons. Typical buttons that
                  have icons include action buttons like Save, Cancel, Delete, View, Print, Email,
                  etc. See the <em>Consistent Icons</em> section for details on common button icons.
                </li>
                <li className="mt-1">
                  <strong>Icon Library</strong>: Unlike other projects, {styleGuideClientName} uses
                  the FontAwesome icon library, instead of the MUI icon library. As such, icon
                  definitions and implementations utilize types and components specific to
                  FontAwesome (i.e. FontAwesomeIcon component)
                </li>
              </ul>
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Consistent Icons
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography mb={1}>
              A discrepency that keeps popping up is the use of inconsistent icons. Generally
              speaking, <em>add</em> icons should use the same <em>plus sign</em> throughout the
              app. The following are icons that should remain consistent throughout the app:
              <ul className="my-2">
                <li>
                  <strong>Add:</strong> faPlusCircle
                </li>
                <li className="mt-1">
                  <strong>Delete:</strong> faTrash
                </li>
                <li className="mt-1">
                  <strong>Save:</strong> faFloppyDisk
                </li>
                <li className="mt-1">
                  <strong>Edit:</strong> faEdit
                </li>
                <li className="mt-1">
                  <strong>View:</strong> faEye
                </li>
                <li className="mt-1">
                  <strong>Download:</strong> faDownload
                </li>
                <li className="mt-1">
                  <strong>Filter:</strong> faFilter
                </li>
                <li className="mt-1">
                  <strong>Arrows:</strong> Chevrons
                </li>
                <li className="mt-1">
                  <strong>Back:</strong> faChevronLeft
                </li>
                <li className="mt-1">
                  <strong>Calendar:</strong> faCalendar
                </li>
                <li className="mt-1">
                  <strong>Print:</strong> faPrint
                </li>
                <li className="mt-1">
                  <strong>Email:</strong> faEnvelope
                </li>
                <li className="mt-1">
                  <strong>Cancel:</strong> faCancel
                </li>
              </ul>
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Action Buttons
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography>
              There are 3 defined action button patterns that are worth pointing out:
              <ul className="my-2">
                <li>
                  <strong>Detail Page:</strong> Delete buttons tend to be placed at the bottom left
                  of the screen, inline with the Cancel and Save Buttons at the bottom right. Add
                  buttons tend to be displayed in the top right of the screen, though aren't common
                  on detail pages. Button styling consists of <em>contained buttons</em> and are
                  placed outside any card elements.
                </li>
                <li className="mt-1">
                  <strong>Lengthy Forms:</strong> When pages with a form create a lot of page
                  height, primary action buttons should be displayed in the Floating Toolbar
                  component.
                </li>
                <li className="mt-1">
                  <strong>Tabbed Layout:</strong> Action buttons on pages that are comprised of tabs
                  (i.e. Customers) should display action buttons as <em>outlined buttons</em> below
                  a <em>secondary</em> colored divider at the bottom of a tab, within the tab card
                  element.
                </li>
              </ul>
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Typography
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography mb={1}>
              This application is generally card based and has limited use of headings. Sections are
              comprised of cards and nested cards, and titles for sections are implemented using
              Card Titles. As such, it is important to define the component used by Card Titles to
              maintain a semantic page.
            </Typography>
            <Typography mb={1}>
              Due to the card nature of the site, the deepest level really only goes down to h3-h4.
              When using the Icon Page Heading, top level card sections should use Card Titles with
              h3 headings, as h2 is utilized in the Icon Page Heading.
            </Typography>
            <Typography>
              With the exception of the h1 (i.e. Page Title) and breadcrumbs, headings typically use
              light font weights (i.e. 500) and are either <em>black</em> or <em>primary</em> color
              in nature.
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Print Styles
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography mb={1}>
              Throughout the app there are various buttons and different types of printing. The ones
              UI/FE needs to worry about are the ones that manually trigger the browser print. On
              pages that have these buttons, print classes, styles, and structures should be used so
              that the page remains printer friendly.
            </Typography>
            <Typography mb={1}>
              A print header has been implemented site-wide via the Header component, along with
              various overflow specific styles to help keep print views intact while allowing for
              more complex scrolling and overflows on desktop.
            </Typography>
            <Alert severity="warning">
              NOTE: Be sure to test print styles to ensure content does not get cut-off between
              pages. Use the print utility class of <em>print--avoid-break</em> to help alleviate
              this issue. A common cause of cutting off content is <em>display: flex</em>. In cases
              where this is the cause, <em>display: block</em> is the only viable solution.
            </Alert>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Cards
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography mb={1}>
              As previously stated, the general page layout is card based. This can manifest as
              large card bands, with a card for each section of a page, or cards in columns (i.e.
              home page).
            </Typography>
            <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
              &lt;Card&gt; <br />
              &lt;/Card&gt;
            </CodeBlock>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Empty States
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography mb={1}>
              Empty states are typically composed of regular <em>body1</em> Typography components.
              These are generally displayed centered within a Box component with a minHeight set as{' '}
              <em>5rem</em>. Wording of empty states should read something like:{' '}
              <em>There [is/are] no [item name] to display.</em>
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Pagination Alignment
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography mb={1}>Pagination alignment should be right aligned, not left.</Typography>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Grid Button Styling
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography mb={1}>
              Grid action buttons should be displayed as text links and not as <em>contained</em>{' '}
              buttons.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Documents Component
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography mb={1}>
              There is a configurable Documents component that renders a grid of documents with Add,
              Download, Edit, and Delete buttons built-in.
            </Typography>
            <Typography mt={0.5} mb={2}>
              <Button
                variant="contained"
                color="primary"
                href="/customers/980357b3-1fb4-48d2-b3cb-0be9db864af5/documents"
                id="document-example"
              >
                Example
              </Button>
            </Typography>
            <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
              &lt;Documents
              <br />
              &nbsp;&nbsp;&nbsp;labelContext="Site"
              <br />
              &nbsp;&nbsp;&nbsp;getApiRequest=&#123;getAccountDocuments&#125;
              <br />
              &nbsp;&nbsp;&nbsp;deleteApiRequest=&#123;deleteAccountDocument&#125;
              <br />
              &nbsp;&nbsp;&nbsp;postApiRequest=&#123;createSiteDocuments&#125;
              <br />
              &nbsp;&nbsp;&nbsp;putApiRequest=&#123;updateAccountDocument&#125;
              <br />
              &nbsp;&nbsp;&nbsp;getFilters=&#123;&#123; siteId: siteId, accountId: accountId
              &#125;&#125;
              <br />
              &nbsp;&nbsp;&nbsp;postFilterId=&#123;siteId&#125;
              <br />
              /&gt;
            </CodeBlock>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Multi-Select
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography>
              Though there are multiple ways to implement a multi-select field, the prominant
              implementation for the application is by using an Autocomplete selector with Chips.
              The Filter By Technicians field is an autocomplete with multiselect.
            </Typography>
            <Typography mt={0.5}></Typography>
            <Stack flexDirection="row" flexWrap="wrap" gap={1} mt={0.5}>
              <Button variant="contained" color="primary" href="/routes" id="multiselect-example">
                Example
              </Button>
              <Button
                variant="contained"
                color="primary"
                href={StyleGuidePaths.autocompleteAsync.url}
                id="multiselect-example-2"
              >
                Autocomplete Async
              </Button>
            </Stack>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Formik MUI Fields
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography mb={1}>
              Outside of a filter implementation, there are select field components for Formik
              implementations. These include, but are not limited to, Select, TextField, Switch,
              Async Autocomplete, etc. These include formik specific attributes and help reduce the
              need to define everything for each field by dynamically setting things based on the
              name prop. When implementing a form with Formik, be sure to use the Formik specific
              component to help keep code clean. These components live in the <em>formik-mui</em>{' '}
              folder of the project.
            </Typography>
            <Alert severity="info">
              NOTE: Some of these components have been documented and can be found in the components
              section of the style guide.
            </Alert>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Async Select and Autocomplete
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography>
              For select/autocomplete implementations, there are select field components labelled
              Async. Async fields help reduce page/component clutter by grouping non-dependent calls
              and loading states for dropdown options within the field itself. For example, if a
              Select has no need to be updated based on the selection from another filter/field, use
              Async Select to bundle up GET calls and loading states.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Image Upload
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography>
              There are multiple instances of image/file uploads, however, the most common
              implementation can be found at the following example.
            </Typography>
            <Typography mt={0.5}>
              <Button
                variant="contained"
                color="primary"
                href="/customers/980357b3-1fb4-48d2-b3cb-0be9db864af5/sites/be991f09-cf63-4dc7-8552-54bea95ea4c1"
                id="image-upload-example"
              >
                Example
              </Button>
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Formik Validation w/ Yup
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
            <Typography mb={1}>
              Form validation should be handled with Formik and Yup, unless there is a specific
              reason why either can't be used (i.e. a simple show/hide of text is all that's needed,
              the form is specific to filtering, etc.).
            </Typography>
            <Typography mb={1}>
              Yup validation definitions can be simple or they can be complex, but will generally
              look something like the following code snippet:
            </Typography>
            <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
              const TestSchema = Yup.object().shape(&#123; <br />
              &nbsp;&nbsp;&nbsp;isActive: Yup.boolean(), <br />
              &nbsp;&nbsp;&nbsp;name: Yup.string().max(255, 'Max 255
              characters').required('Required'), <br />
              &nbsp;&nbsp;&nbsp;nickName: Yup.string().nullable(), <br />
              &nbsp;&nbsp;&nbsp;agingDays:
              Yup.number().min(MIN_AGING_DAYS).max(MAX_AGING_DAYS).required('Required'),
              <br />
              &nbsp;&nbsp;&nbsp;phoneNumber: Yup.string().matches(phoneRegExp, &#123;
              excludeEmptyString: true, message: 'Invalid phone number', &#125;), <br />
              &nbsp;&nbsp;&nbsp;email: Yup.string().email('Invalid Email').required('Required'),
              <br />
              &nbsp;&nbsp;&nbsp;cardName: Yup.mixed().when(['selectedTab', 'creditCardRequired'],
              &#123;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is: (selectedTab: string, creditCardRequired:
              boolean) =&gt; selectedTab === 'creditCard' && creditCardRequired, <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;then: Yup.string().required('Required'), <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;otherwise: Yup.string().notRequired().nullable(),
              <br />
              &nbsp;&nbsp;&nbsp;&#125;),
              <br />
              &#125;);
            </CodeBlock>
            <Typography mt={1}>
              Here are some more specific examples where we use more complex validation using Yup:
            </Typography>
            <Stack flexDirection="row" flexWrap="wrap" gap={1} mt={1.5}>
              <Button
                variant="contained"
                color="primary"
                href="/customer-view/new"
                id="validation-example 1"
              >
                Example 1
              </Button>
              <Button
                variant="contained"
                color="primary"
                href="/estimates/new"
                id="validation-example-2"
              >
                Example 2
              </Button>
              <Button
                variant="contained"
                color="primary"
                href="https://github.com/jquense/yup"
                target="_blank"
                id="yup-docs"
              >
                Yup Docs
              </Button>
            </Stack>
            <Typography mt={1.5}>
              A few reminders when using Yup:
              <ul className="my-2">
                <li>
                  <strong>nullable():</strong> When a value can possibly be submitted as an empty
                  string or null, you should define a field as nullable in the Yup schema
                  definition.
                </li>
                <li className="mt-1">
                  <strong>max():</strong> Keep max character counts in mind when creating a field.
                  Submitting a character count larger than what the database column allows should
                  not be possible. Messaging should also read as "Max 255 characters", depending on
                  the max limit being set.
                </li>
                <li className="mt-1">
                  <strong>mixed():</strong> The most common type of validation for conditional field
                  validation is mixed().
                </li>
                <li className="mt-1">
                  <strong>required():</strong> When defining validation messages, particularly those
                  for required fields, you should keep messaging short and concise (i.e. "Required"
                  not "Test Field is Required").
                </li>
              </ul>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
