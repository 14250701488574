import { faCodeCompare, faClose } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { useQuery } from 'react-query';
import { FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { ConfirmPrompt, Loader } from '../../components';
import { Pod } from './pod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../context';
import { ServiceRoutesContext } from '../../context/service-routes-context';
import {
  IRouteUpdateMode,
  IServiceChange,
  IServiceRoute,
  IServiceChangeItem,
  ITechOptimizationEvent,
  ICalendarView,
  IResponse,
  ITechnician,
  ICalendarDateRange,
  IService,
} from '../../models';
import { DateRangeFilter } from './DateRangeFilter';
import { TechnicianFilters } from './TechnicianFilters';
import { useHistory } from 'react-router-dom';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import {
  createDropResultChangeSet,
  createServiceDraggableId,
  parseServiceDraggableId,
} from './draggableUtils';
import { buildTechnicianInfoList } from './utils';
import { TechPod } from './pod/TechPod';
import {
  isThisWeek,
  endOfDay,
  endOfWeek,
  nextFriday,
  nextMonday,
  nextSunday,
  startOfWeek,
} from 'date-fns';
import { parseCalendarDate } from '../../helpers';
import { useConfirm } from '../../hooks';
import { getTechnicianUsers } from '../../fetch';
import { deepEqual } from 'fast-equals';
import { defaultUnsavedChangesMessage } from '../../constants';
import { UnsortedStopsAlert } from './unsorted-stops-alert';
import { ButtonsToolbar } from './buttons-toolbar';
import { ManualAdjustAlert } from './manual-adjust-alert';
import { useSnackbar } from 'notistack';

interface IServiceRoutesPods {
  updateMode: IRouteUpdateMode;
  isSaving: boolean;
  isLoading: boolean;
  isOptimizing?: boolean;
  serviceRoutes: IServiceRoute[];
  showSave?: boolean;
  showWeekViewSelector?: boolean;
  showUpdateMode?: boolean;
  disableUpdateMode?: boolean;
  hideEditButton?: boolean;
  hasChanges?: boolean;
  changes?: Record<string, IServiceChange>;
  onServicesChange?: (changes: IServiceChangeItem[]) => unknown;
  onUpdateModeChange?: (value: IRouteUpdateMode) => unknown;
  onSave?: () => unknown;
  showToolbar?: boolean;
  onMapClick?: (routeIndex: number) => void;
  toolbarControls?: ReactNode;
  showReset?: boolean;
  onReset?: () => unknown;
  serviceRouteType?: string;
  allowOptimization?: boolean;
  onOptimizationClick?: (event: ITechOptimizationEvent) => unknown;
  singleViewServiceDate?: string;
  setSingleViewServiceDate?: React.Dispatch<React.SetStateAction<string>>;
  onOptimizeSwitch?: () => void;
  allowedTechIds?: string[];
  setOptimizedTech?: React.Dispatch<React.SetStateAction<ITechnician | undefined>>;
  optimizedTech?: ITechnician;
  setUpdatedRoutes?: (data: IServiceRoute[]) => void;
  loadServiceRoutes?: (dateRange: ICalendarDateRange, userId?: string | null) => void;
  initialServiceRoutes?: IServiceRoute[];
  isCompareView?: boolean;
  setCompareView?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ServiceRoutesPods: FC<IServiceRoutesPods> = ({
  isSaving,
  isLoading,
  isOptimizing = false,
  serviceRoutes,
  showSave = true,
  showWeekViewSelector = true,
  showUpdateMode = true,
  hideEditButton = false,
  disableUpdateMode = false,
  hasChanges,
  changes,
  onServicesChange,
  updateMode,
  onUpdateModeChange,
  onSave,
  showToolbar = true,
  onMapClick,
  toolbarControls = null,
  showReset = true,
  onReset,
  serviceRouteType,
  allowOptimization = true,
  onOptimizationClick,
  singleViewServiceDate,
  setSingleViewServiceDate,
  onOptimizeSwitch,
  allowedTechIds,
  setOptimizedTech,
  optimizedTech,
  setUpdatedRoutes,
  loadServiceRoutes,
  initialServiceRoutes,
  isCompareView,
  setCompareView,
}) => {
  const history = useHistory();
  const confirm = useConfirm();
  const [selectedDraggableIds, setSelectedDraggableIds] = useState<string[]>([]);
  const [activeDraggableId, setActiveDraggableId] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const params = new URLSearchParams(window.location.search);
  const paramDate = params.get('date');

  const { isTechUser } = useContext(UserContext);

  const {
    selectedTechs,
    setSelectedTechs,
    selectedDateRange,
    setSelectedDateRange,
    view,
    setView,
    isSingleViewMode,
    setIsSingleViewMode,
  } = useContext(ServiceRoutesContext);

  const { data: usersData } = useQuery<IResponse<any[]>, Error>(['getTechnicianUsers'], () =>
    getTechnicianUsers({ perPage: -1, roles: 'ServiceTech', isDisabled: false })
  );

  const technicians = useMemo(() => usersData?.records ?? [], [usersData]);
  const techInfoList = useMemo(() => buildTechnicianInfoList(serviceRoutes), [serviceRoutes]);

  const filteredTechInfoList = useMemo(() => {
    if (allowedTechIds?.length) {
      const allowedTechInfos = techInfoList.filter(info =>
        allowedTechIds.some(id => id === info.tech.userId)
      );
      if (!selectedTechs.length) {
        return allowedTechInfos;
      }
      return allowedTechInfos.filter(techInfo =>
        selectedTechs.find(t => t.userId === techInfo.tech.userId)
      );
    }
    if (!selectedTechs.length) {
      return techInfoList;
    }
    return techInfoList.filter(techInfo =>
      selectedTechs.find(t => t.userId === techInfo.tech.userId)
    );
  }, [techInfoList, selectedTechs, allowedTechIds]);

  const toggleSelection = (
    e: React.KeyboardEvent,
    podId: string,
    services: IService[],
    tech: ITechnician
  ) => {
    const sortSelectedServices = (newSelectedIds: string[]) => {
      const parsedAndSortedServices = newSelectedIds
        .map(id => parseServiceDraggableId(id))
        .sort((a, b) => a.serviceIndex - b.serviceIndex);
      const newSortedIds = parsedAndSortedServices.map(id => createServiceDraggableId(id));
      setSelectedDraggableIds(newSortedIds);
    };
    // pull out the selected service index and route index from the current selected podId
    const {
      techIndex: selectedTechIndex,
      routeIndex: selectedRouteIndex,
      serviceIndex: selectedServiceIndex,
    } = parseServiceDraggableId(podId);
    // map over all of the selected draggable ids and pull out the service index and route index
    const parsedDragId =
      selectedDraggableIds?.length > 0
        ? selectedDraggableIds?.map(id => parseServiceDraggableId(id))
        : undefined;
    const parseDragRouteTechIndexes = parsedDragId?.map(id => `${id.routeIndex}-${id.techIndex}`);
    // if shift key is pressed and the first selected service is the same as the new selected service
    if (
      e?.nativeEvent?.shiftKey &&
      parseDragRouteTechIndexes?.includes(`${selectedRouteIndex}-${selectedTechIndex}`) &&
      !selectedDraggableIds.includes(podId)
    ) {
      // grab the first selected service index within the tech selected index
      const {
        serviceIndex: firstServiceIndex,
        routeIndex,
        routeId,
        techIndex,
      } = parseServiceDraggableId(
        selectedDraggableIds.filter(
          id => parseServiceDraggableId(id).techIndex === selectedTechIndex
        )?.[0] ?? ''
      );
      const newSelectedIds = [...selectedDraggableIds, podId];
      const isDescending = firstServiceIndex > selectedServiceIndex;
      // loop through all of the in between services from the first selected and last selected service
      for (
        let i = isDescending ? firstServiceIndex - 1 : firstServiceIndex + 1;
        isDescending ? i > selectedServiceIndex : i < selectedServiceIndex;
        isDescending ? i-- : i++
      ) {
        const newId = createServiceDraggableId({
          serviceIndex: i,
          routeIndex,
          serviceId: services[i - 1].scheduledServiceId,
          userId: tech.userId,
          routeId,
          techIndex,
          version: services[i - 1].version,
        });
        newSelectedIds.push(newId);
      }
      sortSelectedServices(newSelectedIds);
    } else {
      if (selectedDraggableIds.includes(podId)) {
        return setSelectedDraggableIds(selectedDraggableIds.filter(val => val !== podId));
      }
      const newSelectedIds = [...selectedDraggableIds, podId];
      sortSelectedServices(newSelectedIds);
    }
  };

  const onDragStart = (props: DragStart) => {
    setIsDragging(true);
    if (selectedDraggableIds.includes(props.draggableId)) {
      setActiveDraggableId(props.draggableId);
    } else {
      setSelectedDraggableIds([]);
    }
  };

  const onDragEnd = (dropResult: DropResult) => {
    setIsDragging(false);
    if (!onServicesChange) {
      return;
    }

    const changeSet = createDropResultChangeSet({
      dropResult,
      selectedDraggableIds,
      serviceRoutes,
      selectedTechs,
    });

    if (!changeSet.length) {
      setActiveDraggableId(null);
      return;
    }

    let techUnavailable: ITechnician | undefined = undefined;
    let techDayOff: ITechnician | undefined = undefined;
    let techNotServiceTech: ITechnician | undefined = undefined;

    changeSet.forEach(change => {
      // check for a tech that doesn't work on the day so we can show the user a prompt warning when dragging a service to this tech
      if (!serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex].isAvailable) {
        techUnavailable = serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex];
      }
      // check for a tech has a day off so we can show the toast warning
      if (serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex].hasDayOff) {
        techDayOff = serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex];
      }
      // check for a tech that isn't a service tech so we can show the user a prompt warning when dragging a service to this tech
      if (!serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex].isServiceTech) {
        techNotServiceTech = serviceRoutes[change.to.routeIndex].technicians[change.to.techIndex];
      }
    });

    const confirmTechChanges = async (message: string) => {
      const result = await confirm(message);
      if (result) {
        setChanges();
      }
    };

    const setChanges = () => {
      onServicesChange(changeSet);
      setActiveDraggableId(null);
      setSelectedDraggableIds([]);
    };

    if (techDayOff) {
      // @ts-ignore
      enqueueSnackbar(`${techDayOff?.userName!} has the day off, cannot assign services.`, {
        variant: 'warning',
      });
    } else if (techUnavailable) {
      confirmTechChanges(
        // @ts-ignore
        `${techUnavailable?.userName!} does not work on this day. Continue with assignment?`
      );
    } else if (techNotServiceTech) {
      confirmTechChanges(
        // @ts-ignore
        `${techNotServiceTech?.userName!} is not a service tech. Continue with assignment?`
      );
    } else {
      setChanges();
    }
  };

  const confirmChangesLoss = async () => {
    return await confirm(defaultUnsavedChangesMessage);
  };

  const handleWeekViewChange = useCallback(
    async (newView: ICalendarView) => {
      if (!selectedDateRange?.startDate || !setView) {
        return;
      }
      if (hasChanges) {
        const result = await confirmChangesLoss();
        if (!result) {
          return;
        }
      }
      switch (newView) {
        case ICalendarView.DateRange:
          setIsSingleViewMode(false);
          if (updateMode !== 'Single') {
            onUpdateModeChange?.('Single');
          }

          break;
        case ICalendarView.Week:
          setIsSingleViewMode(false);
          const weekStart = nextMonday(startOfWeek(selectedDateRange.startDate));
          const weekEnd = endOfDay(nextSunday(weekStart));
          setSelectedDateRange({
            startDate: weekStart,
            endDate: weekEnd,
          });
          break;
        case ICalendarView.WorkWeek:
          setIsSingleViewMode(false);
          const workWeekStart = nextMonday(startOfWeek(selectedDateRange.startDate));
          const workWeekEnd = endOfDay(nextFriday(workWeekStart));
          setSelectedDateRange({
            startDate: workWeekStart,
            endDate: workWeekEnd,
          });
          break;
      }
      setView(newView);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDateRange, hasChanges]
  );

  const handleCloseSingleViewMode = useCallback(() => {
    setIsSingleViewMode(false);
    const dateSelected = paramDate
      ? parseCalendarDate(paramDate)
      : parseCalendarDate(singleViewServiceDate as string);
    history.push('/routes');

    switch (view) {
      case ICalendarView.DateRange:
      case ICalendarView.WorkWeek:
        const workWeekStart = startOfWeek(dateSelected, { weekStartsOn: 1 });
        setSelectedDateRange({
          startDate: workWeekStart,
          endDate: endOfWeek(workWeekStart, { weekStartsOn: 6 }),
        });
        break;
      case ICalendarView.Week:
        setIsSingleViewMode(false);
        const weekStart = startOfWeek(dateSelected);
        setSelectedDateRange({
          startDate: weekStart,
          endDate: endOfWeek(weekStart),
        });
        break;
    }
    if (setSingleViewServiceDate) setSingleViewServiceDate('');
  }, [
    setIsSingleViewMode,
    singleViewServiceDate,
    setSingleViewServiceDate,
    view,
    setSelectedDateRange,
    history,
    paramDate,
  ]);

  const hasUnsortedItems = useMemo(
    () =>
      filteredTechInfoList.some(techInfo =>
        techInfo.tech.services.some(service => !service.isSorted)
      ),
    [filteredTechInfoList]
  );
  // show the confirm prompt if the update mode is recurring and the date range is not this week
  const showConfirmPrompt = useMemo(() => {
    return updateMode === 'Recurring' && !isThisWeek(selectedDateRange?.startDate!);
  }, [updateMode, selectedDateRange]);

  return (
    <>
      {isSaving && <Loader position="centered" type="overlay" title="Saving..." />}
      <ConfirmPrompt when={hasChanges} message={defaultUnsavedChangesMessage} />
      <Grid container spacing={1} alignItems="center">
        {isOptimizing && <Loader position="centered" type="overlay" title="Optimizing..." />}
        <ManualAdjustAlert />
        {showToolbar && (
          <ButtonsToolbar
            showWeekViewSelector={showWeekViewSelector}
            isSingleViewMode={isSingleViewMode}
            view={view}
            handleWeekViewChange={handleWeekViewChange}
            showUpdateMode={showUpdateMode}
            updateMode={updateMode}
            onUpdateModeChange={onUpdateModeChange}
            disableUpdateMode={disableUpdateMode}
            isTechUser={isTechUser}
            showReset={showReset}
            isSaving={isSaving}
            hasChanges={hasChanges}
            isLoading={isLoading}
            onReset={onReset}
            showSave={showSave}
            onSave={onSave}
            toolbarControls={toolbarControls}
            showConfirmPrompt={showConfirmPrompt}
          />
        )}
        {!isTechUser && (
          <Grid item xs={12} md={4}>
            <div>
              <Typography>Filter by Technician</Typography>
              <TechnicianFilters
                technicians={technicians}
                selectedTechs={selectedTechs}
                setSelectedTechs={setSelectedTechs}
                isLoading={isLoading}
              />
            </div>
          </Grid>
        )}
        {serviceRouteType !== 'optimize' && (
          <>
            <Grid item xs={12} md={4}>
              <div>
                <Typography>Filter by Date </Typography>
                <DateRangeFilter
                  selectedDateRange={selectedDateRange}
                  setSelectedDateRange={setSelectedDateRange}
                  singleViewServiceDate={singleViewServiceDate}
                  hasChanges={hasChanges}
                  view={view}
                  setView={setView}
                  isSingleViewMode={isSingleViewMode}
                  setIsSingleViewMode={setIsSingleViewMode}
                  isBorderless
                  isRoutesDateRange={!isSingleViewMode}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box mt={3}>
                {isSingleViewMode ? (
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={handleCloseSingleViewMode}
                    startIcon={<FontAwesomeIcon icon={faClose} size="lg" />}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                  >
                    Close Day View
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color={isCompareView ? 'inherit' : 'primary'}
                    startIcon={
                      <FontAwesomeIcon icon={isCompareView ? faClose : faCodeCompare} size="lg" />
                    }
                    onClick={async () => {
                      setCompareView && setCompareView(!isCompareView);
                      if (isCompareView) {
                        const hasDiffDates = !deepEqual(initialServiceRoutes, serviceRoutes);
                        if (hasChanges) {
                          const result = await confirmChangesLoss();
                          if (result) {
                            return loadServiceRoutes?.(selectedDateRange!);
                          }
                        } else if (hasDiffDates) {
                          return loadServiceRoutes?.(selectedDateRange!);
                        } else {
                          return loadServiceRoutes?.(selectedDateRange!);
                        }
                      }
                    }}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                  >
                    {isCompareView ? 'Close Compare Mode' : 'Compare'}
                  </Button>
                )}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      {isLoading && <Loader type="overlay" position="centered" />}
      {hasUnsortedItems && <UnsortedStopsAlert onOptimizeSwitch={onOptimizeSwitch} />}
      <Box mt={2}>
        <DragDropContext
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onBeforeCapture={() => setIsDragging(true)}
        >
          <PodContainer isDragging={isDragging}>
            {!isSingleViewMode &&
              serviceRoutes?.map((route, routeIndex) => {
                const routeId = `route-${route.routeId}-${route.serviceDate.toString()}`;
                return (
                  <PodCardWrapper key={routeId}>
                    <Pod
                      updateMode={updateMode}
                      changes={changes}
                      onMapClick={onMapClick}
                      routeIndex={routeIndex}
                      route={route}
                      saving={isSaving}
                      toggleSelection={toggleSelection}
                      selectedDraggableIds={selectedDraggableIds}
                      hideEditButton={hideEditButton}
                      showAllTechsButton={true}
                      activeDraggableId={activeDraggableId}
                      selectedTechs={selectedTechs}
                      allowOptimization={allowOptimization}
                      onOptimizationClick={onOptimizationClick}
                      setSingleViewServiceDate={setSingleViewServiceDate}
                      isSingleViewMode={serviceRouteType === 'optimize' || isSingleViewMode}
                      setOptimizedTech={setOptimizedTech}
                      optimizedTech={optimizedTech}
                      isCompareView={isCompareView}
                      serviceRoutes={serviceRoutes}
                      setUpdatedRoutes={setUpdatedRoutes}
                      hasChanges={hasChanges}
                      showServiceIndex
                      serviceIndexStyle="inline"
                      view={view}
                      serviceDate={route.serviceDate}
                    />
                  </PodCardWrapper>
                );
              })}
            {isSingleViewMode &&
              filteredTechInfoList.map(techInfo => {
                const { route, routeIndex, tech, techIndex } = techInfo;
                const routeId = `route-${route.routeId}-${route.serviceDate.toString()}-tech-${
                  tech.userId
                }`;
                return (
                  <PodCardWrapper key={routeId}>
                    <TechPod
                      tech={tech}
                      techIndex={techIndex}
                      route={route}
                      updateMode={updateMode}
                      changes={changes}
                      onMapClick={onMapClick}
                      routeIndex={routeIndex}
                      saving={isSaving}
                      toggleSelection={toggleSelection}
                      selectedDraggableIds={selectedDraggableIds}
                      hideEditButton={hideEditButton}
                      activeDraggableId={activeDraggableId}
                      allowOptimization={allowOptimization}
                      onOptimizationClick={onOptimizationClick}
                      showServiceIndex
                      serviceIndexStyle="inline"
                      setUpdatedRoutes={setUpdatedRoutes}
                      view={view}
                      serviceDate={route.serviceDate}
                    />
                  </PodCardWrapper>
                );
              })}
          </PodContainer>
        </DragDropContext>
      </Box>
    </>
  );
};

const PodContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'isDragging',
})<{ isDragging?: boolean }>(({ theme, isDragging }) => ({
  display: 'flex',
  gap: '8px',
  flexWrap: 'nowrap',
  overflow: isDragging ? 'auto' : 'hidden',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const PodCardWrapper = styled(Box)(({ theme }) => ({
  flex: '1',
  minWidth: '175px',
}));
