import { FC, useMemo } from 'react';
import {
  ICalendarView,
  IRouteUpdateMode,
  IService,
  IServiceChange,
  IServiceRoute,
  ITechOptimizationEvent,
  ITechnician,
} from '../../../models';
import { createServiceDraggableId } from '../draggableUtils';
import { isRouteStartOrEnd } from '../utils';
import { SortableItem } from './SortableItem';
import { Draggable } from 'react-beautiful-dnd';

interface IPodServiceAccordionDetails {
  route: IServiceRoute;
  tech: ITechnician;
  service: IService;
  routeIndex: number;
  techIndex: number;
  serviceIndex: number;
  toggleSelection?: (
    e: React.KeyboardEvent,
    podId: string,
    route: IService[],
    tech: ITechnician
  ) => void;
  selectedDraggableIds?: string[];
  activeDraggableId?: string | null;
  updateMode?: IRouteUpdateMode;
  changes?: Record<string, IServiceChange>;
  readonly?: boolean;
  allowMultiDrag?: boolean;
  showServiceIndex?: boolean;
  serviceIndexStyle?: 'avatar' | 'inline';
  onOptimizationClick?: (event: ITechOptimizationEvent) => unknown;
  colorizeSiteIndex?: boolean;
  serviceDate: string;
  isCondensed: boolean;
  isShowingServiceIndex: boolean;
  view: ICalendarView;
  handleSiteChange: (id: string | number) => void;
  podReadOnlyInCompareMode: boolean;
  saving: boolean;
  allowOptimization: boolean;
}

export const PodServiceAccordionDetails: FC<IPodServiceAccordionDetails> = ({
  route,
  tech,
  service,
  routeIndex,
  techIndex,
  serviceIndex,
  updateMode,
  isCondensed,
  readonly,
  isShowingServiceIndex,
  changes,
  view,
  serviceDate,
  serviceIndexStyle,
  handleSiteChange,
  podReadOnlyInCompareMode,
  toggleSelection,
  activeDraggableId,
  colorizeSiteIndex,
  allowMultiDrag,
  saving,
  selectedDraggableIds,
  allowOptimization,
  onOptimizationClick,
}) => {
  const serviceDraggableId = useMemo(
    () =>
      createServiceDraggableId({
        routeId: route.routeId,
        userId: tech.userId,
        serviceId: service.scheduledServiceId,
        routeIndex,
        techIndex,
        serviceIndex,
        version: service?.version,
      }),
    [
      route.routeId,
      tech.userId,
      service.scheduledServiceId,
      routeIndex,
      techIndex,
      serviceIndex,
      service?.version,
    ]
  );

  const isStartOrEnd = isRouteStartOrEnd(service);

  const isLocked = useMemo(() => {
    return (
      (updateMode === 'Single' && service.isSingleDayModeLocked) ||
      (updateMode === 'All' && service.isAllDayModeLocked)
    );
  }, [updateMode, service.isSingleDayModeLocked, service.isAllDayModeLocked]);

  const hasChanged = useMemo(
    () => !!changes?.[service.scheduledServiceId],
    [changes, service.scheduledServiceId]
  );

  if (isStartOrEnd) {
    return (
      <SortableItem
        key={serviceDraggableId}
        isLocked
        service={service}
        condense={isCondensed}
        readonly={readonly}
        showIndex={isShowingServiceIndex}
        indexStyle={serviceIndexStyle}
        index={serviceIndex}
        techColor={tech.color}
        updateMode={updateMode}
        view={view}
        serviceDate={serviceDate}
      />
    );
  }

  return (
    <Draggable
      key={serviceDraggableId}
      draggableId={serviceDraggableId}
      index={serviceIndex}
      isDragDisabled={
        readonly || isStartOrEnd || isLocked || service.isClosed || podReadOnlyInCompareMode
      }
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <SortableItem
            isLocked={isLocked}
            hasChanged={hasChanged}
            service={service}
            handleSiteChange={() => {
              handleSiteChange(route.routeId);
            }}
            draggableId={serviceDraggableId}
            activeDraggableId={activeDraggableId}
            isDragging={snapshot.isDragging}
            handleSelection={(e, val: string) =>
              toggleSelection &&
              toggleSelection(
                e,
                val,
                tech.services.filter(s => !isRouteStartOrEnd(s)),
                tech
              )
            }
            condense={isCondensed}
            selectedDraggableIds={selectedDraggableIds}
            saving={saving}
            readonly={readonly || podReadOnlyInCompareMode}
            allowMultiDrag={allowMultiDrag}
            showIndex={isShowingServiceIndex}
            indexStyle={serviceIndexStyle}
            index={serviceIndex}
            techColor={tech.color}
            allowOptimization={allowOptimization}
            onOptimizationClick={() => {
              if (allowOptimization) {
                onOptimizationClick?.({ tech, route });
              }
            }}
            updateMode={updateMode}
            colorizeSiteIndex={colorizeSiteIndex}
            view={view}
            serviceDate={serviceDate}
          />
        </div>
      )}
    </Draggable>
  );
};
