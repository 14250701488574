import { axios } from '../helpers';
import qs from 'querystring';
import {
  ICreateRecurringService,
  IResponse,
  IRecurringService,
  IRecurringServiceDetail,
  IUpdateRecurringService,
  ICreateRecurringServiceRes,
} from '../models';
import { userSignal } from '../signals';

export const getRecurringServices = async (filters?: {
  initialDate?: string;
  endDate?: string;
  sortBy?: string;
  sortDirection?: string;
  siteId?: string;
  page?: number;
  perPage?: number;
  officeId?: string;
  serviceDefId?: string;
  inactive?: boolean;
  accountId?: string;
  onlyIncludeActiveServices?: boolean;
  onlyIncludeServicesWithNoRecurringPayment?: boolean;
}): Promise<IResponse<IRecurringService[]>> => {
  try {
    const { data } = await axios.get(`/api/recurring-services`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRecurringService = async (
  id: string | number,
  officeId?: string | null
): Promise<IRecurringServiceDetail> => {
  try {
    const { data } = await axios.get(`/api/recurring-services/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateRecurringServices = async (
  id: number | string,
  payload: IUpdateRecurringService,
  officeId?: string | null
): Promise<void> => {
  try {
    const { data } = await axios.put(`/api/recurring-services/${id}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createRecurringService = async (
  payload: ICreateRecurringService,
  officeId?: string | null
): Promise<ICreateRecurringServiceRes> => {
  try {
    const { data } = await axios.post(`/api/recurring-services`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteRecurringService = async (
  recurringServiceId: string | number | undefined
): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/recurring-services/${recurringServiceId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkForServiceExceptions = async (payload: { recurringServiceIds: string[] }) => {
  try {
    const { data } = await axios.post('/api/recurring-services/any-manual-routes', payload);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
