import { axios } from '../helpers';
import qs from 'querystring';
import {
  IResponse,
  IOffice,
  IGetFiltersResponse,
  IOfficeDetail,
  IPaymentProcessorModel,
  IUpdateOffice,
  IAccountingProviderSettingsAccount,
  IAccountingProviderSettings,
} from '../models';
import { userSignal } from '../signals';

export const getOffices = async (filters?: {
  page?: number;
  perPage?: number | string;
  sortBy?: string;
  sortDirection?: string;
  inactive?: boolean | string;
  code?: string;
  name?: string;
  officeId?: string | null;
}): Promise<IResponse<IOffice[]>> => {
  try {
    const { data } = await axios.get('/api/offices', {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getOfficesFilters = async (officeId?: string | null) => {
  const { data } = await axios.get<IGetFiltersResponse>('/api/offices/filters', {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: params => qs.stringify(params),
  });
  const { filters } = data;
  return filters;
};

export const getOffice = async (
  id: string | number,
  officeId?: string | null
): Promise<IOfficeDetail> => {
  try {
    const { data } = await axios.get(`/api/offices/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateOffice = async (
  id: string | number,
  update: IUpdateOffice,
  officeId?: string | null
) => {
  try {
    const { data } = await axios.put(`/api/offices/${id}`, {
      ...update,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getTimeZone = async (officeId: string | number): Promise<string> => {
  try {
    const { data } = await axios.get(`/api/offices/${officeId}/time-zone`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getOfficePaymentProcessor = async (
  id: string | number | null,
  token?: string | null
): Promise<IPaymentProcessorModel> => {
  try {
    const officeId = !!id ? id : userSignal.value?.officeId;
    const { data } = await axios.get(`/api/offices/${officeId}/payment-processor`, {
      headers: !!token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateOfficeLogo = async (
  id: string | number,
  payload: FormData,
  officeId?: string | null
) => {
  try {
    const { data } = await axios.post(`/api/store-branding/${id}/logo`, payload, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAccountingProvider = async (officeId: string): Promise<string> => {
  try {
    const { data } = await axios.get(`/api/offices/${officeId}/accounting-provider`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAccountingProviderConnectUrl = async (officeId: string): Promise<string> => {
  try {
    const { data } = await axios.get(`/api/offices/${officeId}/accounting-provider/url`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const storeAccountingProviderTokens = async (
  officeId: string,
  payload: { code: string | null; companyId: string | null }
): Promise<any> => {
  try {
    const { data } = await axios.post(
      `/api/offices/${officeId}/accounting-provider/tokens`,
      payload
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAccountingProviderAccounts = async (officeId: string): Promise<any> => {
  try {
    const { data } = await axios.get(`/api/offices/${officeId}/accounting-provider/accounts`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAccountingProviderSettingsAccounts = async (
  officeId: string,
  filters?: {
    page?: number;
    perPage?: number | string;
    sortBy?: string;
    sortDirection?: string;
    accountType?: string;
  }
): Promise<IResponse<IAccountingProviderSettingsAccount[]>> => {
  try {
    const { data } = await axios.get(
      `/api/offices/${officeId}/accounting-provider/provider-accounts`,
      {
        params: filters,
        paramsSerializer: params => qs.stringify(params),
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAccountingProviderSettings = async (
  officeId: string
): Promise<IAccountingProviderSettings> => {
  try {
    const { data } = await axios.get(`/api/offices/${officeId}/accounting-provider/settings`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const storeAccountingProviderSettings = async (
  officeId: string,
  payload: IAccountingProviderSettings
): Promise<void> => {
  try {
    await axios.post(`/api/offices/${officeId}/accounting-provider/settings`, payload);
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const disconnectAccountingProvider = async (officeId: string): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/offices/${officeId}/accounting-provider/disconnect`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const checkAccountingProviderDuplicates = async (officeId: string): Promise<boolean> => {
  try {
    const { data } = await axios.get(`/api/offices/${officeId}/accounting-provider/has-duplicate`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

// Promotes or removes a temporary QuickBooks connection
export const temporaryProviderChange = async (
  officeId: string,
  temporaryQuickBooksTokenId: string,
  payload: { isPermanent: boolean }
): Promise<string> => {
  try {
    const { data } = await axios.put(
      `/api/offices/${officeId}/accounting-provider/temporary/${temporaryQuickBooksTokenId}`,
      payload
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
