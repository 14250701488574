import { Close, DateRange as DateRangeIcon } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Popover, Button, styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { format, isValid, toDate as formatDate, addDays, differenceInDays } from 'date-fns';
import { FC, useRef, useState, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import { IDateRange } from '../../models';
import '../../styles/react-date-range-theme.css'; // theme css file
import { useSnackbar } from 'notistack';

export interface DateRangePickerProps {
  value: IDateRange | null;
  onChange: (value: IDateRange) => void;
  id: string;
  className?: string;
  placeholder?: string;
  inputSize?: 'medium' | 'small';
  fullWidth?: boolean;
  showClear?: boolean;
  isBorderless?: boolean;
  isSmall?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disabledDay?: (date: Date) => void;
  isStatic?: boolean;
  customOnChange?: (date: Date) => void;
  hasMaxandMin?: boolean;
}

interface DateRangePickerStyles {
  inputSize?: 'medium' | 'small';
  isBorderless?: boolean;
  isSmall?: boolean;
  fullWidth?: boolean;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  value,
  onChange,
  id,
  placeholder,
  inputSize,
  className,
  fullWidth,
  showClear = true,
  isBorderless,
  minDate,
  maxDate,
  disabledDay,
  isStatic,
  customOnChange,
  hasMaxandMin,
  isSmall,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [displayCalendar, setDisplayCalendar] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(value?.inputValue ?? '');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(value?.startDate ?? null);
  const [toDate, setToDate] = useState<Date | null>(value?.endDate ?? null);
  const dateRangeRef = useRef(null);

  // if the parent value changes update state
  useEffect(() => {
    setInputValue(value?.inputValue ?? '');
    setFromDate(value?.startDate ?? null);
    setToDate(value?.endDate ?? null);
  }, [value]);

  const onAdornmentClick = (e: any) => {
    setDisplayCalendar(true);
    setAnchorEl(dateRangeRef.current);
  };
  const onClearClick = (e: any) => {
    e.stopPropagation();
    setDisplayCalendar(false);
    setAnchorEl(null);
    setInputValue('');
    setFromDate(null);
    setToDate(null);
    onChange({ startDate: null, endDate: null, key: 'selection', inputValue: '' }); // callback
  };
  const onInputChange = (e: any) => {
    const inputValue = e.target.value;
    const { fromDate, toDate } = processInputValue(inputValue);
    setInputValue(inputValue);
    setFromDate(fromDate);
    setToDate(toDate);
  };
  const onPopperClose = () => {
    setDisplayCalendar(false);
    setAnchorEl(null);

    if (
      value &&
      (value?.startDate?.getDate() !== fromDate?.getDate() ||
        value.endDate?.getDate() !== toDate?.getDate())
    ) {
      if (isStatic && customOnChange) {
        return customOnChange(value?.startDate!);
      }
      onChange({ startDate: fromDate, endDate: toDate, key: 'selection', inputValue }); // callback
    }
  };
  const onSelectDateRanges = ({ selection }: any) => {
    let { startDate, endDate } = selection;
    if (isStatic && customOnChange) {
      // close the popper
      setDisplayCalendar(false);
      setAnchorEl(null);
      return customOnChange(startDate);
    }
    if (differenceInDays(startDate, endDate) > 9) {
      return enqueueSnackbar('Date range has a max of 10 days', {
        variant: 'warning',
      });
    }
    startDate = isValid(startDate) ? formatDate(startDate) : undefined;
    endDate = isValid(endDate) ? formatDate(endDate) : undefined;

    let inputValue = '';
    if (startDate) inputValue += format(startDate, 'M/d/yyyy');
    if (endDate) inputValue += ' - ' + format(endDate, 'M/d/yyyy');

    setFromDate(startDate);
    setToDate(endDate);
    setInputValue(inputValue);
  };
  const processInputValue = (value: any) => {
    let [fromDate, toDate] = value.split('-').map((elm: any) => elm.trim());

    fromDate = format(fromDate, 'M/dd/yyyy');
    fromDate = fromDate.isValid() ? formatDate(fromDate) : undefined;

    toDate = format(toDate, 'M/dd/yyyy');
    toDate = toDate.isValid() ? formatDate(toDate) : undefined;

    return { fromDate, toDate };
  };

  const clearButton =
    fromDate || toDate ? (
      <IconButton onClick={onClearClick} size="small">
        <Close />
      </IconButton>
    ) : (
      <></>
    );
  return (
    <DateRangeWrapper
      className={clsx(className)}
      ref={dateRangeRef}
      inputSize={inputSize}
      isBorderless={isBorderless}
      isSmall={isSmall}
      fullWidth={fullWidth}
      data-testid="DateRange"
    >
      <TextField
        fullWidth
        disabled
        placeholder={placeholder}
        size={inputSize}
        id={id}
        value={inputValue}
        onChange={onInputChange}
        onClick={onAdornmentClick}
        className={classes.pickerInput}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {showClear && clearButton}
              <IconButton onClick={onAdornmentClick} size="small">
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Popover
        id={`${id}-popover`}
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={onPopperClose}
        className={clsx(!fromDate && !toDate ? 'isEmpty' : '')}
      >
        <Box>
          <StyledDateRange
            ranges={[
              {
                startDate: fromDate,
                endDate: toDate,
                key: 'selection',
              },
            ]}
            onChange={onSelectDateRanges}
            editableDateInputs={false}
            value={value}
            showMonthAndYearPickers={true}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            staticRanges={undefined}
            inputRanges={undefined}
            minDate={hasMaxandMin && fromDate ? addDays(fromDate!, -9) : minDate}
            maxDate={hasMaxandMin && fromDate ? addDays(fromDate!, 9) : maxDate}
            disabledDay={disabledDay}
          />
        </Box>
        {!isStatic && (
          <Box
            sx={{
              padding: theme => theme.spacing(0, 1.5, 1.5, 1.5),
            }}
            display="flex"
            gap={1}
            justifyContent="flex-end"
          >
            <Button
              onClick={() => {
                setInputValue('');
                setFromDate(null);
                setToDate(null);
              }}
              color="inherit"
            >
              Clear
            </Button>
            <Button onClick={onPopperClose} color="secondary">
              Apply
            </Button>
          </Box>
        )}
      </Popover>
    </DateRangeWrapper>
  );
};

const PREFIX = 'DateRangePicker';

const classes = {
  fullWidth: `${PREFIX}-fullWidth`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  pickerInput: `${PREFIX}-pickerInput`,
  dateRange: `${PREFIX}-dateRange`,
};

const StyledDateRange = styled(DateRange)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    fontSize: `${theme.spacing(2)} !important`,
  },
}));

const DateRangeWrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'fullWidth' &&
    prop !== 'inputSize' &&
    prop !== 'isBorderless' &&
    prop !== 'isSmall' &&
    prop !== 'fullWidth',
})<DateRangePickerStyles>(({ theme, isSmall, isBorderless, inputSize, fullWidth }) => ({
  width: fullWidth ? '100%' : 'auto',

  [`& .${classes.pickerInput}`]: {
    '& .MuiInputBase-input': {
      color: theme.palette.common.black,
      paddingTop: inputSize === 'small' ? '8.5px' : undefined,
      paddingBottom: inputSize === 'small' ? '8.5px' : undefined,
      '-webkit-text-fill-color': theme.palette.common.black, // Display input as normal, not disabled
    },
    '& input': {
      padding: isSmall ? '8px 14px' : isBorderless ? '14.5px 14px 12px' : '16.5px 14px',
    },
    '& fieldset': {
      borderWidth: isBorderless ? 0 : '1px',
    },
  },
}));
