import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
  Button,
  ButtonProps,
  styled
} from '@mui/material';
import { Link, ExternalLink } from '../link';
import { useState, FC, forwardRef } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

interface IMenuOption {
  icon: any;
  link: string | null;
  text: string;
  target?: string;
  onClick?: () => unknown;
}

interface IMenuProps {
  buttonText: string;
  options: IMenuOption[];
  color?: 'primary' | 'secondary';
  disableMargin?: boolean;
  isDisabled?: boolean;
  startIcon?: IconProp;
  buttonProps?: ButtonProps;
}

export const Menu: FC<IMenuProps> = ({
  buttonText,
  options,
  color = 'primary',
  disableMargin = false,
  isDisabled,
  startIcon,
  buttonProps,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const CustomLink = forwardRef<any, any>((props, ref) => {
    return <div ref={ref}>{props.target ? <ExternalLink {...props} /> : <Link {...props} />}</div>;
  });

  return (
    <MenuWrapper className={classes.wrapper} disableMargin={disableMargin}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color={color}
        disabled={isDisabled}
        size="small"
        data-testid="menu-button"
        startIcon={startIcon ? <FontAwesomeIcon icon={startIcon} /> : undefined}
        endIcon={<FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />}
        className={clsx('menuButton', buttonProps?.className)}
        {...buttonProps}
      >
        {buttonText}
      </Button>
      <MuiMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem
              key={`${index}`}
              className={classes.menuItem}
              to={option.link}
              target={option.target ?? undefined}
              component={option.link ? CustomLink : 'div'}
              onClick={option.onClick}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={option.icon} />
              </ListItemIcon>
              <ListItemText>{option.text}</ListItemText>
            </MenuItem>
          );
        })}
      </MuiMenu>
    </MenuWrapper>
  );
};

const PREFIX = 'Menu';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  menuItem: `${PREFIX}-menuItem`
};

const MenuWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disableMargin'
})<{ disableMargin: boolean; }>(({ theme, disableMargin }) => ({
  [`&.${classes.wrapper}`]: {
    margin: !disableMargin ? theme.spacing(2, 0) : undefined,
  },

  [`& .${classes.menuItem}`]: { color: theme.palette.primary.dark }
}));