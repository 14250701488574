import { Button } from '@mui/material';
import { Card, CardTitle } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useState } from 'react';
import { ISimpleDateRange, IAccountDetail } from '../../../models';
import { faFilter, faFilterCircleXmark, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { SearchParamsContext } from '../../../context';
import { TasksListDetails } from './task-list-details';

export interface ITasksProps {
  dateRange?: ISimpleDateRange;
  cardTitle: string;
  showFilters?: boolean;
  accountId?: string;
  currentCustomer?: IAccountDetail | null;
  redirect: string;
  isEditable?: boolean;
  excludeFilters?: string[];
  defaultUserFilter?: string;
  withExpand?: boolean;
  initialExpand?: boolean;
  overrideExpand?: boolean;
  addTaskButtonText?: string;
  scheduledServiceId?: string;
  siteId?: string;
  hasQueryParamFiltering?: boolean;
}

export const Tasks: FC<ITasksProps> = ({
  dateRange,
  cardTitle,
  showFilters = false,
  accountId,
  currentCustomer,
  redirect,
  isEditable = true,
  excludeFilters,
  defaultUserFilter,
  withExpand,
  initialExpand = true,
  overrideExpand,
  addTaskButtonText,
  scheduledServiceId,
  siteId,
  hasQueryParamFiltering = true,
}) => {
  const history = useHistory();
  const { queryParams, hasSearchParamValues } = useContext(SearchParamsContext);

  const [isShowingFilters, setIsShowingFilters] = useState(
    hasQueryParamFiltering && hasSearchParamValues ? true : false
  );

  const handleAddTaskRouting = () => {
    // If tasks is on customer page and adding a new task
    if (currentCustomer) {
      return history.push(
        `/scheduling/tasks/new${`?accountId=${
          currentCustomer?.accountId
        }&redirect=${encodeURIComponent(`${redirect}${queryParams ? `?${queryParams}` : ''}`)}`}`
      );
    }
    // If on scheduled service page and adding a new task
    if (scheduledServiceId) {
      return history.push(
        `/scheduling/tasks/new${
          redirect ? `?redirect=${redirect}` : ''
        }&scheduledServiceId=${scheduledServiceId}
        ${accountId ? `&accountId=${accountId}` : ''}${siteId ? `&siteId=${siteId}` : ''}`
      );
    }
    return history.push(
      `/scheduling/tasks/new?redirect=${encodeURIComponent(
        `${redirect}${queryParams ? `?${queryParams}` : ''}`
      )}`
    );
  };

  return (
    <Card>
      <CardTitle
        title={cardTitle}
        mobileWrap
        action={
          <>
            {showFilters && (
              <Button
                onClick={() => setIsShowingFilters(!isShowingFilters)}
                className="print--none"
                size="small"
                color="secondary"
                startIcon={
                  <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                }
              >
                Filters
              </Button>
            )}
            <Button
              onClick={() => handleAddTaskRouting()}
              color="secondary"
              size="small"
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
              {addTaskButtonText ?? 'Add Task'}
            </Button>
          </>
        }
        withExpand={withExpand}
        initialExpand={initialExpand}
        overrideExpand={overrideExpand}
        marginBottom={0}
        unmountOnExit
      >
        <TasksListDetails
          redirect={redirect}
          dateRange={dateRange}
          accountId={accountId}
          currentCustomer={currentCustomer}
          isEditable={isEditable}
          excludeFilters={excludeFilters}
          defaultUserFilter={defaultUserFilter}
          scheduledServiceId={scheduledServiceId}
          hasQueryParamFiltering={hasQueryParamFiltering}
          isShowingFilters={isShowingFilters}
          setIsShowingFilters={setIsShowingFilters}
        />
      </CardTitle>
    </Card>
  );
};
