import { FC, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Modal, Loader, CancelIcon, AmountInput } from '../../../components';
import {
  Box,
  Fade,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  InputAdornment,
  Button,
  styled,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { convertPercentToNumber, convertToNumber, getNumberRange } from '../../../helpers';
import { getBillingGroups, getLateFeeOptions, getAllTranCodes, postLateFees } from '../../../fetch';
import { IBillingGroup, ILateFeeOption } from '../../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

interface FormValues {
  officeId: string;
  agingDate: Date;
  agingDays: number;
  minBalance: string;
  percentage: string;
  amount: string;
  tranCodeId: string;
  reference: string;
  option: string;
  billingGroupId: string;
}

const DEFAULT_VALUES = {
  officeId: '',
  agingDate: new Date(),
  agingDays: 1,
  minBalance: '$0.01',
  percentage: '1',
  amount: '$0.01',
  tranCodeId: '',
  reference: 'Late Fees',
  option: '',
  billingGroupId: '',
} as FormValues;

interface ILateFeesModal {
  open: boolean;
  onClose: () => void;
  billingGroupId?: string | null;
}

const MIN_AGING_DAYS = 1;
const MAX_AGING_DAYS = 180;

const numberValidation = () => {
  return yup
    .string()
    .test('min', 'Must be more than 0', val => (val && convertToNumber(val) > 0 ? true : false))
    .required('Required');
};

const LateFeesSchema = yup.object().shape({
  agingDate: yup.string().typeError('Must be a valid date').required().label('Minimum date'),
  agingDays: yup.number().min(MIN_AGING_DAYS).max(MAX_AGING_DAYS).required(),
  minBalance: numberValidation(),
  percentage: numberValidation(),
  amount: numberValidation(),
  reference: yup.string().required().label('Description'),
  option: yup.string().required(),
  billingGroupId: yup.string().required(),
});

const agingDaysOptions = getNumberRange?.(1, 180, 1);

export const LateFeesModal: FC<ILateFeesModal> = ({ open, onClose, billingGroupId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [billingGroups, setBillingGroups] = useState<IBillingGroup[]>([]);
  const [selectedTranCode, setSelectedTranCode] = useState<string>('');
  const [lateFeeOptions, setLateFeeOptions] = useState<ILateFeeOption[]>([]);

  const {
    resetForm,
    isSubmitting,
    handleSubmit,
    values,
    dirty,
    isValid,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useFormik<FormValues>({
    validateOnChange: true,
    initialValues: { ...DEFAULT_VALUES, billingGroupId: billingGroupId || '' },
    validationSchema: LateFeesSchema,
    onSubmit: async (values, actions) => {
      try {
        const officeId = billingGroups.find(
          group => group.billingGroupId === values.billingGroupId
        )?.officeId;
        if (!officeId) {
          return;
        }
        await postLateFees({
          officeId,
          agingDate: values.agingDate.toISOString(),
          agingDays: values.agingDays,
          minBalance: convertToNumber(values.minBalance),
          percentage: convertPercentToNumber(+values.percentage),
          amount: convertToNumber(values.amount),
          tranCodeId: selectedTranCode,
          reference: values.reference,
          option: values.option,
          billingGroupId: values.billingGroupId,
        });
        enqueueSnackbar('Successfully posted late fees', {
          variant: 'success',
        });
        onClose();
        actions.resetForm();
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? 'Posting late fees failed', {
          variant: 'error',
        });
      }
    },
  });

  const reset = useCallback(() => {
    resetForm({
      values: {
        ...DEFAULT_VALUES,
        billingGroupId: billingGroupId || '',
      },
    });
  }, [billingGroupId, resetForm]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const loadBillingGroups = async () => {
    const billingGroupData = await getBillingGroups({ perPage: -1 });
    setBillingGroups(billingGroupData.records);
  };

  const loadTranCode = async () => {
    const tranCodesData = await getAllTranCodes({ perPage: -1 });
    const lateFeesId =
      tranCodesData.records.filter(item => item.description === 'Late Fee')[0]?.tranCodeId ??
      '164919ff-8d19-431a-89c1-b5dcd3af9b4a';
    setSelectedTranCode(lateFeesId);
  };

  const loadLateFeeOptions = async () => {
    const lateFeeOptionData = await getLateFeeOptions();
    setLateFeeOptions(lateFeeOptionData);
  };

  useEffect(() => {
    loadBillingGroups();
    loadTranCode();
    loadLateFeeOptions();
  }, []);

  useEffect(() => {
    reset();
  }, [billingGroupId, reset]);

  return (
    <Modal open={open} onClose={handleClose} maxWidth="sm" title="Post Late Fees">
      {isSubmitting && <Loader type="overlay" position="centered" title="Posting..." />}
      <Fade in={open}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <FormControl fullWidth size="small" required>
                <StyledInputLabel>
                  Post to accounts with balances older than this many days
                </StyledInputLabel>
                <Select name="agingDays" value={values.agingDays} onChange={handleChange}>
                  {agingDaysOptions.map(n => (
                    <MenuItem key={n} value={n}>
                      {n}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Compute aging days as of this date"
                format="MM/dd/yyyy"
                value={values.agingDate}
                onChange={value => setFieldValue('agingDate', value, true)}
                slotProps={{
                  textField: {
                    size: 'small',
                    required: true,
                    error: !!errors.agingDate,
                    helperText: errors.agingDate,
                    fullWidth: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AmountInput
                value={values.minBalance}
                onChange={(val: string) => {
                  setFieldValue('minBalance', val);
                }}
                label="Only accounts with aged balances over"
                name="minBalance"
                required
                onBlur={handleBlur}
                error={touched.minBalance && !!errors.minBalance}
                helperText={touched.minBalance ? errors.minBalance : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small" required>
                <StyledInputLabel>Select Billing Group</StyledInputLabel>
                <Select name="billingGroupId" value={values.billingGroupId} onChange={handleChange}>
                  {billingGroups.map(group => (
                    <MenuItem key={group.billingGroupId} value={group.billingGroupId}>
                      {group.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="percentage"
                required
                size="small"
                label="Late fee percentage"
                value={values.percentage}
                onChange={handleChange}
                error={!!errors.percentage}
                helperText={errors.percentage}
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AmountInput
                value={values.amount}
                onChange={(val: string) => {
                  setFieldValue('amount', val);
                }}
                label="Late fee flat amount"
                name="amount"
                required
                onBlur={handleBlur}
                error={touched.amount && !!errors.amount}
                helperText={touched.amount ? errors.amount : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="reference"
                required
                size="small"
                label="Description to show on statement"
                value={values.reference}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.reference && !!errors.reference}
                helperText={touched.reference ? errors.reference : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small" required>
                <StyledInputLabel>Late fee transaction type</StyledInputLabel>
                <Select name="option" value={values.option} onChange={handleChange}>
                  {lateFeeOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
            <Button type="button" color="inherit" onClick={handleClose} startIcon={<CancelIcon />}>
              Cancel
            </Button>
            <Button
              disabled={!dirty || isSubmitting || !isValid}
              type="submit"
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
            >
              Post Late Fees
            </Button>
          </Box>
        </form>
      </Fade>
    </Modal>
  );
};

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  background: theme.palette.common.white,
}));
