import { FC, useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import {
  Modal,
  Loader,
  TextField,
  ModalSaveSection,
  SelectAsyncInput,
  AmountInput,
} from '../../../components';
import { Box, Fade, Grid, FormControlLabel, Checkbox } from '@mui/material';

// fetch
import { updateTranCode, createTranCode, getAccountingProviderAccounts } from '../../../fetch';
import {
  EAccountProvider,
  IAccountingProviderAccount,
  IQuickBooksAccount,
  ITranCode,
} from '../../../models';
import { convertToNumber, formatMoney } from '../../../helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IAddEditTranCodeModal {
  open: boolean;
  onClose: () => void;
  currentTranCode?: ITranCode | null;
  fetchTranCodes: () => void;
}

const Schema = Yup.object().shape({
  tranCodeId: Yup.string(),
  defaultAmount: Yup.string().test('max', 'Must be less than 10,000,000,000', val => {
    return convertToNumber(val) > 10000000000 ? false : true;
  }),
  isCredit: Yup.boolean(),
  isRevenue: Yup.boolean(),
  isTaxable: Yup.boolean(),
  isSummarized: Yup.boolean(),
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  sortOrder: Yup.number().required('Required'),
  qbAccount: Yup.string(),
});

export const AddEditTranCodeModal: FC<IAddEditTranCodeModal> = ({
  open,
  onClose,
  currentTranCode,
  fetchTranCodes,
}) => {
  const { user, office, accountingProvider } = useContext(UserContext);
  const featureFlags = useFlags();
  const hasQuickBooks =
    accountingProvider === EAccountProvider.QuickBooks && featureFlags.quickbooks ? true : false;
  const { enqueueSnackbar } = useSnackbar();
  const [quickBooksAccounts, setQuickBooksAccounts] = useState<IQuickBooksAccount[]>([]);

  const selectedQuickBooksAccount = (selectedAccountId: string) => {
    let account =
      quickBooksAccounts?.find(account => account.accountId === selectedAccountId) ?? null;
    return currentTranCode
      ? {
          quickBooksAccountTranCodeId:
            currentTranCode?.quickBooksAccount?.quickBooksAccountTranCodeId ?? '',
          accountId: account?.accountId ?? '',
          accountName: account?.accountName ?? '',
          accountType: account?.accountType ?? '',
          accountTypeDisplayName: account?.accountTypeDisplayName ?? '',
          quickBooksAccountId: currentTranCode?.quickBooksAccount?.quickBooksAccountId ?? '',
        }
      : account;
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          tranCodeId: currentTranCode?.tranCodeId ?? '',
          officeId: user?.officeId ?? '',
          code: currentTranCode?.code ?? '',
          description: currentTranCode?.description ?? '',
          sortOrder: currentTranCode?.sortOrder ? currentTranCode?.sortOrder.toString() : '0',
          isCredit: currentTranCode?.isCredit ?? false,
          isRevenue: currentTranCode?.isRevenue ?? false,
          isTaxable: currentTranCode?.isTaxable ?? false,
          isSummarized: currentTranCode?.isSummarized ?? false,
          defaultAmount: currentTranCode?.defaultAmount
            ? formatMoney(currentTranCode?.defaultAmount)
            : '$0.00',
          qbAccount: currentTranCode?.quickBooksAccount?.accountId ?? '',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data: ITranCode = {
            tranCodeId: values.tranCodeId,
            officeId: values.officeId,
            code: values.code,
            description: values.description,
            isCredit: values.isCredit,
            isRevenue: values.isRevenue,
            isTaxable: values.isTaxable,
            isSummarized: values.isSummarized,
            sortOrder: convertToNumber(values.sortOrder),
            defaultAmount: convertToNumber(values.defaultAmount),
            quickBooksAccount:
              hasQuickBooks && values.qbAccount
                ? selectedQuickBooksAccount(values.qbAccount)
                : null,
          };
          try {
            currentTranCode ? await updateTranCode(data) : await createTranCode(data);
            enqueueSnackbar(
              currentTranCode
                ? 'Successfully updated transaction code!'
                : 'Successfully created transaction code!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchTranCodes();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving transaction codes, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          handleSubmit,
          handleChange,
          isValid,
          values,
          setFieldValue,
          touched,
          errors,
          handleBlur,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
              title={currentTranCode ? 'Edit Transaction Code' : 'Add Transaction Code'}
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="sortOrder"
                          label="Sort Order"
                          type="number"
                          inputProps={{ min: 0, max: 2147483647 }}
                          value={values.sortOrder}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AmountInput
                          name="defaultAmount"
                          label="Default Amount"
                          value={values.defaultAmount}
                          onChange={val => {
                            setFieldValue('defaultAmount', val);
                          }}
                          onBlur={handleBlur}
                          required={false}
                          error={touched.defaultAmount && Boolean(errors.defaultAmount)}
                          helperText={
                            touched.defaultAmount && errors.defaultAmount
                              ? errors.defaultAmount
                              : ''
                          }
                        />
                      </Grid>
                      {hasQuickBooks && (
                        <Grid container item xs={12}>
                          <Grid item xs={12} sm={6} paddingRight={{ xs: 0, sm: '8px' }}>
                            <SelectAsyncInput
                              label="QB Account"
                              name="qbAccount"
                              value={values.qbAccount}
                              disabled={values.isCredit}
                              handleChange={val => {
                                setFieldValue('qbAccount', val);
                              }}
                              apiRequest={() => getAccountingProviderAccounts(office?.officeId!)}
                              transformResponse={(res: IAccountingProviderAccount[]) =>
                                res
                                  .map(r => ({
                                    label: `${r.accountTypeDisplayName} - ${r.accountName}`,
                                    value: r.accountId,
                                  }))
                                  .sort((a, b) => a.label.localeCompare(b.label))
                              }
                              handleResponseOptions={val => {
                                setQuickBooksAccounts(val);
                              }}
                              hasClear={!!values.qbAccount}
                              onClear={() => {
                                setFieldValue('qbAccount', '');
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          justifyContent="space-evenly"
                          width="100%"
                          alignItems="center"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isCredit}
                                onChange={e => {
                                  handleChange(e);
                                  if (hasQuickBooks && !!e.target.value) {
                                    setFieldValue('qbAccount', '');
                                  }
                                }}
                                name="isCredit"
                              />
                            }
                            label="Credit"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isRevenue}
                                onChange={handleChange}
                                name="isRevenue"
                              />
                            }
                            label="Revenue"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isTaxable}
                                onChange={handleChange}
                                name="isTaxable"
                              />
                            }
                            label="Taxable"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isSummarized}
                                onChange={handleChange}
                                name="isSummarized"
                              />
                            }
                            label="Summarized on Statement"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                      }}
                      isSaveDisabled={isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
