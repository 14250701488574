import { DateTimePicker as MuiDateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { useContext, FC } from 'react';
import { UserContext } from '../../context';

interface IDateTimePicker extends DateTimePickerProps<any> {
  name?: string;
  error?: boolean;
  textFieldClass?: string;
  textFieldHelperText?: string;
}

export const DateTimePicker: FC<IDateTimePicker> = ({
  label,
  onChange,
  value,
  name,
  error,
  textFieldClass,
  textFieldHelperText,
  ...props
}) => {
  const { timezone } = useContext(UserContext);
  return (
    <MuiDateTimePicker
      {...props}
      label={label}
      onChange={onChange}
      value={value ? new Date(value) : null}
      slotProps={{
        textField: {
          name: name ?? 'date-time-picker',
          error,
          size: 'small',
          classes: { root: textFieldClass },
          helperText: textFieldHelperText ?? '',
          fullWidth: true,
        },
      }}
      timezone={timezone}
      viewRenderers={{
        hours: null,
        minutes: null,
        seconds: null,
      }}
    />
  );
};
