import { FC, useContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { faEdit, faList } from '@fortawesome/free-solid-svg-icons';
import { Stack, Button, Box } from '@mui/material';
import { Documents, FloatingToolbar, WaterAnalysis } from '../../components';
import {
  createSiteDocuments,
  deleteAccountDocument,
  getAccountDocuments,
  updateAccountDocument,
} from '../../fetch';
import { SiteImages, DisplaySiteInformation } from './';
import { ISiteGetResponse } from '../../models/sites';
import { ScheduledServices } from '../services/ScheduledServices';
import { PoolStructureCard } from './pool-structure';
import { Treatments } from '../../components/treatments';
import { UserContext } from '../../context';
import { Permissions } from '../../constants';
import { OTSLandingServices } from '../services/one-time-services/ots-landing-services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisplayAdditionalInformation } from './display-additional-information';
import { hasCorrectUserPermissions } from '../../helpers';

interface ISiteDetailDisplayViewProps {
  isModal?: boolean;
  accountId: string;
  siteId: string;
  site: ISiteGetResponse;
  isAllExpanded?: boolean;
  handleEditSite: (site: ISiteGetResponse) => void;
  handleViewAll: () => void;
}

export const SiteDetailDisplayView: FC<ISiteDetailDisplayViewProps> = ({
  isModal,
  accountId,
  siteId,
  site,
  isAllExpanded,
  handleEditSite,
  handleViewAll,
}) => {
  const { user } = useContext(UserContext);
  const { v2Ots } = useFlags();

  return (
    <Stack gap={2}>
      <DisplaySiteInformation isExpanded={isAllExpanded} site={site} />
      {(site.additionalSiteInformation.length > 0 || site.chargeForChemicals) && (
        <DisplayAdditionalInformation isExpanded={isAllExpanded} site={site} />
      )}
      <ScheduledServices
        accountId={accountId}
        redirect={`/customers/${accountId}/sites?siteId=${siteId}`}
        siteId={siteId}
        title="Maintenance Services"
        isCollapsible
        initialExpand
        isExpanded={isAllExpanded}
        isEditable={false}
        isSitePage
        showRecurringTab
        hasQueryParamFiltering={false}
      />
      {v2Ots && (
        <OTSLandingServices
          accountId={accountId}
          redirect={`/customers/${accountId}/sites?siteId=${siteId}`}
          siteId={siteId}
          isCollapsible
          initialExpand
          isEditable={false}
          isExpanded={isAllExpanded}
        />
      )}
      <PoolStructureCard
        siteId={siteId}
        isCollapsible
        initialExpand
        isExpanded={isAllExpanded}
        readOnly
        shouldFetch={site?.hasPoolEquipment ?? false}
      />
      {hasCorrectUserPermissions(Permissions.ViewPoolPhotos, user!) && (
        <SiteImages siteId={siteId} isEditable={false} isExpanded={isAllExpanded} showPagination />
      )}
      <Documents
        gridKeyName="site-documents-grid"
        labelContext="Site"
        getApiRequest={getAccountDocuments}
        deleteApiRequest={deleteAccountDocument}
        postApiRequest={createSiteDocuments}
        putApiRequest={updateAccountDocument}
        getFilters={{ siteId: siteId, accountId: accountId }}
        postFilterId={siteId}
        disableDelete={item => {
          return !!item.repairId ? true : false; // If repairId, disable delete button
        }}
        isEditable={false}
        marginTop={0}
        isCollapsible
        initialExpand
        isExpanded={isAllExpanded}
        hasWrapper
        hideOnEmpty
      />
      <Treatments
        isCollapsible
        initialExpand
        siteId={siteId}
        siteName={site?.accountName}
        isModal={isModal}
        isExpanded={isAllExpanded}
        isEditable={false}
      />
      <WaterAnalysis
        siteId={siteId}
        isExpanded={isAllExpanded}
        initialExpand
        cardTitle="Water Analysis History"
        isCardLayout
        isHistory
      />
      <FloatingToolbar isModal={isModal}>
        <Box display="flex" gap={1}>
          <Button
            onClick={handleViewAll}
            color="primary"
            startIcon={<FontAwesomeIcon icon={faList} />}
          >
            Manage Sites
          </Button>
          <Button
            onClick={() => handleEditSite(site)}
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faEdit} size="sm" />}
          >
            Edit Site
          </Button>
        </Box>
      </FloatingToolbar>
    </Stack>
  );
};
