import { Modal, ModalSaveSection, Loader, Tabs } from '../../../../components';
import { Fade, Box, useMediaQuery, Button } from '@mui/material';
import { FC, useState, useMemo, useContext, useEffect } from 'react';
import { getUsers, updateRepairVisits, getRepairVisits } from '../../../../fetch';
import {
  IListUser,
  IOneTimeServiceDetail,
  IResponse,
  IRepair,
  IUpdateRepairVisitMultiple,
  ICalendarDateRange,
  IColorSetMap,
} from '../../../../models';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { useConfirm } from '../../../../hooks';
import { endOfWeek, isPast, startOfWeek } from 'date-fns';
import { deepEqual } from 'fast-equals';
import { UserContext } from '../../../../context';
import { getColorMap } from '../../../../helpers';
import { defaultUnsavedChangesMessage } from '../../../../constants';
import { OTSSchedulerLegend } from './ots-scheduler-legend';
import { OTSSchedulerCalendarTab } from './ots-scheduler-calendar-tab';
import { OTSSchedulerMapTab } from './ots-scheduler-map-tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

interface IOTSSchedulerModal {
  open: boolean;
  onClose: (shouldReload?: boolean) => void;
  service?: IOneTimeServiceDetail | null;
  allowCreate?: boolean;
}

export const OTSSchedulerModal: FC<IOTSSchedulerModal> = ({
  onClose,
  open,
  service,
  allowCreate,
}) => {
  const [selectedTechId, setSelectedTechId] = useState('');
  const [selectedTech, setSelectedTech] = useState<IListUser | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingSelectedTech, setIsShowingSelectedTech] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<ICalendarDateRange | undefined>({
    startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
  });
  const [initialVisits, setInitialVisits] = useState<IRepair[]>([]);
  const [visitsColorMap, setVisitsColorMap] = useState<IColorSetMap>({});
  const [scheduledVisits, setScheduledVisits] = useState<IRepair[]>([]);
  const [deletedVisits, setDeletedVisits] = useState<string[]>([]);
  const [isSingleViewMode, setIsSingleViewMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>('scheduler');

  const confirm = useConfirm();
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const isSmMobile = useMediaQuery(`(max-width: 800px)`);

  const { isLoading: isLoadingAllVisits, refetch: fetchRepairVisits } = useQuery<
    IResponse<IRepair[]>,
    Error
  >(
    ['getRepairVisits', selectedDateRange, open],
    () =>
      getRepairVisits({
        perPage: -1,
        officeId: user?.officeId,
        sortDirection: 'desc',
        includeCancelledRepairs: false,
        serviceStartDate: selectedDateRange?.startDate?.toISOString(),
        serviceEndDate: selectedDateRange?.endDate?.toISOString(),
      }),
    {
      onSuccess: d => {
        const filteredAssignedToArray = d.records.filter((obj, index, arr) => {
          return arr.map(mapObj => mapObj.assignedTo).indexOf(obj.assignedTo) === index;
        });
        setVisitsColorMap(getColorMap(filteredAssignedToArray, 'assignedTo'));
        const initial = isSubmitting ? [...d.records] : [...initialVisits, ...d.records]; // If submitting, completely reset array
        const scheduled = isSubmitting ? [...d.records] : [...scheduledVisits, ...d.records]; // If submitting, completely reset array
        setInitialVisits(
          // remove duplicates, so we maintain if the user created any visits and then changed the date range and then came back
          initial.filter(
            (value, index, self) =>
              index === self.findIndex(t => t.repairVisitId === value.repairVisitId)
          )
        );
        setScheduledVisits(
          // remove duplicates, so we maintain if the user created any visits and then changed the date range and then came back
          scheduled.filter(
            (value, index, self) =>
              index === self.findIndex(t => t.repairVisitId === value.repairVisitId)
          )
        );
      },
      enabled: open,
    }
  );

  const { isLoading: isLoadingTechs, data: techsData } = useQuery<IResponse<IListUser[]>, Error>(
    ['getUsers'],
    () => getUsers({ perPage: -1, onlyIncludeRepairTechs: true, isDisabled: false }),
    {
      onSuccess: d => {
        setSelectedTech(d.records?.[0]);
        setSelectedTechId(d.records?.[0]?.userId);
      },
    }
  );

  const techs = useMemo(() => techsData?.records ?? [], [techsData]);

  const handleClose = async () => {
    if (!deepEqual(initialVisits, scheduledVisits)) {
      const result = await confirm(defaultUnsavedChangesMessage);
      if (result) {
        onClose();
        setScheduledVisits(initialVisits);
        setSelectedDateRange({
          startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
          endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
        });
      } else {
        return;
      }
    } else {
      onClose();
      setSelectedDateRange({
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      });
    }
    // reset to first tech in the list on close
    setTimeout(() => {
      setSelectedTechId(techs?.[0]?.userId);
      setSelectedTech(techs?.[0]);
      setIsShowingSelectedTech(false);
      setScheduledVisits([]);
      setDeletedVisits([]);
      setInitialVisits([]);
      setSelectedTab('scheduler');
      if (isSmMobile) {
        setIsSingleViewMode(true);
      } else {
        setIsSingleViewMode(false);
      }
      setSelectedDateRange({
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      });
    }, 500);
  };

  const handleSave = async (closeModal?: boolean) => {
    const presentAndFutureScheduledVisits = scheduledVisits
      // only grab present and future visits
      .filter(v => v?.startTime && !isPast(new Date(v.startTime)));
    const formatVisits = (visits: any[]) => {
      return visits.map(v => ({
        repairVisitId: v.isNew ? null : v.repairVisitId,
        technicianId: v.userId,
        startTime: v.startTime ? new Date(v.startTime).toISOString() : null,
        endTime: v.endTime ? new Date(v.endTime).toISOString() : null,
      })) as IUpdateRepairVisitMultiple[];
    };
    const newVisits = formatVisits(presentAndFutureScheduledVisits.filter(e => !!e.isNew));
    const updatedVisits = formatVisits(
      presentAndFutureScheduledVisits.filter(e => !e.isNew && !!e.isMoved)
    );
    try {
      setIsSubmitting(true);
      await updateRepairVisits(
        {
          addedRepairVisits: newVisits,
          updatedRepairVisits: updatedVisits,
          deletedRepairVisits: deletedVisits,
        },
        {
          repairId: (service?.repairId as string) ?? undefined,
        }
      );
      await fetchRepairVisits();
      enqueueSnackbar('OTS Visits saved successfully', {
        variant: 'success',
      });
      if (!!closeModal) {
        onClose(true);
        setSelectedTechId(techs?.[0].userId);
        setSelectedTech(techs?.[0]);
        setIsShowingSelectedTech(false);
        setInitialVisits([]);
        setScheduledVisits([]);
      }
      setDeletedVisits([]);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? 'Error saving ots visits, please try again', {
        variant: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isSmMobile) {
      return setIsSingleViewMode(true);
    }
    return setIsSingleViewMode(false);
  }, [isSmMobile]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      fullHeight
      fullWidth
      maxWidth={false}
      title="OTS Visit Scheduler"
    >
      <Box>
        {(isSubmitting || isLoadingTechs || isLoadingAllVisits || isLoading) && (
          <Loader type="overlay" position="centered" />
        )}
        <Fade in={open}>
          <Box>
            <Tabs
              id="ots-scheduler-tabs"
              size="md"
              selectedTab={selectedTab}
              setSelectedTab={val => {
                setSelectedTab(val);
              }}
              tabs={[
                {
                  key: 'scheduler',
                  title: 'Scheduler',
                  hasUnsavedChanges: !deepEqual(initialVisits, scheduledVisits),
                  children: (
                    <OTSSchedulerCalendarTab
                      allowCreate={allowCreate}
                      service={service}
                      selectedTechId={selectedTechId}
                      setSelectedTechId={setSelectedTechId}
                      isLoadingTechs={isLoadingTechs}
                      techs={techs}
                      setSelectedTech={setSelectedTech}
                      isShowingSelectedTech={isShowingSelectedTech}
                      setIsShowingSelectedTech={setIsShowingSelectedTech}
                      selectedDateRange={selectedDateRange}
                      setSelectedDateRange={setSelectedDateRange}
                      selectedTech={selectedTech}
                      visitsColorMap={visitsColorMap}
                      scheduledVisits={scheduledVisits}
                      setScheduledVisits={setScheduledVisits}
                      setDeletedVisits={setDeletedVisits}
                      deletedVisits={deletedVisits}
                      setIsSingleViewMode={setIsSingleViewMode}
                      isSingleViewMode={isSingleViewMode}
                      isSmMobile={isSmMobile}
                    />
                  ),
                },
                {
                  key: 'map',
                  title: 'Map',
                  children: (
                    <OTSSchedulerMapTab
                      selectedTab={selectedTab}
                      techs={techs}
                      initialDateRange={selectedDateRange}
                      deletedVisits={deletedVisits}
                      isLoading={isLoading || isLoadingAllVisits || isLoadingTechs}
                      setIsLoading={setIsLoading}
                    />
                  ),
                },
              ]}
            />
            {selectedTab === 'scheduler' && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: isSmMobile ? 'flex-end' : 'center',
                  justifyContent: 'flex-end',
                  flexDirection: isSmMobile ? 'column' : 'row',
                  width: '100%',
                  marginTop: theme => theme.spacing(2),
                }}
              >
                <OTSSchedulerLegend />
                <ModalSaveSection
                  hasNoMarginTop={!isSmMobile}
                  handleCancel={handleClose}
                  handleSave={() => handleSave(false)}
                  isSaveDisabled={isSubmitting || deepEqual(initialVisits, scheduledVisits)}
                  fullWidth={isSmMobile}
                >
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      handleSave(true);
                    }}
                    startIcon={<FontAwesomeIcon icon={faSave} />}
                    disabled={isSubmitting || deepEqual(initialVisits, scheduledVisits)}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Save & Close
                  </Button>
                </ModalSaveSection>
              </Box>
            )}
          </Box>
        </Fade>
      </Box>
    </Modal>
  );
};
