import { SxProps, styled } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';

interface IExternalLink {
  className?: string;
  to: string;
  type?: 'primary' | 'white' | 'secondary';
  target?: '_self' | '_blank' | '_parent' | '_top';
  title?: string;
  sx?: SxProps;
}

export const ExternalLink: FC<IExternalLink> = ({
  to,
  children,
  className,
  type = 'primary',
  target,
  title,
  sx,
}) => {
  return (
    <StyledAnchor
      href={to}
      rel="noopener noreferrer"
      target={target}
      className={clsx(classes[type], className)}
      title={title}
      sx={sx}
    >
      {children}
    </StyledAnchor>
  );
};

const PREFIX = 'ExternalLink';

const classes = {
  white: `${PREFIX}-white`,
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
};

const StyledAnchor = styled('a')(({ theme }) => ({
  [`&.${classes.white}`]: {
    color: theme.palette.background.paper,
    textDecoration: 'none',
  },

  [`&.${classes.primary}`]: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },

  [`&.${classes.secondary}`]: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
}));
