import { FormControlLabel, Radio, RadioGroup, TextField, MenuItem, Grid } from '@mui/material';
import { useState, Dispatch, FC, useEffect } from 'react';
import { getDailyWeeklyOptions } from '../../helpers';

interface IDaily {
  setSchedule: Dispatch<{
    interval1: number;
    recurPeriod: string;
  }>;
  interval1: number;
}

export const Daily: FC<IDaily> = ({ setSchedule, interval1 }) => {
  const [recurOption, setRecurOption] = useState<boolean>(false);
  const [val1, setInterval1] = useState<number>(interval1 ?? 1);

  useEffect(() => {
    setSchedule({ recurPeriod: 'Daily', interval1: val1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val1]);
  return (
    <>
      <RadioGroup
        aria-labelledby="options"
        defaultValue={false}
        name="daily-options"
        value={recurOption}
        onChange={(_, val) => setRecurOption(val as any)}
      >
        <>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <FormControlLabel value={false} control={<Radio />} label="Every" />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label=""
                select
                onChange={(e: any) => {
                  setInterval1(e.target.value);
                }}
                value={interval1}
                size="small"
              >
                {getDailyWeeklyOptions().map((val, index) => (
                  <MenuItem key={`${index}`} value={val.value}>
                    {val.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              <span>day(s)</span>
            </Grid>
          </Grid>
          <FormControlLabel value={true} control={<Radio />} label="Every weekday" />
        </>
      </RadioGroup>
    </>
  );
};
