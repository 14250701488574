import { Avatar, Typography, Box, Tooltip, Stack, styled } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';
import { IRepair } from '../../../../models';
import { formatTime } from '../../../../helpers';
import { theme } from '../../../../styles';
import { InvalidLocationIcon } from '../../../../components';

export interface IRepairItem {
  repair: IRepair;
  condense?: boolean;
  showIndex?: boolean;
  indexStyle?: 'avatar' | 'inline';
  index: number;
  techColor?: string;
  colorizeIndex?: boolean;
  shouldHandleInvalidLocation?: boolean;
}

export const RepairItem: FC<IRepairItem> = ({
  repair,
  condense = false,
  showIndex = false,
  indexStyle = 'avatar',
  index,
  techColor,
  colorizeIndex,
  shouldHandleInvalidLocation = true,
}) => {
  return (
    <StyledBox display="flex" justifyContent="space-between" key={repair.repairVisitId}>
      <Box
        sx={{
          backgroundColor: theme => theme.palette.grey[300],
          borderBottom: 0,
          borderTop: 0,
          borderRadius: '6px',
          margin: '8px 0',
        }}
        className={clsx(
          !repair?.siteAddress?.latitude || !repair?.siteAddress?.longitude
            ? classes.warning
            : classes.visit
        )}
      >
        {showIndex && indexStyle === 'avatar' && (
          <Avatar
            className={classes.indexBadge}
            sx={{
              border: `2px solid ${techColor || '#aaaaaa'}`,
              backgroundColor: `${techColor || '#aaaaaa'}`,
            }}
          >
            {index}
          </Avatar>
        )}

        <Stack sx={{ overflow: 'hidden', flex: 1 }} gap={0.5}>
          <Typography
            className={classes.visitName}
            display={colorizeIndex ? 'flex' : 'block'}
            alignItems={colorizeIndex ? 'center' : 'unset'}
            component="div"
          >
            {showIndex && indexStyle === 'inline' && (
              <>
                <Box
                  component={colorizeIndex ? 'div' : 'span'}
                  sx={{
                    ...(colorizeIndex && {
                      backgroundColor: `${techColor || '#aaaaaa'}`,
                      color: theme.palette.common.white,
                      borderRadius: '50%',
                      padding: theme.spacing(0.5),
                      lineHeight: '2',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: theme.spacing(2.5),
                      height: theme.spacing(2.5),
                      fontSize: '.75rem',
                    }),
                    marginRight: theme.spacing(0.5),
                  }}
                >
                  {`${index}${colorizeIndex ? '' : '.'}`}
                </Box>
              </>
            )}
            {repair?.siteName ?? 'No Address'}
          </Typography>
          <>
            <Typography
              lineHeight={1}
              variant="caption"
              fontWeight={'bold'}
              color={theme => theme.palette.grey[700]}
            >
              {formatTime(repair?.startTime)} - {formatTime(repair?.endTime)}
            </Typography>
            <Tooltip title={repair?.serviceType}>
              <Typography variant="caption" className={classes.truncateText}>
                {repair?.serviceType}
              </Typography>
            </Tooltip>
            <Box display="flex" alignItems="end">
              <Box flex={1} display="flex" alignItems="center" justifyContent="flex-end">
                {(!repair?.siteAddress?.latitude || !repair?.siteAddress?.longitude) && (
                  <InvalidLocationIcon
                    accountId={shouldHandleInvalidLocation ? repair.accountId : undefined}
                    siteId={shouldHandleInvalidLocation ? repair.siteId : undefined}
                  />
                )}
              </Box>
            </Box>
          </>
        </Stack>
      </Box>
    </StyledBox>
  );
};

const PREFIX = 'RepairItem';

const classes = {
  visit: `${PREFIX}-visit`,
  warning: `${PREFIX}-warning`,
  indexBadge: `${PREFIX}-indexBadge`,
  visitName: `${PREFIX}-visitName`,
  truncateText: `${PREFIX}-truncateText`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.visit}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
    padding: '8px',
    gap: '3px',
    boxShadow: 'none',
  },

  [`& .${classes.warning}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(211, 47, 47, .25)',
    width: '100%',
    padding: '8px',
  },

  [`& .${classes.indexBadge}`]: {
    width: '24px',
    height: '24px',
    fontSize: '16px',
    marginRight: '12px',
  },

  [`& .${classes.visitName}`]: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    lineHeight: 1,
  },

  [`& .${classes.truncateText}`]: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));
