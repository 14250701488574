import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Fade, Grid, Button, Typography, styled } from '@mui/material';
import clsx from 'clsx';
import { deepEqual } from 'fast-equals';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useContext, useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Loader,
  Page,
  InvoiceDetail,
  CardTitle,
  Modal,
  ConfirmPrompt,
  FloatingToolbar,
  CreditLimitAlert,
  SaveButton,
  Card,
  Tabs,
  ITab,
  UploadedImage,
  WaterAnalysis,
  CancelIcon,
} from '../../components';
import { Treatments } from '../../components/treatments';
import { UserContext } from '../../context';
import {
  createScheduledServices,
  getScheduledService,
  getUsers,
  updateScheduledServices,
  createRecurringService,
  getRecurringService,
  updateRecurringServices,
  deleteRecurringService,
  deleteScheduledService,
  getServicePhotos,
  checkForServiceExceptions,
} from '../../fetch';
import { convertToNumber, formatMoney, getIntervalValues } from '../../helpers';
import {
  ICreateScheduledService,
  IRecurringServiceDetail,
  IListUser,
  IScheduledServiceDetail,
  IServiceSuggestionChecklist,
  ICreateRecurringService,
  ServicePodType,
  IServicePhoto,
  IServiceDefinition,
  ISiteSimple,
} from '../../models';
import { BillingCommissionInfo } from './BillingCommissionInfo';
import { ChecklistInfo } from './ChecklistInfo';
import { PSISection } from './PSISection';
import { RecurringPattern } from './RecurringPattern';
import { ServiceDetailsCard } from './ServiceDetailsCard';
import { ServiceLog } from './ServiceLogSection';
import { ServiceRecommendations } from './ServiceRecommendations';
import { PoolStructureCard } from '../sites/pool-structure';
import { ServiceImages } from './ServiceImages';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useConfirm } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultUnsavedChangesMessage } from '../../constants';
import { Tasks } from '../scheduling';
import { ScheduledServices } from '../services/ScheduledServices';

interface IServiceDetailPage {
  recurringId?: string;
  isModalOpen?: boolean;
  handleModalClose?: () => void;
  isModal?: boolean;
  currentServiceId?: string;
  podType?: ServicePodType | null;
}

const RecurringDetailPageSchema = Yup.object().shape({
  initialDate: Yup.string().nullable().required('Required'),
  siteId: Yup.string().required('Required'),
  serviceDefId: Yup.string().required('Required'),
  description: Yup.string(),
  serviceAmount: Yup.string().required('Required'),
  dateCreated: Yup.string().nullable().required('Required'),
  createdBy: Yup.string().required('Required'),
  assignedTo: Yup.string().required('Required'),
  previousSiteId: Yup.string().nullable(),
  endDate: Yup.string().nullable().notRequired(),
  isRecurring: Yup.boolean(),
  recurOption: Yup.boolean(),
  recurPeriod: Yup.string(),
  dailyInterval1: Yup.number(),
  weeklyInterval1: Yup.number(),
  weeklyInterval2: Yup.mixed().when('recurPeriod', {
    is: (val: string) => val === 'Weekly',
    then: Yup.number().min(1, 'Required'),
    otherwise: Yup.number(),
  }),
  monthlyInterval1: Yup.number(),
  monthlyInterval2: Yup.number(),
  monthlyInterval3: Yup.number(),
  yearlyInterval1: Yup.number(),
  yearlyInterval2: Yup.number(),
  yearlyInterval3: Yup.number(),
  version: Yup.string().nullable(),
});

const ServiceDetailPageSchema = Yup.object().shape({
  // Service Details
  siteId: Yup.string().required('Required'),
  serviceDate: Yup.mixed().when('isRecurring', {
    is: (val: boolean) => val,
    then: Yup.string().notRequired(),
    otherwise: Yup.date().typeError('Invalid Date').nullable().required('Required'),
  }),
  initialDate: Yup.mixed().when('isRecurring', {
    is: (val: boolean) => val,
    then: Yup.date().typeError('Invalid Date').nullable().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  serviceDefId: Yup.string().required('Required'),
  // Not sure yet if this will be an ID or Guid, so I'm going to stick with a string for now
  assignedTo: Yup.string().required('Required'),
  previousSiteId: Yup.string().nullable(),
  status: Yup.mixed().when('isRecurring', {
    is: (val: boolean) => val,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('Required'),
  }),
  earliestArrival: Yup.string().nullable(),
  latestArrival: Yup.string().nullable(),
  estimatedDuration: Yup.number().min(1, 'Duration must be greater than 0').nullable(),
  startingTime: Yup.string().nullable(),
  description: Yup.string(),
  completionNotes: Yup.string(),
  sendEmail: Yup.boolean(),
  emailTemplateId: Yup.string(),
  emailAddress: Yup.mixed().when('sendEmail', {
    is: (val: boolean) => {
      return val;
    },
    then: Yup.array(Yup.string().email('Invalid email address'))
      .min(1, 'Required')
      .required('Required'),
    otherwise: Yup.array(Yup.string().email('Invalid email address')).nullable(),
  }),
  emailAddressBcc: Yup.array(Yup.string().email('Invalid email address')).nullable(),
  serviceChecklistItems: Yup.array(),
  // Billing/Commission Information
  serviceAmount: Yup.string().required('Required'),
  override: Yup.boolean(),
  dateCreated: Yup.string().nullable().required('Required'),
  transactionCode: Yup.string().nullable(),
  createdBy: Yup.string().required('Required'),
  endDate: Yup.string().nullable().notRequired(),
  // create/edit a recurring service
  isRecurring: Yup.boolean(),
  dailyInterval1: Yup.number(),
  weeklyInterval1: Yup.number(),
  weeklyInterval2: Yup.number(),
  monthlyInterval1: Yup.number(),
  monthlyInterval2: Yup.number(),
  monthlyInterval3: Yup.number(),
  yearlyInterval1: Yup.number(),
  yearlyInterval2: Yup.number(),
  yearlyInterval3: Yup.number(),
  recurOption: Yup.boolean(),
  recurPeriod: Yup.string(),
  version: Yup.string().nullable(),
});
export const ServiceDetailPage: FC<IServiceDetailPage> = ({
  recurringId,
  isModalOpen,
  handleModalClose,
  isModal,
  currentServiceId,
  podType,
}) => {
  const params = new URLSearchParams(window.location.search);
  const redirect = params?.get?.('redirect');
  const accountIdParam = params?.get?.('accountId');
  // editing a single service
  const { serviceId: serviceIdParam }: { serviceId: string } = useParams();
  const serviceId = serviceIdParam || currentServiceId!;
  // used for creating/editing a new recurring service
  const isRecurring = !!recurringId || serviceId === 'recurring';
  // used for created a new single service
  const isNewService = serviceId === 'new' || serviceId === 'recurring';
  // context
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const confirm = useConfirm();

  const [schedule, setService] = useState<IScheduledServiceDetail | null>(null);
  const [recurring, setRecurring] = useState<IRecurringServiceDetail | null>(null);
  const [isLoadingService, setIsLoadingService] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const history = useHistory();
  const [hasServiceLoaded, setServiceLoaded] = useState<boolean>(false);
  const [users, setUsers] = useState<IListUser[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isAllExpanded, setIsAllExpanded] = useState<boolean | undefined>(undefined);
  const [isInvalidInitialDate, setIsInvalidInitialDate] = useState(false);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState({
    reason: '',
    check: false,
  });
  const [selectedTab, setSelectedTab] = useState<string>(
    (podType as string) ?? ServicePodType.POOL
  );
  const [isLoadingServicePhoto, setIsLoadingServicePhoto] = useState(false);
  const [selectedServicePhoto, setServicePhoto] = useState<IServicePhoto | null>(null);
  const [serviceDefinitions, setServiceDefinitions] = useState<IServiceDefinition[]>([]);
  const [selectedSite, setSelectedSite] = useState<ISiteSimple | null>(null);

  const fetchUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const res = await getUsers({
        perPage: -1,
        officeId: user?.officeId,
        isDisabled: isNewService ? false : undefined,
      });
      setUsers(res.records);
    } catch (error) {
      enqueueSnackbar(`Error loading users, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const isEditing = () => {
    if (isRecurring || isNewService) {
      return false;
    } else {
      return true;
    }
  };

  const fetchRecurringScheduledService = async () => {
    if (recurringId) {
      setIsLoadingService(true);
      try {
        const res = await getRecurringService(recurringId);
        setRecurring(res);
        setServiceLoaded(true);
        // wait till the page is loading before flipping this flag so the form is in correct state
        setTimeout(() => {
          setServiceLoaded(false);
        }, 500);
      } catch (error) {
        enqueueSnackbar(`Error loading recurring service, please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsLoadingService(false);
      }
    }
  };

  const fetchScheduledService = async () => {
    if (isEditing()) {
      setIsLoadingService(true);
      try {
        const res = await getScheduledService(serviceId);
        setService(res);
        setServiceLoaded(true);
        // wait till the page is loading before flipping this flag so the form is in correct state
        setTimeout(() => {
          setServiceLoaded(false);
        }, 500);
      } catch (error: any) {
        if (error?.Status === 404) {
          enqueueSnackbar('Redirecting...', {
            variant: 'info',
          });
          enqueueSnackbar(error.Detail, {
            variant: 'error',
          });
          return setTimeout(function () {
            history.push(redirect ?? '/services/maintenance');
          }, 3000);
        } else {
          enqueueSnackbar(`Error loading scheduled service, please try again.`, {
            variant: 'error',
          });
        }
      } finally {
        setIsLoadingService(false);
      }
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchRecurringScheduledService();
    fetchScheduledService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchSitePhoto = async () => {
      setIsLoadingServicePhoto(true);
      try {
        const res = await getServicePhotos(schedule?.scheduledServiceId!);
        if (res.length > 0) {
          setServicePhoto(res?.[0]);
        }
      } catch (error) {
      } finally {
        setIsLoadingServicePhoto(false);
      }
    };

    if (schedule && selectedTab === 'photo' && !selectedServicePhoto) {
      fetchSitePhoto();
    }
  }, [selectedTab, schedule, selectedServicePhoto]);

  const siteId = useMemo(
    () => {
      const siteIdInParams = params?.get?.('siteId');
      const siteIdInRedirectParams = new URLSearchParams(params?.get?.('redirect') ?? '')?.get?.(
        'siteId'
      );
      const siteIdInRedirectParamsSplit = siteIdInRedirectParams?.includes('?')
        ? siteIdInRedirectParams?.split('?')?.[0]
        : siteIdInRedirectParams;
      return siteIdInParams ?? siteIdInRedirectParamsSplit ?? schedule?.siteId;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schedule]
  );

  const currentRecurringServiceDef = useMemo(() => {
    return serviceDefinitions?.find(service => service.serviceDefId === recurring?.serviceDefId);
  }, [recurring, serviceDefinitions]);

  const currentServiceDef = useMemo(() => {
    return serviceDefinitions?.find(service => service.serviceDefId === schedule?.serviceDefId);
  }, [schedule, serviceDefinitions]);

  if (isLoadingService) {
    return isModal ? (
      <Modal
        open={isModalOpen!}
        onClose={handleModalClose!}
        maxWidth="lg"
        title={podType ? '' : 'Edit Service'}
      >
        <Fade in={isModalOpen!}>
          <Box mt={3}>
            <Box minHeight="30rem" display="flex" alignItems="center" justifyContent="center">
              <Loader position="centered" />
            </Box>
          </Box>
        </Fade>
      </Modal>
    ) : (
      <Page
        title="Loading..."
        breadcrumb={{
          text: 'Maintenance Services',
          title: 'Back to Maintenance',
          link: redirect?.includes(`/services/maintenance`) ? redirect : '/services/maintenance',
        }}
      >
        <Loader position="centered" />
      </Page>
    );
  }
  return (
    <Wrapper>
      {isDeleting && <Loader type="overlay" position="centered" title="Deleting..." />}
      <Formik
        initialValues={
          isRecurring
            ? {
                siteId: recurring?.siteId ?? '',
                previousSiteId: recurring?.previousSiteId ?? null,
                initialDate: recurring?.initialDate ?? new Date(),
                endDate: recurring?.endDate ? recurring?.endDate : '',
                serviceDefId: recurring?.serviceDefId ?? '',
                assignedTo: recurring?.userId ?? '',
                description: recurring?.comments ?? '',
                serviceAmount:
                  recurring?.amountToCharge === null && currentRecurringServiceDef
                    ? formatMoney(currentRecurringServiceDef?.defaultAmountToCharge, 2)
                    : formatMoney(recurring?.amountToCharge, 2),
                dateCreated: recurring?.whenCreated ?? new Date(),
                createdBy: recurring?.createdByUserId ?? user?.userId,
                createdByUserName: recurring?.createdByUserName ?? '',
                createdByUserId: recurring?.createdByUserId ?? '',
                isRecurring,
                dailyInterval1: recurring?.recurPeriod === 'Daily' ? recurring?.interval1 : 1,
                weeklyInterval1: recurring?.recurPeriod === 'Weekly' ? recurring?.interval1 : 1,
                weeklyInterval2: recurring?.recurPeriod === 'Weekly' ? recurring?.interval2 : 0,
                monthlyInterval1: recurring?.recurPeriod === 'Monthly' ? recurring?.interval1 : 1,
                monthlyInterval2: recurring?.recurPeriod === 'Monthly' ? recurring?.interval2 : 1,
                monthlyInterval3: recurring?.recurPeriod === 'Monthly' ? recurring?.interval3 : 1,
                yearlyInterval1: recurring?.recurPeriod === 'Yearly' ? recurring?.interval1 : 1,
                yearlyInterval2: recurring?.recurPeriod === 'Yearly' ? recurring?.interval2 : 1,
                yearlyInterval3: recurring?.recurPeriod === 'Yearly' ? recurring?.interval3 : 1,
                recurOption: recurring?.recurOption ?? false,
                recurPeriod: recurring?.recurPeriod ?? 'Weekly',
                version: recurring?.version ?? null,
                estimatedDuration: '',
                completionNotes: '',
              }
            : {
                siteId: schedule?.siteId ?? '',
                serviceDate: schedule?.serviceDate ?? '',
                serviceDefId: schedule?.serviceDefId ?? '',
                assignedTo: schedule?.assignedToUserId ?? '',
                previousSiteId: schedule?.previousSiteId ?? null,
                status: schedule?.status ?? 'Open',
                earliestArrival: schedule?.earliestArrival ?? null,
                latestArrival: schedule?.latestArrival ?? null,
                estimatedDuration: schedule?.durationInMinutes || '',
                startingTime: schedule?.whenStarted ?? null,
                description: schedule?.comments ?? '',
                completionNotes: schedule?.completionNotes ?? '',
                serviceAmount:
                  schedule?.amountToCharge === null && currentServiceDef
                    ? formatMoney(currentServiceDef?.defaultAmountToCharge, 2)
                    : formatMoney(schedule?.amountToCharge, 2) ?? '',
                override: schedule?.amountToChargeOverride ?? false,
                dateCreated: schedule?.whenCreated ?? new Date(),
                transactionCode: schedule?.transactionCode ?? '',
                transactionid: schedule?.transactionId ?? '',
                createdBy: schedule?.createdByUserId ?? user?.userId,
                createdByUserName: schedule?.createdByUserName ?? '',
                createdByUserId: schedule?.createdByUserId ?? '',
                sendEmail: false,
                emailTemplateId: '',
                emailAddress: [],
                emailAddressBcc: [],
                endDate: schedule?.endDate ? schedule?.endDate : '',
                // recurring service
                isRecurring, // flag to tell the validation what state we are in
                initialDate: schedule?.serviceDate ?? '',
                dailyInterval1: schedule?.recurPeriod === 'D' ? schedule?.interval1 : 1,
                weeklyInterval1: schedule?.recurPeriod === 'W' ? schedule?.interval1 : 1,
                weeklyInterval2: schedule?.recurPeriod === 'W' ? schedule?.interval2 : 0,
                monthlyInterval1: schedule?.recurPeriod === 'M' ? schedule?.interval1 : 1,
                monthlyInterval2: schedule?.recurPeriod === 'M' ? schedule?.interval2 : 1,
                monthlyInterval3: schedule?.recurPeriod === 'M' ? schedule?.interval3 : 1,
                yearlyInterval1: schedule?.recurPeriod === 'Y' ? schedule?.interval1 : 1,
                yearlyInterval2: schedule?.recurPeriod === 'Y' ? schedule?.interval2 : 1,
                yearlyInterval3: schedule?.recurPeriod === 'Y' ? schedule?.interval3 : 1,
                recurOption: schedule?.recurOption ?? false,
                recurPeriod: schedule?.recurPeriod ?? 'Weekly',
                serviceChecklistItems: schedule?.serviceChecklistItems ?? [],
                version: schedule?.version ?? null,
              }
        }
        enableReinitialize={hasServiceLoaded}
        validationSchema={isRecurring ? RecurringDetailPageSchema : ServiceDetailPageSchema}
        onSubmit={async (values, actions) => {
          try {
            if (isRecurring) {
              if (selectedSite?.accountStatus === 'Inactive') {
                const result = await confirm(
                  `Customer status is currently Inactive. Creating a new service will change them to Active.`
                );
                if (!result) {
                  return;
                }
              }

              let hasServiceExpections = false;
              let serviceVariationResolution = null;
              // only check if the recurring service date changes, assigned to and recurring options
              if (
                recurringId &&
                (!deepEqual(recurring?.userId!, values.assignedTo) ||
                  !deepEqual(recurring?.initialDate!, values.initialDate) ||
                  !deepEqual(
                    {
                      dailyInterval1: recurring?.recurPeriod === 'Daily' ? recurring?.interval1 : 1,
                      weeklyInterval1:
                        recurring?.recurPeriod === 'Weekly' ? recurring?.interval1 : 1,
                      weeklyInterval2:
                        recurring?.recurPeriod === 'Weekly' ? recurring?.interval2 : 0,
                      monthlyInterval1:
                        recurring?.recurPeriod === 'Monthly' ? recurring?.interval1 : 1,
                      monthlyInterval2:
                        recurring?.recurPeriod === 'Monthly' ? recurring?.interval2 : 1,
                      monthlyInterval3:
                        recurring?.recurPeriod === 'Monthly' ? recurring?.interval3 : 1,
                      yearlyInterval1:
                        recurring?.recurPeriod === 'Yearly' ? recurring?.interval1 : 1,
                      yearlyInterval2:
                        recurring?.recurPeriod === 'Yearly' ? recurring?.interval2 : 1,
                      yearlyInterval3:
                        recurring?.recurPeriod === 'Yearly' ? recurring?.interval3 : 1,
                      recurOption: recurring?.recurOption ?? false,
                      recurPeriod: recurring?.recurPeriod ?? 'Weekly',
                    },
                    {
                      recurPeriod: values.recurPeriod,
                      recurOption: values.recurOption,
                      dailyInterval1: values.dailyInterval1,
                      weeklyInterval1: values.weeklyInterval1,
                      weeklyInterval2: values.weeklyInterval2,
                      monthlyInterval1: values.monthlyInterval1,
                      monthlyInterval2: values.monthlyInterval2,
                      monthlyInterval3: values.monthlyInterval3,
                      yearlyInterval1: values.yearlyInterval1,
                      yearlyInterval2: values.yearlyInterval2,
                      yearlyInterval3: values.yearlyInterval3,
                    }
                  ))
              ) {
                try {
                  hasServiceExpections = await checkForServiceExceptions({
                    recurringServiceIds: [recurringId!],
                  });
                } catch (error: any) {
                  actions.setSubmitting(false);
                  enqueueSnackbar(
                    error?.Detail ??
                      'Unable to check for service exceptions. Please try again later.',
                    {
                      variant: 'error',
                    }
                  );
                }

                if (hasServiceExpections) {
                  const result = await confirm(
                    `Individual service variations exist in certain future weeks. How would you like to proceed?`,
                    {
                      confirmationText: 'Leave the exceptions as-is',
                      cancellationText: 'Reset the exceptions to match the change',
                    }
                  );
                  if (result) {
                    serviceVariationResolution = 'LeaveExisting';
                  } else {
                    serviceVariationResolution = 'ClearExisting';
                  }
                }
              }

              const recurData: ICreateRecurringService = {
                siteId: values.siteId,
                previousSiteId: values.previousSiteId ?? null,
                initialDate: new Date(values.initialDate).toISOString(),
                endDate: values.endDate ? new Date(values.endDate).toISOString() : null,
                serviceDefId: values.serviceDefId,
                recurPeriod: values.recurPeriod,
                recurOption: values.recurOption,
                comments: values.description,
                serviceAmount: !values.override ? null : convertToNumber(values.serviceAmount),
                whenCreated: new Date(values.dateCreated).toISOString(),
                createdBy: values.createdBy ?? '',
                assignedTo: values.assignedTo,
                officeId: user?.officeId ?? '',
                ...getIntervalValues(values),
              };
              let res;
              if (recurringId) {
                await updateRecurringServices(recurringId, {
                  ...recurData,
                  version: values.version,
                  serviceVariationResolution,
                });
              } else {
                res = await createRecurringService(recurData);

                if (res.recurringServiceId) {
                  enqueueSnackbar(`Recurring service created!`, {
                    variant: 'success',
                  });
                }
              }
              if (recurringId) {
                enqueueSnackbar(`Recurring service updated!`, {
                  variant: 'success',
                });
              }

              return history.push(redirect ?? '/services/maintenance');
            } else {
              const data: ICreateScheduledService = {
                siteId: values.siteId,
                serviceDate: values.serviceDate ?? '',
                serviceDefId: values.serviceDefId,
                status: values.status ?? 'Open',
                assignedTo: values.assignedTo,
                earliestArrival: values.earliestArrival ? values.earliestArrival : null,
                latestArrival: values.latestArrival ? values.latestArrival : null,
                estimateDuration: values.estimatedDuration
                  ? Number(values.estimatedDuration)
                  : null,
                comments: values.description,
                completionNotes: values.completionNotes ?? '',
                serviceAmount: convertToNumber(values.serviceAmount),
                whenStarted: values.startingTime ? values.startingTime : null,
                whenCompleted: null,
                whenCreated: values.dateCreated,
                createdBy: values.createdBy ?? '',
                isOverride: values.override ?? false,
              };
              isEditing()
                ? await updateScheduledServices(serviceId, {
                    ...data,
                    serviceChecklistItems:
                      values.serviceChecklistItems as IServiceSuggestionChecklist[],
                    sendEmail: values.sendEmail ?? false,
                    emailTemplateId: values?.emailTemplateId ? values?.emailTemplateId : null,
                    emailAddresses: values?.emailAddress ?? [],
                    emailAddressBccs: values?.emailAddressBcc ?? [],
                    version: values.version,
                  })
                : await createScheduledServices(data);

              enqueueSnackbar(
                isEditing() ? 'Service Updated!' : 'Successfully scheduled a new service!',
                {
                  variant: 'success',
                }
              );
              if (isModal && handleModalClose) {
                return handleModalClose();
              }
              history.push(params.get('redirect') ?? '/services/maintenance');
            }
          } catch (error: any) {
            const defaultErrorMessage = isEditing()
              ? 'Unable to update service.'
              : 'Unable to schedule a new service. Please try again later.';
            enqueueSnackbar(error?.Detail ?? defaultErrorMessage, {
              variant: 'error',
            });

            if (error?.Detail?.includes('modified by another user')) {
              fetchRecurringScheduledService();
              fetchScheduledService();
            }
          }
        }}
      >
        {({
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
          setValues,
        }) => {
          const handleSave = async () => {
            const isNotRecurringAndAssignedChanged =
              !recurringId &&
              !isNewService &&
              !deepEqual(initialValues.assignedTo, values.assignedTo);
            const isRecurringAndAssignedChanged =
              recurringId &&
              !isNewService &&
              !deepEqual(initialValues.assignedTo, values.assignedTo) &&
              !values.previousSiteId;

            const isNotRecurringAndCreateException =
              !isRecurring &&
              !!schedule?.recurringServiceId &&
              !isNewService &&
              (!deepEqual(initialValues.assignedTo, values.assignedTo) ||
                !deepEqual(initialValues.serviceDate, values.serviceDate));

            if (isNotRecurringAndCreateException && !schedule.isManualRoute) {
              const result = await confirm(
                `You are about to change a Recurring Service. Make this change as a one-time schedule exception?`
              );
              if (!result) {
                return;
              }
            }
            if (isNotRecurringAndAssignedChanged || isRecurringAndAssignedChanged) {
              // If assignedTo is changed for a single service
              // or assignedTo is changed and previousSiteId is null for a recurring service
              // display confirm before proceeding to save
              const result = await confirm(
                `Changing the technician will add this service to the bottom of their route and require manual sequencing.`
              );
              if (!result) {
                return;
              }
            }
            handleSubmit();
          };
          // used for rendering the children in a modal context with the tabs and non-collapseable cards
          const renderModalChildren = (
            <Box mt={1} mb={2}>
              <Tabs
                id="service-details"
                color="secondary"
                size="lg"
                backgroundColor="white"
                selectedTab={selectedTab}
                setSelectedTab={async val => {
                  setSelectedTab(val);
                }}
                tabs={
                  [
                    {
                      key: ServicePodType.POOL,
                      title: 'Pool Structure',
                      // Prevent mounting and API calls when not the selectedTab
                      children: selectedTab === ServicePodType.POOL && (
                        <Box mt={1.5}>
                          <PoolStructureCard
                            siteId={schedule?.siteId!}
                            readOnly
                            isServiceDetailPage
                            shouldFetch={schedule?.hasPoolEquipment ?? false}
                            isCardLayout={false}
                          />
                        </Box>
                      ),
                    },
                    {
                      key: ServicePodType.ANALYSIS,
                      title: 'Analysis',
                      // Prevent mounting and API calls when not the selectedTab
                      children: selectedTab === ServicePodType.ANALYSIS && (
                        <Box mt={1.5}>
                          <WaterAnalysis serviceId={serviceId} isCardLayout={false} />
                        </Box>
                      ),
                    },
                    {
                      key: ServicePodType.RECOMMENDATIONS,
                      title: 'Recommendations',
                      // Prevent mounting and API calls when not the selectedTab
                      children: selectedTab === ServicePodType.RECOMMENDATIONS && (
                        <ServiceRecommendations
                          serviceId={serviceId}
                          showNoResults={
                            podType && podType === ServicePodType.ANALYSIS ? false : undefined
                          }
                          isCardLayout={false}
                          siteId={schedule?.siteId}
                          withTreatments
                        />
                      ),
                    },
                    {
                      key: ServicePodType.PHOTO,
                      title: 'Service Photo',
                      // Prevent mounting and API calls when not the selectedTab
                      children: selectedTab === ServicePodType.PHOTO && (
                        <Box mt={1.5}>
                          {isLoadingServicePhoto && <Loader />}
                          {selectedServicePhoto?.urlPath && (
                            <>
                              <UploadedImage
                                source={selectedServicePhoto?.urlPath ?? ''}
                                isLoading={isLoadingServicePhoto}
                              />
                            </>
                          )}
                          {!isLoadingServicePhoto && !selectedServicePhoto?.urlPath && (
                            <Typography align="center">No service photo available</Typography>
                          )}
                        </Box>
                      ),
                    },
                    {
                      key: ServicePodType.DETAILS,
                      title: 'Details',
                      // Prevent mounting and API calls when not the selectedTab
                      children: selectedTab === ServicePodType.DETAILS && (
                        <ServiceDetailsCard
                          isLoadingUsers={isLoadingUsers}
                          serviceId={serviceId}
                          users={users}
                          isRecurring={isRecurring}
                          recurringId={isRecurring ? recurringId : schedule?.recurringServiceId}
                          isEdit={isEditing()}
                          schedule={schedule}
                          siteId={siteId}
                          isNew={isNewService}
                          isCardLayout={false}
                          isInvalidInitialDate={isInvalidInitialDate}
                          setIsInvalidInitialDate={setIsInvalidInitialDate}
                          setIsInvalidEndDate={setIsInvalidEndDate}
                          readOnly={!!podType ? true : false}
                          accountId={schedule?.siteAccountId || recurring?.siteAccountId}
                          setServiceDefinitions={setServiceDefinitions}
                          serviceDefinitions={serviceDefinitions}
                          withChecklistInfo
                        />
                      ),
                    },
                  ].filter(Boolean) as ITab[]
                }
              />
            </Box>
          );
          // used for rendering the children in a page context with the collapsable cards
          const renderChildren = (
            <div>
              {isSubmitting && <Loader position="centered" type="overlay" />}
              {(schedule || !isEditing()) && (
                <>
                  {!podType && (
                    <Box
                      mb={1}
                      mt={-2}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      className="print--none"
                    >
                      <Button
                        variant="text"
                        onClick={() => setIsAllExpanded(!isAllExpanded ? true : false)}
                        startIcon={isAllExpanded ? <ExpandLess /> : <ExpandMore />}
                      >
                        {isAllExpanded ? 'Collapse All' : 'Expand All'}
                      </Button>
                    </Box>
                  )}
                  {!podType && (
                    <CreditLimitAlert
                      siteId={schedule?.siteId}
                      isExpanded={isAllExpanded}
                      containerShadow
                    />
                  )}
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSubmit();
                    }}
                  >
                    <Box mt={2}>
                      <ServiceDetailsCard
                        isLoadingUsers={isLoadingUsers}
                        serviceId={serviceId}
                        users={users}
                        isRecurring={isRecurring}
                        recurringId={isRecurring ? recurringId : schedule?.recurringServiceId}
                        isEdit={isEditing()}
                        schedule={schedule}
                        siteId={siteId}
                        isNew={isNewService}
                        isExpanded={
                          podType
                            ? podType === ServicePodType.DETAILS
                              ? true
                              : false
                            : isAllExpanded
                        }
                        isInvalidInitialDate={isInvalidInitialDate}
                        setIsInvalidInitialDate={setIsInvalidInitialDate}
                        setIsInvalidEndDate={setIsInvalidEndDate}
                        readOnly={!!podType ? true : false}
                        accountId={
                          accountIdParam || schedule?.siteAccountId || recurring?.siteAccountId
                        }
                        setServiceDefinitions={setServiceDefinitions}
                        serviceDefinitions={serviceDefinitions}
                        setSelectedSite={setSelectedSite}
                        selectedSite={selectedSite}
                      />
                    </Box>
                    {!isRecurring && !podType && !isNewService && (
                      <Box mt={2} mb={2}>
                        <Tasks
                          cardTitle="Scheduled Tasks"
                          showFilters={false}
                          redirect={`/services/maintenance/${serviceId}`}
                          isEditable={!isModal}
                          withExpand
                          initialExpand={false}
                          overrideExpand={isAllExpanded}
                          addTaskButtonText="Add Task to Service"
                          scheduledServiceId={serviceId}
                          accountId={schedule?.siteAccountId}
                          siteId={schedule?.siteId}
                          hasQueryParamFiltering={false}
                        />
                      </Box>
                    )}
                    {isRecurring && !podType && (
                      <RecurringPattern
                        frequency={schedule?.frequency}
                        recurringServiceId={schedule?.recurringServiceId}
                        isRecurring={isRecurring}
                        initialDate={values.initialDate}
                        setSchedule={data => {
                          setValues({
                            ...values,
                            ...data,
                          });
                        }}
                        serviceDetails={schedule}
                        endDate={values.endDate}
                        recurOption={values.recurOption}
                        recurPeriod={values.recurPeriod}
                        dailyInterval1={values.dailyInterval1}
                        weeklyInterval1={values.weeklyInterval1}
                        weeklyInterval2={values.weeklyInterval2}
                        monthlyInterval1={values.monthlyInterval1}
                        monthlyInterval2={values.monthlyInterval2}
                        monthlyInterval3={values.monthlyInterval3}
                        yearlyInterval1={values.yearlyInterval1}
                        yearlyInterval2={values.yearlyInterval2}
                        yearlyInterval3={values.yearlyInterval3}
                        setFieldValue={setFieldValue}
                        dirty={dirty}
                        isExpanded={isAllExpanded}
                        setIsInvalidEndDate={setIsInvalidEndDate}
                        isInvalidEndDate={isInvalidEndDate}
                      />
                    )}
                    {!isNewService && !isRecurring && !podType && (
                      <Box mb={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <div className={clsx('print--avoid-break')}>
                              <ChecklistInfo
                                serviceId={serviceId}
                                serviceDefId={values.serviceDefId}
                                checklistInfo={
                                  values.serviceChecklistItems as IServiceSuggestionChecklist[]
                                }
                                setChecklistInfo={data => {
                                  setFieldValue('serviceChecklistItems', data);
                                }}
                                dirty={dirty}
                                isExpanded={isAllExpanded}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className={clsx('print--avoid-break')}>
                              <PSISection
                                previousPSI={schedule?.previousPSI || '0.00'}
                                currentPSI={schedule?.currentPSI || '0.00'}
                                isExpanded={isAllExpanded}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    )}

                    {!!values.transactionid && !podType && (
                      <Box mt={2}>
                        <Card>
                          <CardTitle
                            title="Invoice"
                            withExpand
                            initialExpand={false}
                            overrideExpand={isAllExpanded}
                          >
                            <InvoiceDetail
                              invoiceId={values.transactionid}
                              accountId={values.siteId}
                              fetchScheduledService={fetchScheduledService}
                            />
                          </CardTitle>
                        </Card>
                      </Box>
                    )}
                    {!isNewService && !isRecurring && (
                      <>
                        <>
                          <Box mt={2}>
                            <WaterAnalysis
                              serviceId={serviceId}
                              isExpanded={
                                podType
                                  ? podType === ServicePodType.ANALYSIS
                                    ? true
                                    : false
                                  : isAllExpanded
                              }
                            />
                          </Box>
                          <Box mt={2}>
                            <ServiceRecommendations
                              serviceId={serviceId}
                              isExpanded={
                                podType
                                  ? podType === ServicePodType.ANALYSIS
                                    ? true
                                    : false
                                  : isAllExpanded
                              }
                              showNoResults={
                                podType && podType === ServicePodType.ANALYSIS ? false : undefined
                              }
                            />
                          </Box>
                        </>
                        <Box mt={2}>
                          <Treatments
                            siteId={values.siteId}
                            siteName={schedule?.siteName}
                            serviceId={serviceId}
                            isModal={isModal}
                            isServiceDetailPage
                            isExpanded={
                              podType
                                ? podType === ServicePodType.RECOMMENDATIONS
                                  ? true
                                  : false
                                : isAllExpanded
                            }
                            isEditable={!podType}
                          />
                        </Box>
                        {schedule && (
                          <Box mt={2}>
                            <ServiceImages
                              scheduledServiceId={schedule?.scheduledServiceId}
                              service={schedule}
                              isAllExpanded={isAllExpanded}
                              readOnly={!!podType}
                            />
                          </Box>
                        )}
                        {schedule?.hasPoolEquipment && (
                          <Box mt={2}>
                            <PoolStructureCard
                              siteId={schedule?.siteId!}
                              readOnly
                              isServiceDetailPage
                              isExpanded={
                                (podType && podType === ServicePodType.POOL) || isAllExpanded
                                  ? true
                                  : false
                              }
                              shouldFetch={schedule?.hasPoolEquipment ?? false}
                            />
                          </Box>
                        )}
                        {!podType && (
                          <div className={clsx('print--avoid-break', classes.printBlock)}>
                            <ServiceLog
                              serviceId={serviceId}
                              isModal={isModal}
                              isExpanded={isAllExpanded}
                            />
                          </div>
                        )}
                      </>
                    )}
                    {!podType && (
                      <BillingCommissionInfo
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        values={values}
                        isLoadingUsers={isLoadingUsers}
                        users={users}
                        setFieldValue={setFieldValue}
                        dirty={dirty}
                        isExpanded={isAllExpanded}
                        isRecurring={isRecurring}
                      />
                    )}
                    {isRecurring && !podType && recurringId && (
                      // related services for recurring service parent
                      <Box mb={2}>
                        <ScheduledServices
                          redirect={`/services/maintenance/recurring/${recurringId}`}
                          siteId={siteId}
                          title="Service History"
                          isCollapsible
                          initialExpand
                          isExpanded={isAllExpanded ?? false}
                          isEditable={false}
                          parentRecurringService={recurringId}
                          dateLinkModal={false}
                          hasQueryParamFiltering={false}
                        />
                      </Box>
                    )}
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection={{
                        xs: 'column',
                        sm: 'row',
                      }}
                      justifyContent={{
                        xs: 'center',
                        sm: 'flex-start',
                      }}
                      className="print--none"
                    >
                      {!podType && (
                        <FloatingToolbar isModal={isModal}>
                          <Button
                            className={classes.floatingButton}
                            color="inherit"
                            disabled={isDeleting || isSubmitting}
                            onClick={() => {
                              if (isModal && handleModalClose) {
                                return handleModalClose();
                              }
                              history.push(params.get('redirect') ?? '/services/maintenance');
                            }}
                            startIcon={<CancelIcon />}
                          >
                            Cancel
                          </Button>
                          <SaveButton
                            className={classes.floatingButton}
                            disabled={
                              !dirty ||
                              isSubmitting ||
                              !isValid ||
                              isDeleting ||
                              (isRecurring && (isInvalidEndDate.check || isInvalidInitialDate))
                            }
                            handleSave={handleSave}
                          />
                        </FloatingToolbar>
                      )}
                      {!isNewService &&
                        serviceId !== 'recurring' &&
                        (schedule?.completed === null || !schedule?.completed) &&
                        !isModal && (
                          <Button
                            className={classes.floatingButton}
                            color="error"
                            title="Delete Service"
                            onClick={async () => {
                              const result = await confirm(
                                `Are you sure you want to delete this ${
                                  isRecurring ? 'recurring service' : 'scheduled service'
                                }?`
                              );
                              if (result) {
                                setDeleting(true);
                                try {
                                  isRecurring
                                    ? await deleteRecurringService(recurringId)
                                    : await deleteScheduledService(serviceId);
                                  enqueueSnackbar(
                                    `${isRecurring ? 'Recurring Service' : 'Service'} deleted!`,
                                    {
                                      variant: 'success',
                                    }
                                  );
                                  history.push(params.get('redirect') ?? '/services/maintenance');
                                } catch (error) {
                                  enqueueSnackbar(
                                    `Error deleting ${
                                      isRecurring ? 'Recurring Service' : 'Service'
                                    }, please try again.`,
                                    {
                                      variant: 'error',
                                    }
                                  );
                                } finally {
                                  setDeleting(false);
                                }
                              }
                            }}
                            startIcon={<FontAwesomeIcon icon={faTrash} />}
                          >
                            Delete
                          </Button>
                        )}
                    </Box>
                  </form>
                </>
              )}
            </div>
          );
          return isModal ? (
            <Modal
              open={isModalOpen!}
              onClose={handleModalClose!}
              maxWidth="lg"
              fullHeight
              title={podType ? 'View Service' : 'Edit Service'}
              customButtonHandler={() => {
                history.push(`/services/maintenance/${schedule?.scheduledServiceId}`);
              }}
              customButtonIcon={<FontAwesomeIcon icon={faEye} />}
              customButtonText="Service Details"
            >
              <Fade in={isModalOpen!}>{renderModalChildren}</Fade>
            </Modal>
          ) : (
            <Page
              title={isNewService ? 'New Service' : 'Edit Service'}
              breadcrumb={{
                text: 'Maintenance Services',
                title: 'Back to Maintenance',
                link: redirect?.includes(`/services/maintenance`)
                  ? redirect
                  : '/services/maintenance',
              }}
            >
              <ConfirmPrompt
                when={
                  !deepEqual(initialValues, values) &&
                  !isSubmitting &&
                  !isLoadingService &&
                  !isDeleting
                }
                message={defaultUnsavedChangesMessage}
              />
              {renderChildren}
            </Page>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

const PREFIX = 'ServiceDetailPage';

const classes = {
  printBlock: `${PREFIX}-printBlock`,
  floatingButton: `${PREFIX}-floatingButton`,
};

const Wrapper = styled(Box)(({ theme }) => ({
  [`& .${classes.printBlock}`]: {
    margin: '1rem 0',
  },

  [`& .${classes.floatingButton}`]: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '13px',
      gap: theme.spacing(1),
      '& svg': {
        margin: '0 !important',
      },
    },
  },
}));
