import { faUserPen, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { FC, useContext, useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs } from '../../components';
import { ServiceRoutesContext } from '../../context/service-routes-context';
import {
  ICalendarDateRange,
  IFinalRoutePayload,
  IServiceRouteDetail,
  ITechOptimizationEvent,
  IUpdateRoutesPayload,
} from '../../models';
import { OptimizeRoutes } from './OptimizeRoutes';
import { ServiceRoutesPods } from './ServiceRoutesPods';
import { useRoutes } from './useRoutes';
import { useRouteChanges } from './useRouteChanges';
import { toChangeListPayload } from './utils';
import {
  getServiceRouteDetails,
  updateServiceRoutes,
  checkForServiceExceptions,
} from '../../fetch';
import { useSnackbar } from 'notistack';
import { useConfirm, useUnload } from '../../hooks';
import { format } from 'date-fns';
import { formatDate, isValidDate, parseCalendarDate } from '../../helpers';
import { UserContext } from '../../context';
import { defaultUnsavedChangesMessage } from '../../constants';

export const ServiceRoutesPageDetails: FC = () => {
  const history = useHistory();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedDateRange, setSelectedDateRange } = useContext(ServiceRoutesContext);
  const [selectedTab, setSelectedTab] = useState<string>('adjust');
  const [activeStep, setActiveStep] = useState(0);
  const { isSingleViewMode } = useContext(ServiceRoutesContext);
  const [singleViewServiceDate, setSingleViewServiceDate] = useState<string>('');
  // Get URL data for deeply linked results for the list page
  const { search } = useLocation();
  // Get query params so we can filter by them
  const userId = new URLSearchParams(search).get('userId');
  const startDateParam = new URLSearchParams(search).get('startDate');
  const endDateParam = new URLSearchParams(search).get('endDate');
  const optimizedRouteIdParam = new URLSearchParams(search).get('optimizeRouteId');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [techOptimizationEvent, setTechOptimizationEvent] = useState<
    ITechOptimizationEvent | undefined
  >();
  const [optimizeDateRange, setOptimizeDateRange] = useState<ICalendarDateRange | undefined>();
  const [isCompareView, setCompareView] = useState(false);
  const { user } = useContext(UserContext);

  const { loadServiceRoutes, isLoading, setIsLoading, serviceRoutes, setServiceRoutes } =
    useRoutes();
  const {
    updatedRoutes,
    hasChanges,
    changes,
    onServicesChange,
    updateMode,
    onUpdateModeChange,
    reset,
    isOptimizing,
    onOptimizeTechRoute,
    setOptimizedTech,
    optimizedTech,
    setInitialRoutes,
  } = useRouteChanges({
    serviceRoutes,
    updateMode: 'Single',
  });

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, hasChanges);

  useEffect(() => {
    if (optimizedRouteIdParam) {
      setSelectedTab('optimize');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optimizedRouteIdParam]);

  useEffect(() => {
    if (startDateParam && endDateParam) {
      setSelectedDateRange({
        startDate: new Date(startDateParam),
        endDate: new Date(endDateParam),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateParam, endDateParam]);

  useEffect(() => {
    if (!selectedDateRange) {
      return;
    }
    if (selectedTab === 'adjust' && !optimizedRouteIdParam) {
      loadServiceRoutes(selectedDateRange, userId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, selectedDateRange, optimizedRouteIdParam]);

  const onMapClick = (routeIndex: number) => {
    const route = updatedRoutes[routeIndex];
    history.push(
      isSingleViewMode
        ? `/routes/${route.routeId}?redirect=/routes?date=${format(
            parseCalendarDate(route.serviceDate),
            'yyyy-MM-dd'
          )}`
        : `/routes/${route.routeId}`
    );
  };

  const reloadCompareModePods = async () => {
    const loadComparePodsByDate = async (date: string) => {
      setIsLoading(true);
      if (isValidDate(new Date(date))) {
        try {
          // get the tickId
          const ticksId = new Date(date).getTime() * 10000 + 621355968000000000;
          const route = await getServiceRouteDetails(ticksId, {
            officeId: user?.officeId,
          });
          if (route) {
            const copy: IServiceRouteDetail[] = updatedRoutes;
            const currentIndex = copy.findIndex(c => c.serviceDate === date);
            copy![currentIndex] = route;
            setInitialRoutes(copy!);
          } else {
            // handle pod that doesn't have service data
            const copy: IServiceRouteDetail[] = updatedRoutes;
            const currentIndex = copy.findIndex(c => c.serviceDate === date);
            copy![currentIndex] = {
              nextRouteId: '',
              previousRouteId: '',
              officeId: '',
              routeId: '',
              serviceDate: date,
              technicians: [],
            };
            setInitialRoutes(copy!);
          }
        } catch (error: any) {
          enqueueSnackbar(error?.Detail ?? `Error loading service date, ${formatDate(date)}.`, {
            variant: 'error',
          });
        } finally {
          setIsLoading(false);
        }
      }
    };

    updatedRoutes.forEach(s => {
      loadComparePodsByDate(s.serviceDate);
    });
  };

  const saveChanges = useCallback(
    async (shouldConfirm: boolean = true) => {
      try {
        const changeList = Object.values(changes || {});
        const hasChangedRecurringServices =
          changeList
            // check if route changed tech
            .filter(change => change.from.userId !== change.to.userId)
            .map(change => change.service)
            // only check for recurring services
            .filter(service => !!service.recurringServiceId).length > 0;

        let hasServiceExpections = false;
        if (updateMode === 'Recurring') {
          try {
            hasServiceExpections = await checkForServiceExceptions({
              recurringServiceIds: changeList
                // check if route changed tech
                .filter(change => change.from.userId !== change.to.userId)
                .map(change => change.service.recurringServiceId),
            });
          } catch (error) {}
        }

        let serviceVariationResolution = hasChangedRecurringServices ? 'LeaveExisting' : null;
        if (updateMode === 'Recurring' && hasServiceExpections) {
          const result = await confirm(
            `Individual service variations exist in certain future weeks. How would you like to proceed?`,
            {
              confirmationText: 'Leave the exceptions as-is',
              cancellationText: 'Reset the exceptions to match the change',
            }
          );
          if (result) {
            serviceVariationResolution = 'LeaveExisting';
          } else {
            serviceVariationResolution = 'ClearExisting';
          }
        }

        if (shouldConfirm) {
          if (updateMode === 'Single' && hasChangedRecurringServices) {
            const result = await confirm(
              `You are about to change a Recurring Service while set to Single Time Mode.  
              Make this change as a one-time schedule exception?`
            );
            if (!result) {
              return;
            }
          }
        }

        const finalOrder: IFinalRoutePayload[] =
          updatedRoutes?.map(route => {
            return {
              serviceDate: route.serviceDate,
              routes: route.technicians.map(tech => {
                return {
                  userId: tech.userId,
                  scheduledServiceIds: tech.services.map(service => service.scheduledServiceId),
                };
              }),
            };
          }) || [];

        if (!finalOrder.length) {
          return;
        }

        const payload: IUpdateRoutesPayload = {
          updateMode,
          changes: toChangeListPayload(changes || {}),
          finalOrder,
          serviceVariationResolution,
        };

        setIsSaving(true);
        await updateServiceRoutes(payload);
        setServiceRoutes(updatedRoutes);
        enqueueSnackbar(`Updated Route Order!`, {
          variant: 'success',
        });
        !isCompareView && loadServiceRoutes(selectedDateRange!, userId);
        isCompareView && (await reloadCompareModePods());
      } catch (error: any) {
        if (!error?.Detail?.includes('modified by another user')) {
          // reset changes if an error is thrown
          reset();
          return enqueueSnackbar(error?.Detail ?? `Error saving route order, please try again.`, {
            variant: 'error',
          });
        }
        if (error?.Detail?.includes('modified by another user') && selectedDateRange) {
          return loadServiceRoutes(selectedDateRange, userId);
        }
        return enqueueSnackbar(`Error saving route order, please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsSaving(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changes, updateMode, updatedRoutes]
  );

  const handleTechOptimize = useCallback(
    async (event: ITechOptimizationEvent) => {
      if (hasChanges) {
        const result = await confirm(
          'Optimizing this route requires that pending changes will be saved first. Continue?'
        );
        if (!result) {
          return;
        }
        await saveChanges(false);
        setTechOptimizationEvent(event);
      } else {
        const result = await confirm('Optimize this route?');
        if (!result) {
          return;
        }
        onOptimizeTechRoute(event);
      }
    },
    [hasChanges, saveChanges, onOptimizeTechRoute, confirm]
  );

  const handleOptimizeSwitch = () => {
    setSelectedTab('optimize');
    setOptimizeDateRange(selectedDateRange);
  };

  useEffect(() => {
    if (!techOptimizationEvent) {
      return;
    }
    setTechOptimizationEvent(undefined);
    onOptimizeTechRoute(techOptimizationEvent);
  }, [techOptimizationEvent, onOptimizeTechRoute]);
  return (
    <>
      <Tabs
        id="service-routes"
        color="secondary"
        size="lg"
        selectedTab={selectedTab}
        setSelectedTab={async val => {
          if (hasChanges || activeStep === 1) {
            const result = await confirm(defaultUnsavedChangesMessage);
            if (result) {
              setSelectedTab(val);
              if (val === 'adjust') {
                setActiveStep(0);
              }
            }
          } else {
            setActiveStep(0);
            setSelectedTab(val);
          }
        }}
        tabs={[
          {
            key: 'adjust',
            title: 'Adjust Routes',
            mobileTitle: 'Adjust',
            icon: faUserPen,
            children: (
              <div>
                <ServiceRoutesPods
                  isLoading={isLoading}
                  isOptimizing={isOptimizing}
                  serviceRoutes={updatedRoutes}
                  initialServiceRoutes={serviceRoutes}
                  hasChanges={hasChanges}
                  changes={changes}
                  onServicesChange={onServicesChange}
                  updateMode={updateMode}
                  onUpdateModeChange={onUpdateModeChange}
                  isSaving={isSaving}
                  onSave={saveChanges}
                  onReset={reset}
                  onMapClick={onMapClick}
                  onOptimizationClick={handleTechOptimize}
                  singleViewServiceDate={singleViewServiceDate}
                  setSingleViewServiceDate={setSingleViewServiceDate}
                  onOptimizeSwitch={handleOptimizeSwitch}
                  setOptimizedTech={setOptimizedTech}
                  optimizedTech={optimizedTech}
                  setUpdatedRoutes={setInitialRoutes}
                  loadServiceRoutes={loadServiceRoutes}
                  isCompareView={isCompareView}
                  setCompareView={setCompareView}
                />
              </div>
            ),
          },
          {
            key: 'optimize',
            title: 'Optimize Routes',
            mobileTitle: 'Optimize',
            icon: faWandMagicSparkles,
            children: (
              <OptimizeRoutes
                loadingServiceRoutes={isLoading}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                setKey={setSelectedTab}
                optimizedRouteId={optimizedRouteIdParam}
                dateRange={optimizeDateRange}
                updateMode={updateMode}
                onUpdateModeChange={onUpdateModeChange}
              />
            ),
          },
        ]}
      />
    </>
  );
};
